import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { isEqual } from 'date-fns';
import { first } from 'rxjs/operators';
import { LocationService } from 'src/app/services/location.service';
import { NotificationService } from 'src/app/services/notification.service';
import { firebaseTS } from 'src/app/types/firebaseTS.type';
import { eventSlotStatus } from 'src/app/utils/constants/eventSlotStatus';
import {
  getJsDateFromFireTs,
} from 'src/app/utils/helpers';

@Component({
  selector: 'app-cancel-request',
  templateUrl: './cancel-request.component.html',
  styleUrls: ['./cancel-request.component.scss'],
})
export class CancelRequestComponent implements OnInit {
  location = {};

  public locationId;
  public roomUUID;
  public slotId;
  public requestUUID;
  currentNotification = undefined;
  public notification;


  refuseReasonList = [
    'Termin kurzfristig vergeben',
    'Nicht unsere Zielgruppe',
    'Anfrage nicht möglich',
  ];
  refuseReason = this.refuseReasonList[0];
  customRefuseReason = '';
  notifications = [];
  loading = false;
  eventSlot;
  request;


  constructor(
    private snackbar: MatSnackBar,
    private notificationService: NotificationService,
    private firestore: AngularFirestore,
    private locationService: LocationService,
    private ref: MatDialogRef<CancelRequestComponent>,
    private datePipe: DatePipe
  ) { }

  ngOnInit(): void {
    this.getEventSlot();
    this.loadLocation();
  }

  ngAfterViewInit() {
    this.initNotification();
  }

  async loadLocation() {
    this.location = await this.locationService.getLocation(this.locationId).pipe(first()).toPromise();
  }

  initNotification() {
    // // TODO
    // let startDateFb: firebaseTS = this.location['places'][this.placeIndex][
    //   'bookableEventSlots'
    // ][this.slotIndex]['startSlot'];
    // let startDate = getJsDateFromFireTs(
    //   startDateFb.seconds,
    //   startDateFb.nanoseconds
    // );
    // this.notification = getTourNotification(this.notifications, startDate);
  }

  refuseRequest() {
    // TODO?
    // if (
    //   this.notification &&
    //   'payload' in this.notification[0] &&
    //   'tourId' in this.notification[0]['payload']
    // ) {
    //   this.updateTourSlot();
    // }
    this.sendRefuseRequest();
  }

  updateTourSlot() {
    this.firestore
      .collection('tours')
      .doc(this.notification[0]['payload']['tourId'])
      .valueChanges()
      .pipe(first())
      .subscribe((tour: any) => {
        this.updateTour(tour);
      });
  }

  updateTour(tour) {
    for (let i = 0; i < tour.eventSlots.length; i++) {
      const tourSlot = tour.eventSlots[i];
      const startFire: firebaseTS = tourSlot.eventSlotStartDate;
      let start = getJsDateFromFireTs(startFire.seconds, startFire.nanoseconds);
      let nStartFire: firebaseTS = this.notification[0].payload.startSlot;
      let nStart = getJsDateFromFireTs(
        nStartFire.seconds,
        nStartFire.nanoseconds
      );
      if (isEqual(start, nStart)) {
        tour['eventSlots'][i]['eventSlotRequest']['status'] =
          eventSlotStatus.declined;
        this.firestore
          .collection('tours')
          .doc(this.notification[0]['payload']['tourId'])
          .set(tour);
        return;
      }
    }
  }

  async sendRefuseRequest() {
    this.loading = true;
    // let place = this.location['places'][this.placeIndex];
    // let slot = place['bookableEventSlots'][this.slotIndex];
    // let request = slot['requests'][this.requestIndex];
    this.request['status'] = 'Abgelehnt';
    this.request['customRefuseReason'] = this.customRefuseReason;
    this.request['refuseReason'] = this.refuseReason;

    let reasonText = this.refuseReason;
    if (this.refuseReason == 'Sonstiges...') {
      reasonText = this.customRefuseReason;
    }

    console.log('Refusing text:', this.refuseReason, this.customRefuseReason, reasonText);

    const eventDate = this.datePipe.transform(this.eventSlot['startSlot'].toDate(), 'mediumDate');

    await this.notificationService.sendNotification(
      this.request['user'],
      `Deine Buchungsanfrage für den Veranstaltungsort ${this.location['name']} am ${eventDate} wurde abgelehnt: 
      ${reasonText}
      `,
      'BOOKING_DECLINED',
      {
        eventSlot: this.slotId,
        requestUUID: this.requestUUID,
      }
    );

    this.saveEventSlot();
    // this.updateTourSlot();
    this.finishNotinication();
  }

  private finishNotinication() {
    this.currentNotification['action'] = 'REQUEST_SLOT_DONE';
    this.firestore
      .collection('notifications')
      .doc(this.currentNotification['customIdName'])
      .update(this.currentNotification)
      .then();
  }

  private saveEventSlot() {
    this.firestore
      .collection('event-slots')
      .doc(this.slotId)
      .update(this.eventSlot)
      .then(() => {
        this.loading = false;
        this.snackbar.open('Die Anfrage wurde abgelehnt', null, {
          duration: 2000,
        });
        this.ref.close();
      })
      .catch(() => {
        this.loading = false;
        this.snackbar.open('Leider ist ein Fehler aufgetreten', null, {
          duration: 2000,
        });
      });
  }



  private getEventSlot() {
    console.log('Getting event slot');
    this.firestore
      .collection('event-slots')
      .doc(this.slotId)
      .valueChanges()
      .subscribe((slot) => {
        this.eventSlot = slot;
        this.request = this.eventSlot['requests'].find(r => r['UUID'] == this.requestUUID);
        console.log('Loaded event slot and request', this.requestUUID, this.eventSlot, this.request);
      });
  }
}
