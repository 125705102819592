import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-location-status-info',
  templateUrl: './location-status-info.component.html',
  styleUrls: ['./location-status-info.component.scss']
})
export class LocationStatusInfoComponent implements OnInit {
  @Input() location = {};
  constructor() { }

  ngOnInit(): void {
  }

}
