import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { selectedEventSlot } from 'src/app/types/selectedEventSlot.type';

@Component({
  selector: 'app-selected-locations',
  templateUrl: './selected-locations.component.html',
  styleUrls: ['./selected-locations.component.scss'],
})
export class SelectedLocationsComponent implements OnInit {
  @Input() opened = false;
  @Input() selectedLocation: selectedEventSlot = {
    daySlot: undefined,
    location: undefined,
    room: undefined,
    bookingRequestUUID: undefined,
  };
  @Input() bookingDate: Date = undefined;
  @Output()
  deletedSelectedLocation: EventEmitter<selectedEventSlot> = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  toggle() {
    this.opened = !this.opened;
  }

  public deleteChoosenLocation() {
    this.deletedSelectedLocation.emit(this.selectedLocation);
  }
}
