import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CachingServiceService {

  map = {};

  constructor() { }

  public get(key) {
    return this.map[key];
  }

  public set(key, value) {
    this.map[key] = value;
  }

}
