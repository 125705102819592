import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { MatSnackBar } from '@angular/material/snack-bar';
import { EmailService } from 'src/app/services/email.service';
import { MessageService } from 'src/app/services/message.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit {
  user = {};
  userInfo = {
    gender: '',
    firstName: '',
    lastName: '',
    company: '',
    position: '',
    phone: '',
    fax: '',
  };
  customUser = {};
  loading = false;
  showSaveMessage = false;

  constructor(
    private authService: AngularFireAuth,
    private message: MessageService,
    private firestore: AngularFirestore
  ) { }

  ngOnInit(): void {
    this.authService.user.subscribe((user) => {
      console.log(user);
      this.user = user;
      this.subscribeUser();
    });
  }

  saveUser() {
    console.log('Save user with id ', this.user['uid'], this.userInfo);
    this.loading = true;
    this.userInfo['email'] = this.user['email'];
    this.userInfo['profileComplete'] = true;
    this.firestore
      .collection('users')
      .doc(this.user['uid'])
      .set(this.userInfo)
      .then((result) => {
        console.log('Updated user ', result);
        this.message.snackbarCenter('Änderungen wurden gespeichert');
        this.showSavedMessage();
        this.loading = false;
      })
      .catch((error) => {
        console.error('Error saving user', error);
        this.loading = false;
        this.message.snackbarCenter('Die Änderungen konnten nicht gespeichert werden.');
      });
  }

  private subscribeUser() {
    this.firestore
      .collection('users')
      .doc(this.user['uid'])
      .valueChanges({ idField: 'customIdName' })
      .subscribe((userInfo: any) => {
        console.log('Got user from DB', userInfo);
        if (userInfo) {
          this.userInfo = userInfo;
        }
      });
  }

  private showSavedMessage() {
    this.showSaveMessage = true;

    setTimeout(() => {
      this.showSaveMessage = false;
    }, 6000);
  }


}
