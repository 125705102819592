<div class="tourContainer margin-bottom-32">
    <button class="add-icon" mat-icon-button color="primary" (click)="toggle()">
        <mat-icon *ngIf="!opened">add</mat-icon>
        <mat-icon *ngIf="opened">remove</mat-icon>
    </button>
    <div class="tourContentContainer">
        <h3 class="pointer" (click)="toggle()">{{ tour['tourName'] }} <span class="color-gray">{{ tour['tourType'] }}</span></h3>
        <div class="bottomLine margin-bottom-32"></div>

        <div class="collapsed" [class.expanded]="opened">
            <div *ngFor="let tourSlot of tour['tourSlots']" class="selected-slot">
                <app-tour-slot [tourSlot]="tourSlot"></app-tour-slot>
            </div>
        </div>
    </div>
</div>