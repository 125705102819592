import { Component, OnInit, ViewChildren } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { LocationService } from 'src/app/services/location.service';
import { MessageService } from 'src/app/services/message.service';
import { locationStatus } from 'src/app/utils/constants/locationStatus';
import { validateForm } from '../../utils/helpers';
import { LocationAccessComponent } from '../dialogs/location-access/location-access.component';
import { ReleaseVenueComponent } from '../dialogs/release-venue/release-venue.component';
import { GET_DESCRIPTION, LOCATION_CATEGORIES, LOCATION_FIELDS } from './locations-fields.data';

@Component({
  selector: 'app-edit-location',
  templateUrl: './edit-location.component.html',
  styleUrls: ['./edit-location.component.scss'],
})
export class EditLocationComponent implements OnInit {

  locationId = '';
  location = {
    name: '',
    phone: '',
    mail: '',
    description: '',
    places: [],
    rooms: [],
    users: [],
  };
  successMessage = false;
  locationInitialized = false;
  locationInfoForm = new FormGroup({});
  LOCATION_CATEGORIES = [null, ...LOCATION_CATEGORIES()];
  openedCategories = LOCATION_CATEGORIES();
  @ViewChildren('editPlaceForm') editPlaceForm;

  constructor(
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private firestore: AngularFirestore,
    private locationService: LocationService,
    private message: MessageService
  ) { }

  ngOnInit(): void {
    this.createLocationInfoForm();
    this.route.paramMap.subscribe((paramMap) => {
      this.locationId = paramMap.get('id');
      this.getLocation();
    });
  }

  getFields(category?: string) {
    if (category) {
      return LOCATION_FIELDS.filter(f => f['category'] == category);
    } else {
      return LOCATION_FIELDS.filter(f => !f['category']);
    }
  }


  toggle(category) {
    if (this.openedCategories.includes(category)) {
      this.openedCategories = this.openedCategories.filter(c => c != category);
    } else {
      this.openedCategories.push(category);
    }
  }

  async updateLocation() {
    console.log('Updating location');
    if (validateForm(this.locationInfoForm)) {
      this.locationInfoForm.disable();
      LOCATION_FIELDS.forEach((field) => {
        this.location[field.field] = this.locationInfoForm.controls[
          field.field
        ].value;
      });

      let latlng;
      try {
        latlng = await this.locationService.resolveLatLng(this.location);
      } catch (e) {
        console.error('Error resolving address', e);
      }

      if (latlng) {
        this.location['location'] = latlng;
        if (!this.location['status']) {
          this.location['status'] = locationStatus.inCreation;
        }
        this.saveLocation();
      } else {
        this.message.snackbar('Bitte überprüfe die eingegebene Adresse');
        this.locationInfoForm.enable();
      }

    } else {
      this.message.snackbar('Bitte fülle alle Pflichtfelder richtig aus.');
    }
  }

  /**
   * Used to store old rooms from EditPlaceComponent which were not saved.
   */
  resolveOldRooms() {
    let oldRooms = [];
    this.editPlaceForm.forEach((epf, i) => {
      console.log('editPlaceForm', i, epf.getCurrentFields());
      oldRooms.push(epf.getCurrentFields());
    });
    return oldRooms;
  }

  showCategoryInfo(category) {
    this.message.dialog(GET_DESCRIPTION(category));
  }

  async updatePlace(output, index) {
    let room = output[0];
    let form = output[1];

    try {
      console.log('Updating room (' + index + ')', room);


      // this.location.places[index] = place; // Old
      this.location.places = []; // Old, kept for migration issues
      if (!this.location.rooms) {
        this.location.rooms = [];
      }
      this.location.rooms[index] = room;
      // this.addRequiredFields();
      this.saveLocation(form);
    } catch (e) {
      console.error(e);
      this.message.snackbar('Die Änderungen konnten nicht gespeichert werden');
    }
  }


  showFieldInfo(field) {
    if (field['placeholder'] && field['placeholder'].length > 0) {
      this.message.dialog(field['placeholder']);
    } else {
      this.message.dialog(field['label']);
    }
  }



  addRoom() {
    this.location.rooms.push({});
  }

  getLocation() {
    if (this.locationId) {
      this.firestore
        .collection('locations')
        .doc(this.locationId)
        .valueChanges({ idField: 'customIdName' })
        .subscribe((location: any) => {
          if (this.locationInitialized) {
            location.rooms = this.resolveOldRooms();
          }

          this.location = location;
          if (!this.location['rooms'] || this.location['rooms'].length == 0) {
            this.location['rooms'] = [{}];
          }
          console.log('Got location', this.location);
          this.locationInitialized = true;
          this.createLocationInfoForm();
        });
    }
  }

  createLocationInfoForm() {
    this.locationInfoForm = new FormGroup({});
    LOCATION_FIELDS.forEach((field) => {
      this.locationInfoForm.addControl(
        field.field,
        new FormControl(
          this.location[field.field],
          field.optional ? null : Validators.required
        )
      );
    });
  }

  deleteRoom(index) {
    console.log('Deleting location', index);

    this.location.rooms.splice(index, 1);
    this.saveLocation();
  }

  manageAccess() {
    let c = this.dialog.open(LocationAccessComponent).componentInstance;
    c.location = this.location;
  }

  releaseVenue() {
    if (this.locationIsReadyToRelease()) {
      let c = this.dialog.open(ReleaseVenueComponent).componentInstance;
      c.location = this.location;
    }
  }

  private locationIsReadyToRelease() {
    const firstRoom = this.location.rooms[0];
    const fieldsWithNull = Object.values(firstRoom).filter(f => f == null);
    if (Object.keys(firstRoom).length == 0 || fieldsWithNull.length > 0) {
      this.message.dialog('Bitte fülle zuerst die Informationen für mindestens einen Raum aus.');
      return false;
    }


    const latlng = this.location['location'];

    console.log('Checking Location', latlng);

    if (!latlng || !latlng.lat || !latlng.lng) {
      this.message.dialog('Bitte überprüfe die eingegebene Adresse, speichere deine Eingaben und versuche es erneut.');
      return false;
    }

    return true;
  }

  private saveLocation(form?) {
    // this.location['city'] = this.location.places[0]['city'] || ''; // Copy field for search queries, OLD
    console.log('Updating in firebase', this.location);
    try {
      this.firestore
        .collection('locations')
        .doc(this.locationId)
        .set(this.location)
        .then(
          () => {
            console.log('Updated');
            this.message.snackbarCenter('Änderungen wurden gespeichert');
            this.showSuccessMessage();
            if (form) {
              form.enable();
            }
          },
          (err) => {
            this.handleError(err, form);
          }
        );
    } catch (e) {
      this.handleError(e, form);
    }
  }

  private showSuccessMessage() {
    this.successMessage = true;
    setTimeout(() => {
      this.successMessage = false;
    }, 6000);
  }
  private handleError(err, form?) {
    console.error('Failed to update', err);
    this.message.snackbar('Die Änderungen konnten nicht gespeichert werden');
    if (form) {
      form.enable();
    }
  }


}
