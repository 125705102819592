<div class="request-details">
    <app-user *ngIf="currentRequest" [uid]="currentRequest['user']"></app-user><br>
    <b>Angefragter Zeitraum:</b>
    <p *ngIf="currentSlot">{{ currentSlot.startSlot.toDate() | date:'short'}} - {{ currentSlot.startSlot.toDate() | date:'short'}}
    </p>
    <b>Über die Veranstaltung:</b>
    <p *ngIf="currentRequest"> {{ currentRequest['description'] }}</p>
    <p *ngIf="currentRequest"><a matTooltip="Webseite öffnen" href="{{ currentRequest['website'] }}" target="_blank">{{ currentRequest['website'] }}</a></p>
    <b>Persönliche Nachricht:</b>
    <p *ngIf="currentRequest"> {{ currentRequest['message'] }}</p>

    <!-- <div class="hide-desktop" *ngIf="currentSlot['status'] == 'Reserviert'">
        <button mat-raised-button class="margin-right-16" (click)="cancelRequest(li, pi, si, ri)">Ablehnen</button>
        <button mat-raised-button color="primary" (click)="acceptRequest(li, pi, si, ri)">Annehmen</button>

    </div> -->
</div>