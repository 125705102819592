<div class="margin-32">
    <button mat-raised-button class="margin-right-16" color="primary" (click)="manageAccess()">Nutzer verwalten</button>


    <button *ngIf="!location['status'] || location['status'] == 'Wird erstellt'" mat-raised-button color="accent" (click)="releaseVenue()">Venue veröffentlichen</button>
</div>

<app-location-status-info [location]="location"></app-location-status-info>

<form class="card-round" (ngSubmit)="updateLocation()" [formGroup]="locationInfoForm">
    <h2>Venue bearbeiten</h2>


    <div *ngFor="let category of LOCATION_CATEGORIES">
        <div class="space-between" *ngIf="category">
            <h3 class="margin-top-32 flex-center-v" (click)="toggle(category)">
                <mat-icon [class.rotate-90]="!openedCategories.includes(category)">
                    keyboard_arrow_right</mat-icon>
                {{ category || 'Weiteres' }}
            </h3>
            <button class="description-button" mat-button matSuffix mat-icon-button tabindex="-1" matTooltip="Informationen zu diesem Feld" type="button" matTooltipPosition="above" (click)="showCategoryInfo(category)">
            <mat-icon>info</mat-icon>
          </button>
        </div>

        <div [class.place-field]="category" [class.hidden]="openedCategories.includes(category)">

            <mat-form-field *ngFor="let field of getFields(category)" class="width-100">
                <mat-label>{{ field.label }}</mat-label>
                <input *ngIf="!field.textarea" [id]="field.field" [formControlName]="field.field" matInput maxlength="120" [placeholder]="field.placeholder">
                <textarea *ngIf="field.textarea" maxlength="400" [id]="field.field" [formControlName]="field.field" matInput [placeholder]="field.placeholder"></textarea>

                <!-- <button mat-button matSuffix mat-icon-button tabindex="-1" matTooltip="Informationen zu diesem Feld" type="button" matTooltipPosition="above" (click)="showFieldInfo(field)">
                    <mat-icon>info</mat-icon>
                  </button> -->
            </mat-form-field>
        </div>

    </div>

    <div class="flex-right">
        <span class="flex-center-v margin-right-16" *ngIf="successMessage">
            <mat-icon>check</mat-icon>
            Änderungen gespeichert!
        </span>
        <button mat-raised-button color="primary" type="submit">Speichern</button>
    </div>
</form>

<app-edit-place [successMessage]="successMessage" (delete)="deleteRoom(i)" #editPlaceForm [placeIndex]="i" [placeInfo]="place" (placeInfoUpdate)="updatePlace($event, i)" *ngFor="let place of location.rooms; let i = index;">
</app-edit-place>

<div class="flex-center margin-top-32">
    <button mat-raised-button color="primary" (click)="addRoom()" type="submit">Weiteren Raum hinzufügen</button>
</div>