import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-delete-location',
  templateUrl: './delete-location.component.html',
  styleUrls: ['./delete-location.component.scss']
})
export class DeleteLocationComponent implements OnInit {
  public location = {};
  loading = false;

  constructor(private firestore: AngularFirestore, private dialogRef: MatDialogRef<DeleteLocationComponent>) { }

  ngOnInit(): void {
  }

  delete() {
    this.loading = true;
    this.firestore
      .collection('locations')
      .doc(this.location['customIdName'])
      .delete()
      .then(() => {
        this.loading = false;
        this.dialogRef.close();
      });
  }

}
