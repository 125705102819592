import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-location-message',
  templateUrl: './location-message.component.html',
  styleUrls: ['./location-message.component.scss'],
})
export class LocationMessageComponent implements OnInit {
  constructor(public dialogRef: MatDialogRef<LocationMessageComponent>) {
    this.data = {
      name: '',
      eMail: '',
      message: '',
    };
  }

  data: {
    name: string;
    eMail: string;
    message: string;
  };

  ngOnInit(): void {}

  onNoClick(): void {
    this.dialogRef.close();
  }
}
