import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-add-location',
  templateUrl: './add-location.component.html',
  styleUrls: ['./add-location.component.scss']
})
export class AddLocationComponent implements OnInit {
  location: any = {};
  loading = false;
  user = {};
  
  noSpacesRegex = /.*\S.*/;
  name = new FormControl('', [Validators.required, Validators.pattern(this.noSpacesRegex)]);

  getErrorMessage() {
    return this.name.hasError('required') ? 'You must enter a value' :
          this.name.hasError('pattern') ? 'You must enter a valid name' : '';
  }

  constructor(private authService: AngularFireAuth,
    private firestore: AngularFirestore,
    private router: Router,
    public dialogRef: MatDialogRef<AddLocationComponent>) { }

  ngOnInit(): void {
    this.authService.user
      .subscribe((user) => {
        console.log(user);
        this.user = user;
      });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  addLocation() {
    this.location.name = this.name.value;
    this.location.contractTemplateSrcs = [];
    this.location.imgSrcs = [];
    this.location.users = [{ 'uid': this.user['uid'], 'displayName': this.user['displayName'], 'role': 'Owner' }];
    this.loading = true;
    this.firestore
      .collection('locations')
      .add(this.location)
      .then((result: any) => {
        this.loading = false;
        console.log('Adding location finsihed', result);
        this.router.navigate(['/location/edit/' + result.id]);
        this.dialogRef.close();
      }).catch((err: any) => {
        console.error(err);
      });
  }
}
