<!-- <div class="aot-show-desktop">
    <img class="lading-logo-sadey" src="./assets/img/logo.svg">

    <div class="atf-container full-width">
        <div id="sadeyLogoContainer" class="left">
            <img id="sadeyLogo" src="./assets/img/sadey.png">
        </div>
        <div id="sadeyTextContainer" class="right position-relative">
            <img class="iamsadey" src="./assets/img/ich_bin_sadey.svg">

            <h1 id="smartPhoneAdjust" class="margin-top-32">Dein persönlicher<br id="bookAssistentBreak"> Venue-Buchunsassistent
            </h1>

            <app-change-text [text]="['Finde freie Venues für deine Tour', 'Füge deine Venues hinzu', 'Plane deine Tour']">
            </app-change-text>
        </div>
        <button mat-button class="find-more-btn">
      <mat-icon (click)="scroll('tutorial')" class="color-warm" inline="true">arrow_circle_down</mat-icon>
    </button>


    </div>
</div>

<app-aot-mobile class="aot-show-mobile"></app-aot-mobile> -->

<app-aot-new></app-aot-new>
<!-- <app-divi-tutorial id="tutorial" class="margin-top-32"></app-divi-tutorial> -->

<!-- <div class="hide-mobile flex-center aot-logo margin-top-32 margin-bottom-32">
    <img src="assets/img/sadey_loves_events_schriftzug.svg">
</div> -->

<!-- <app-trusticons></app-trusticons>

<div class="hide-desktop flex-center aot-logo margin-top-32">
    <img src="assets/img/sadey_loves_events_schriftzug.svg">
</div> -->


<!-- 

<div id="tourplanningContainer" class="flex-center background-white padding-top-32 full-width padding-bottom-32">
  <div id="tourheadlineContainer" class="width-40 pl-50">
    <span class="pre-headline">TOURPLANUNG</span>
    <h2 class="headline">Venue finden in wenigen Minuten</h2>
    <p class="color-second margin-right-32">
      Durch SADEY kannst du für deine Tour eine optimale Route planen. Und das alles auf dem schnellsten und einfachsten
      Weg!
    </p>
  </div>
  <div id="videoContainer" class="width-60 pr-50 minSize">
    <video #video autoplay muted loop plazsinline repeat class="tutorial-video">
      <source src="assets/videos/tutorial.mp4">
    </video>
  </div>
</div>


-->