import { Component, OnInit, ViewChild } from '@angular/core';
import { LocationService } from 'src/app/services/location.service';

@Component({
  selector: 'app-location-picker',
  templateUrl: './location-picker.component.html',
  styleUrls: ['./location-picker.component.scss'],
})
export class LocationPickerComponent implements OnInit {
  locations = [];

  @ViewChild('formField') formField;
  currentSelection = {};
  constructor(
    public locationService: LocationService
  ) {
  }

  async ngOnInit() {
    console.log('LocationPickerComponent init');
    this.getMyLocations();
  }

  private async getMyLocations() {
    (await this.locationService.getMyLocations())
      .subscribe((locations) => {
        if (this.locations.length == 0) {
          this.locations = locations;
        }
        // this.currentSelection = this.locationService.currentSelection;
        setTimeout(() => {
          this.currentSelection = this.locationService;
        }, 1000);
      });

  }

  public triggerLocationChange() {
    console.log('Update currentSelection to', this.currentSelection)
  }
}