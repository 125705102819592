import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { isSameDay } from 'date-fns';
import { eventJSDate } from 'src/app/types/eventJSDate.type';
import { getJsDate, getRoomOfSlot } from 'src/app/utils/helpers';

@Component({
  selector: 'app-location-mini-info',
  templateUrl: './location-mini-info.component.html',
  styleUrls: ['./location-mini-info.component.scss'],
})
export class LocationMiniInfoComponent implements OnInit, OnChanges {
  @Input() location: any = {};
  @Input() selectedBookingDate: Date = new Date();
  @Output() selected = new EventEmitter();
  bookableSlots: { daySlot: {}; room: {} }[] = [];
  loaded: boolean = false;

  constructor(private firestore: AngularFirestore) {}

  ngOnInit(): void {
    this.setDaySlots();
  }

  ngOnChanges(){
    console.log('selectedBookingDate updated', this.selectedBookingDate);
    this.setDaySlots();
  }

  private setDaySlots() {
    this.firestore
      .collection('event-slots', (ref) =>
        ref.where('locationId', '==', this.location['customIdName'])
      )
      .valueChanges()
      .subscribe((locationSlots) => {
        let daySlots = this.filterDaySlots(locationSlots);
        this.bookableSlots = this.getBookableSlots(daySlots);
        this.loaded = true;
      });
  }

  private filterDaySlots(locationSlots) {
    return locationSlots.filter((s) => {
      s['startSlot'] = getJsDate(s['startSlot']);
      s['endSlot'] = getJsDate(s['endSlot']);
      return (
        isSameDay(s['startSlot'], this.selectedBookingDate) ||
        isSameDay(s['endSlot'], this.selectedBookingDate)
      );
    });
  }

  private getBookableSlots(daySlots) {
    let bookableSlots = [];
    for (let i = 0; i < daySlots.length; i++) {
      const s = daySlots[i];
      let room = getRoomOfSlot(s, this.location);
      let bookableSlot = { daySlot: s, room: room };
      bookableSlots.push(bookableSlot);
    }
    return bookableSlots;
  }

  /**
   * emit
   */
  public emit() {
    this.selected.emit({
      location: this.location,
      bookableSlots: this.bookableSlots,
    });
  }
}
