import { Component, Input, OnInit } from '@angular/core';
import { tourInfo } from 'src/app/types/tourInfo.type';

@Component({
  selector: 'app-tour',
  templateUrl: './tour.component.html',
  styleUrls: ['./tour.component.scss'],
})
export class TourComponent implements OnInit {
  constructor() {}

  @Input() tour = {};
  public opened: boolean = true;

  ngOnInit(): void {
    console.log('tour', this.tour);
  }

  toggle() {
    this.opened = !this.opened;
  }
}
