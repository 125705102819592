import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatRadioButton } from '@angular/material/radio';
import { locationExamination } from 'src/app/types/locationExamination.type';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AngularFirestore } from '@angular/fire/firestore';

@Component({
  selector: 'app-location-card',
  templateUrl: './location-card.component.html',
  styleUrls: ['./location-card.component.scss'],
})
export class LocationCardComponent implements OnInit {
  @Input() location: any = {};
  owner = {};
  @Output() examinationResult = new EventEmitter<locationExamination>();
  @ViewChild('correct') correctBtn: MatRadioButton;
  @ViewChild('wrong') wrongBtn: MatRadioButton;

  constructor(
    private snackBar: MatSnackBar,
    private firestore: AngularFirestore
  ) {}

  ngOnInit(): void {
    this.resolveOwner();
  }

  resolveOwner() {
    console.log('location is', this.location);
    let ownerId = undefined;
    if ('users' in this.location) {
      ownerId = this.location['users'].find((u) => u.role == 'Owner')['uid'];
    } else {
      console.error('Error, no users found');
    }
    // let ownerId = this.location['users'].find((u) => u.role == 'Owner')['uid'];
    console.log('ownerId', ownerId);
    this.firestore
      .collection('users')
      .doc(ownerId)
      .valueChanges()
      .subscribe((owner) => {
        console.log('owner', owner);
        this.owner = owner;
      });
  }

  /**
   *
   */
  public examine() {
    if (this.correctBtn.checked) {
      this.emitResult({
        location: this.location,
        result: true,
      });
    }
    if (this.wrongBtn.checked) {
      this.emitResult({
        location: this.location,
        result: false,
      });
    }
    if (!this.correctBtn.checked && !this.wrongBtn.checked) {
      this.snackBar.open(
        'Es muss Freigeben oder Ablehnen ausgewählt werden.',
        null,
        {
          duration: 3000,
        }
      );
    }
  }

  private emitResult(result: locationExamination) {
    this.examinationResult.emit(result);
  }
}
