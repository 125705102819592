export const NGX_AUTH_FIREBASEUI_REG = {
    nameText: 'Name',
    nameErrorRequiredText: 'Name ist erforderlich',
    emailText: 'Email',
    emailErrorRequiredText: 'E-Mail ist erforderlich',
    emailErrorPatternText: 'Bitte gebe eine gültige E-Mail-Adresse ein',
    passwordText: 'Passwort',
    passwordErrorRequiredText: 'Bitte Passwort eingeben',
    passwordConfirmationText: 'Passwort Bestätigung',
    passwordConfirmationErrorRequiredText: 'Eine Passwortbestätigung ist erforderlich',
    passwordErrorMatchText: 'Passwort muss übereinstimmen',
    titleText: 'ERSTELLEN EINES KONTOS',
    createAccountButtonText: 'KONTO ERSTELLEN',
    alreadyHaveAccountText: 'Hast du bereits ein Konto?',
    loginButtonText: 'Einloggen',
    passwordErrorMaxLengthText: 'Passwort ist zu lang!',
    passwordErrorMinLengthText: 'Passwort ist zu kurz!'
};

export const NGX_AUTH_FIREBASEUI_LOGIN = {
    titleText: 'EINLOGGEN',
    loginButtonText: 'EINLOGGEN',
    forgotPasswordText: 'Passwort vergessen?',
    emailText: 'Email',
    emailErrorRequiredText: 'Bitte E-Mail eingeben',
    emailErrorPatternText: 'Bitte gebe eine gültige E-Mail-Adresse ein',
    passwordText: 'Passwort',
    passwordErrorRequiredText: 'Passwort wird benötigt',
    emailVerificationNeededText: 'Bitte bestätige deine E-Mail-Adresse'
}