import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Subject } from 'rxjs';
import { getSlotsInJsDate } from '../utils/helpers';
import { LocationService } from './location.service';

@Injectable({
  providedIn: 'root',
})
export class RoomEventSlotsService {
  public eventSlots$: Subject<{}[]> = new Subject();

  private roomUUID = undefined;

  constructor(
    private firestore: AngularFirestore,
    private locationService: LocationService
  ) {}

  /**
   * updateCurrentRoom
   */
  public updateCurrentRoom() {
    // console.log(
    //   'Room UUID:',
    //   this.locationService.currentLocationRoom()['UUID']
    // );

    this.roomUUID = this.locationService.currentLocationRoom()['UUID'];
    this.firestore
      .collection('event-slots', (ref) =>
        ref.where(
          'locationId',
          '==',
          this.locationService.currentLocation()['customIdName']
        )
      )
      .valueChanges()
      .subscribe((eventSlots: {}[]) => {
        // console.log('subscribe eventSlots', eventSlots);
        let processedSlots = this.processEventSlots(eventSlots);
        this.eventSlots$.next(processedSlots);
      });
  }

  private processEventSlots(eventSlots: {}[]) {
    let filteredSlots = eventSlots.filter((slot) => {
      return slot['roomUUID'] === this.roomUUID;
    });
    return getSlotsInJsDate(filteredSlots);
  }
}
