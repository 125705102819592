import { Component, Input, OnInit } from '@angular/core';
import { LocationService } from 'src/app/services/location.service';

@Component({
  selector: 'app-location-name',
  templateUrl: './location-name.component.html',
  styleUrls: ['./location-name.component.scss']
})
export class LocationNameComponent implements OnInit {
  @Input() id;
  location;

  constructor(private locationService: LocationService) { }

  ngOnInit(): void {
    this.loadLocation();
  }

  private loadLocation() {
    this.locationService
      .getLocation(this.id)
      .subscribe((location) => {
        this.location = location;
      });
  }

}
