<div class="space-between">
    <h1>Tour buchen</h1>


    <!-- <mat-form-field appearance="fill">
        <mat-label>Bitte Typ auswählen</mat-label>
        <mat-select [(ngModel)]="tourType">
            <mat-option value="Konzert">Konzert</mat-option>
            <mat-option value="Theater">Theater</mat-option>
            <mat-option value="Ballet">Ballet</mat-option>
            <mat-option value="Musical">Musical</mat-option>
            <mat-option value="Anderes">Anderes</mat-option>
        </mat-select>
    </mat-form-field> -->
</div>

<app-tour-planner-filters #filters (selectedCity)="goToCity($event)" (selectedDate)="setBookingDate($event)"
    (selectedPriceRange)="setPriceRange($event)" (selectedRange)="setRange($event)"
    (selectedStageSize)="setMinimumStageSize($event)">
</app-tour-planner-filters>

<div id="booking-section" class="booking-section">

    <div class="booking-info">
        <div *ngIf="selectedLocations.length == 0" class="margin-bottom-32">
            Hier werden deine ausgewählten Locations angezeigt.
        </div>

        <div class="backgroundContainer">
            <div class="labelContainer">
                <div class="labelLine"></div>
                <div class="labelText">Ausgewählt</div>
            </div>

            <div>
                <app-selected-locations (deletedSelectedLocation)=deleteThisSelectedLocation($event)
                    [selectedLocation]="l" *ngFor="let l of selectedLocations"></app-selected-locations>
            </div>
        </div>

        <button id="bookTourBtn" (click)="requestEvents()" class="margin-left-20" mat-raised-button
            color="primary">Jetzt
            bestätigen</button>
    </div>

    <div class="booking-map">
        <agm-map [zoom]="zoom" [styles]="styles" (mapReady)="onMapReady($event)" [latitude]="lat" [longitude]="lng">
            <agm-marker *ngFor="let l of filteredLocations" [latitude]="l.location.lat" [longitude]="l.location.lng">
                <agm-info-window>
                    <app-location-mini-info (selected)="addLocation($event)" [location]="l"
                        [selectedBookingDate]="selectedBookingDate"></app-location-mini-info>
                </agm-info-window>
            </agm-marker>
        </agm-map>
    </div>


    <div class="booking-info pl-12">

        <div class="backgroundContainer">
            <div class="labelContainer">
                <div class="labelLine"></div>
                <div class="labelText">Gefunden</div>
            </div>
            <app-tour-planner-search-result (select)="focusLocation(l)" [distance]="getDistance(l)" [location]="l"
                *ngFor="let l of filteredLocations"></app-tour-planner-search-result>
        </div>
    </div>

</div>