import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { MatCalendar } from '@angular/material/datepicker';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatSliderChange } from '@angular/material/slider';
import { CITIES } from 'src/app/utils/constants/cities';
import { defaultPriceRange } from 'src/app/utils/constants/defaultPriceRange';
import { capitalizeFirstLetter, validateFormControl } from 'src/app/utils/helpers';
import { defaultRange } from 'src/app/utils/constants/defaultRange';
import { stageSize } from 'src/app/types/stageSize.type';
import { defaultSelectedStageSize } from 'src/app/utils/constants/defaultSelectedStageSize';

@Component({
  selector: 'app-tour-planner-filters',
  templateUrl: './tour-planner-filters.component.html',
  styleUrls: ['./tour-planner-filters.component.scss'],
})
export class TourPlannerFiltersComponent implements OnInit {

  cityControl = new FormControl('', [Validators.required]);
  filteredCities = [];
  @Output() selectedCity: EventEmitter<string> = new EventEmitter();
  @Output() selectedDate: EventEmitter<Date> = new EventEmitter();
  @Output() selectedStageSize: EventEmitter<stageSize> = new EventEmitter();
  @Output() selectedPriceRange: EventEmitter<number> = new EventEmitter();
  @Output() selectedRange: EventEmitter<number> = new EventEmitter();
  @ViewChildren(MatMenuTrigger) trigger: QueryList<MatMenuTrigger>;
  @ViewChild(MatCalendar) datepicker: MatCalendar<Date>;

  choosenDate: Date = new Date();

  // Range filter
  range = defaultRange;
  newRange = defaultRange;

  // Date filter
  date = new Date();
  newDate = new Date();

  // Price filter
  priceRange = defaultPriceRange;
  newPriceRange = defaultPriceRange;

  // StageSize Filter
  stageSize = {
    newStageDepth: defaultSelectedStageSize.stageDepth,
    newStageBreadth: defaultSelectedStageSize.stageDepth,
    newStageHeight: defaultSelectedStageSize.stageHeight,
  };


  dateHasBeenSet = false;

  constructor() { }

  ngOnInit(): void {
    this.autoComplete();
  }

  public increaseDate() {
    this.choosenDate.setDate(this.choosenDate.getDate() + 1);
    this.choosenDate = new Date(this.choosenDate);
    this.selectDate();
  }

  stopPropagationOnTab(event: KeyboardEvent) {
    event.stopPropagation();
  }

  stopPropagation(event: MouseEvent) {
    event.stopPropagation();
  }

  upateRange(e: MatSliderChange) {
    this.newRange = e.value;
  }

  selectCity() {
    if (validateFormControl(this.cityControl, 'Stadt')) {
      if (
        CITIES.find(
          (city) => city.toLowerCase() == this.cityControl.value.toLowerCase()
        )
      ) {
        // let addressInfo = await this.locationService.resolveAddressInfo(this.cityControl.value);
        // addressInfo['city'] = this.cityControl.value;
        // this.selectedCity.emit(addressInfo);

        this.selectedCity.emit(this.cityControl.value);
        this.trigger.forEach((t) => t.closeMenu());
      }
    }
  }

  public setCity(city) {
    city = capitalizeFirstLetter(city);
    this.cityControl.setValue(city);
    this.selectCity();
  }

  selectDate() {
    this.selectedDate.emit(this.choosenDate);
    this.trigger.forEach((t) => t.closeMenu());
    this.dateHasBeenSet = true;
  }

  selectRange() {
    this.range = this.newRange;
    this.selectedRange.emit(this.range);
    this.trigger.forEach((t) => t.closeMenu());
  }

  selectStageSize() {
    let stageSize: stageSize = {
      stageBreadth: this.stageSize.newStageBreadth,
      stageDepth: this.stageSize.newStageDepth,
      stageHeight: this.stageSize.newStageHeight,
    };
    this.selectedStageSize.emit(stageSize);
    this.trigger.forEach((t) => t.closeMenu());
  }

  autoComplete() {
    this.cityControl.valueChanges.subscribe((value) => {
      const filterValue = value.toLowerCase();
      this.filteredCities = CITIES.filter(
        (option) => option.toLowerCase().trim().indexOf(filterValue) === 0
      );
    });
  }

  selectPriceRange() {
    this.priceRange = this.newPriceRange;
    this.selectedPriceRange.emit(this.priceRange);
    this.trigger.forEach((t) => t.closeMenu());
  }

  formatSliderLabel(value: number) {
    return value;
  }

  formatSliderLabelPrice(value: number) {
    if (value >= 1000) {
      return (value / 1000).toFixed(0) + 'k';
    } else {
      return value;
    }
  }

  formatSliderLabelStageSize(value: number) {
    return value;
  }
}
