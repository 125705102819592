<div class="dialog-container access-container">

    <!-- <h1 mat-dialog-title>Herzlich willkommen!</h1> -->

    <div mat-dialog-content>
        <div class="flex-center">
            <img class="dialog-logo" src="assets/img/favicon.png">
        </div>

        <div *ngIf="user['userType'] != 'locations'" class="margin-top-16">
            <p>
                <b>Willkommen bei SADEY,</b> die virtuelle Assistenz der Veranstaltungsbranche!<br> Es ist wunderbar, dass Du Teil unserer Community wirst.
            </p>

            <p>
                Wie geht’s los und wie geht’s weiter?<br> Ganz einfach,<br>
                <b>SADEY ist simpel und zu 100% intuitiv.</b><br> Du findest Deinen Fahrplan & jedwede Antworten in den FAQs.
            </p>

            <p>
                Mit click auf „{{'{'}}info Sign{{'}'}}“, rechts oben, erhälst Du jederzeit hilfreiche Informationen zum entsprechenden Seiten - Bereich.
            </p>

            <p>
                <b>SADEY ist kommunikativ, transparent und interaktiv.</b><br> Du wirst konsequent über alle Ereignisse und Updates informiert.
            </p>
        </div>

        <div *ngIf="user['userType'] == 'locations'" class="margin-top-16">

            <p>
                <b>Willkommen bei SADEY,</b> die virtuelle Assistenz der Veranstaltungsbranche!<br> Es ist wunderbar, dass Du Teil unserer Community wirst.
            </p>

            <p>
                Wie geht’s los und wie geht’s weiter?<br> Ganz einfach,<br>
                <b>SADEY ist simpel und zu 100% intuitiv.</b><br> Du findest Deinen Fahrplan & jedwede Antworten in den FAQs.
            </p>

            <p>
                Mit click auf „{{'{'}}info Sign{{'}'}}“, rechts oben, erhälst Du jederzeit hilfreiche Informationen zum entsprechenden Seiten - Bereich.
            </p>

            <p>
                <b>SADEY ist kommunikativ, transparent und interaktiv.</b><br> Du wirst konsequent über alle Ereignisse und Updates informiert.
            </p>

        </div>




        <div class="flex-center">
            <img class="sadey-loves-events" src="assets/img/sadey_loves_events_schriftzug.svg">
        </div>
    </div>

    <div mat-dialog-actions class="flex-right">
        <button mat-raised-button (click)="markAsRead()" color="primary">Auf geht's</button>
    </div>
</div>