<div class="trust-row">
    <div id="platformHeadline" class="pre-headline flex-center margin-bottom-32">
        SADEY BIETET EINE SICHERE PLATTFORM
    </div>
    <div class="trusticon-container margin-16">
        <!-- <img class="trusticon margin-16" src="assets/img/trustsymbols/dsgvo.jpg"> -->
        <img class="trusticon margin-16" src="assets/img/trustsymbols/nutzergruppen.png">
        <img class="trusticon margin-16" src="assets/img/trustsymbols/lock.png">
        <img class="trusticon margin-16" src="assets/img/trustsymbols/server.png">
        <img class="trusticon margin-16" src="assets/img/trustsymbols/germany.png">
    </div>
</div>