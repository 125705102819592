import { Component, OnInit, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { LocationService } from 'src/app/services/location.service';
import { choosableSlotsOfLocation } from 'src/app/types/choosableSlotsOfLocation.type';
import { selectedEventSlot } from 'src/app/types/selectedEventSlot.type';
import { stageSize } from 'src/app/types/stageSize.type';
import { defaultPriceRange } from 'src/app/utils/constants/defaultPriceRange';
import { defaultRange } from 'src/app/utils/constants/defaultRange';
import {
  distance,
  isLocationLngLatExistent,
  isValidDateAndPrice,
  isWithinStageSize,
  isApproved,
  hasImgs,
  getEventlerTxt,
  getLocationOwners,
  getLocationOwnerTxtRequest,
  isImgInRoom,
  isPreValidLocation,
  isSlotAlreadychoosen,
} from 'src/app/utils/helpers';
import { ChooseEventSlotComponent } from '../dialogs/choose-event-slot/choose-event-slot.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AngularFireAuth } from '@angular/fire/auth';
import { defaultSelectedStageSize } from 'src/app/utils/constants/defaultSelectedStageSize';
import { requestSlotTypes } from 'src/app/utils/constants/requestSlotTypes';
import { first } from 'rxjs/operators';
import { Router } from '@angular/router';
import { MessageService } from 'src/app/services/message.service';
import { GOOLE_MAPS_STYLE } from 'src/app/utils/constants/google-maps-style';
import { NotificationService } from 'src/app/services/notification.service';
import { DatePipe } from '@angular/common';
import { notificationTypes } from 'src/app/utils/constants/notificationTypes';
import { eventSlotStatus } from 'src/app/utils/constants/eventSlotStatus';
import { EnterTourDataComponent } from '../dialogs/enter-tour-data/enter-tour-data.component';

@Component({
  selector: 'app-tour-planner',
  templateUrl: './tour-planner.component.html',
  styleUrls: ['./tour-planner.component.scss'],
})
export class TourPlannerComponent implements OnInit {
  zoom = 5;
  lat = 51.678418;
  lng = 7.809007;
  locations = [];
  filteredLocations: any[] = [];
  selectedLocations: selectedEventSlot[] = [];
  selectedBookingDate: Date = new Date();
  selectedPriceRange: number = defaultPriceRange;
  selectedRange: number = defaultRange;
  selectedStageSize: stageSize = {
    stageDepth: defaultSelectedStageSize.stageDepth,
    stageBreadth: defaultSelectedStageSize.stageBreadth,
    stageHeight: defaultSelectedStageSize.stageHeight,
  };
  currentUser;
  tourType = 'Konzert';
  tourName = '';
  @ViewChild('filters') filters;
  styles = GOOLE_MAPS_STYLE;

  constructor(
    private authService: AngularFireAuth,
    private firestore: AngularFirestore,
    private locationService: LocationService,
    private datePipe: DatePipe,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private router: Router,
    private message: MessageService,
    private notificationService: NotificationService
  ) { }

  async ngOnInit(): Promise<void> {
    this.getLocation();
    this.subscribeLocations();
    this.currentUser = await this.authService.user.pipe(first()).toPromise();
  }

  onMapReady(map?: google.maps.Map) {
    if (map)
      map.setOptions({
        streetViewControl: false,
      });

    // console.log('Google map is', map);
  }

  addLocation(choosenSlots: choosableSlotsOfLocation) {
    const chooseSlotDialog = this.openEventSlotDialog(choosenSlots);
    chooseSlotDialog.afterClosed().subscribe((result: selectedEventSlot) => {
      if (!result || isSlotAlreadychoosen(this.selectedLocations, result)) {
        this.message.snackbar('Event Slot nicht hinzugefügt.');
        return;
      }
      console.log('Selected eventSlot', result);
      console.log('Selected eventSlot city', result['location']['city']);
      this.filters.setCity(result['location']['city']);
      this.selectedLocations.push(result);
      this.filters.increaseDate();
      // console.log('Increase', this.filters);
      return;
    });
  }

  private openEventSlotDialog(choosenSlots: choosableSlotsOfLocation) {
    const dialog = this.dialog.open(ChooseEventSlotComponent, {
      minWidth: '80vw',
      data: {
        chooseableSlots: choosenSlots,
        choosenDate: this.selectedBookingDate,
        tourType: this.tourType,
      },
    });
    return dialog;
  }

  getDistance(location): number {
    // console.log('distance', this.lat, this.lng, location['location'].lat, location['location'].lng);
    // console.log('distance', distance(this.lat, this, location['location'].lat, location['location'].lng));
    if (!location['location']) {
      console.warn(`Location ${location['name']} seems to be INVALID and might break the system. Please check the data and fix it / delete it.`, location);

      return 0;
    }
    let dst = distance(
      this.lat,
      this.lng,
      location['location'].lat,
      location['location'].lng
    );
    dst = Math.round(dst * 100) / 100;
    return dst;
  }

  async goToCity(city) {
    console.log('Go to city', city);
    const info = await this.locationService.resolveAddressInfo(city);
    // console.log('Info is', info);
    this.changeLatLng(info.lat, info.lng);
    this.setFilteredLocations(
      this.selectedRange,
      this.selectedBookingDate,
      this.selectedPriceRange,
      this.selectedStageSize
    );
  }

  setBookingDate(date: Date) {
    this.selectedBookingDate = date;
    this.setFilteredLocations(
      this.selectedRange,
      this.selectedBookingDate,
      this.selectedPriceRange,
      this.selectedStageSize
    );
  }

  setPriceRange(price: number) {
    this.selectedPriceRange = price;
    this.setFilteredLocations(
      this.selectedRange,
      this.selectedBookingDate,
      this.selectedPriceRange,
      this.selectedStageSize
    );
  }

  setRange(range: number) {
    this.selectedRange = range;
    this.setFilteredLocations(
      this.selectedRange,
      this.selectedBookingDate,
      this.selectedPriceRange,
      this.selectedStageSize
    );
  }

  setMinimumStageSize(stageSize: stageSize) {
    // console.log('stageSize: ', stageSize);
    this.selectedStageSize.stageBreadth = stageSize.stageBreadth;
    this.selectedStageSize.stageDepth = stageSize.stageDepth;
    this.selectedStageSize.stageHeight = stageSize.stageHeight;
    this.setFilteredLocations(
      this.selectedRange,
      this.selectedBookingDate,
      this.selectedPriceRange,
      this.selectedStageSize
    );
  }

  deleteThisSelectedLocation(selectedLocation: selectedEventSlot) {
    // console.log('selectedLocation:', selectedLocation);
    let idx = this.selectedLocations.findIndex((s: selectedEventSlot) => {
      return (
        s['location']['customIdName'] ===
        selectedLocation['location']['customIdName'] &&
        s['room']['UUID'] === selectedLocation['room']['UUID'] &&
        s['daySlot']['customIdName'] ===
        selectedLocation['daySlot']['customIdName']
      );
    });
    this.selectedLocations.splice(idx, 1);
  }

  public requestEvents() {
    if (this.selectedLocations.length === 0) {
      this.snackBar.open('Keine Events ausgewählt', null, {
        duration: 2000,
      });
      return;
    }


    let dialog = this.dialog.open(EnterTourDataComponent);

    dialog.afterClosed().subscribe((info) => {
      this.tourType = info['tourType'];
      this.tourName = info['tourName'];
      this.setSlotsTourType();
      this.updateEventSlotRequests();
      let tourId = this.firestore.collection('tours').doc().ref.id;
      this.saveTour(tourId);
      this.sendNotifications(tourId);
    });
  }

  private setSlotsTourType() {
    for (let i = 0; i < this.selectedLocations.length; i++) {
      const s = this.selectedLocations[i];
      const daySlot = s['daySlot'];
      for (let j = 0; j < daySlot['requests'].length; j++) {
        const r = daySlot['requests'][j];
        if (r['UUID'] === s['bookingRequestUUID']) {
          r['category'] = this.tourType;
        }
      }
    }
  }

  private updateEventSlotRequests() {
    for (let i = 0; i < this.selectedLocations.length; i++) {
      const s = this.selectedLocations[i]['daySlot'];

      if (!s['customIdName']) {
        console.error('Tourslot has no customIDField', s);
      }

      this.firestore
        .collection('event-slots')
        .doc(s['customIdName'])
        .update(s)
        .catch((error) => {
          console.log(error, ' Error occured while updating eventslot: ', s);
        });
    }
  }

  private sendNotifications(tourId: string) {
    for (let i = 0; i < this.selectedLocations.length; i++) {
      const s = this.selectedLocations[i];
      const date = this.datePipe.transform(s['daySlot']['startSlot'], 'short');
      const payload = {
        tourId: tourId,
        slotId: s['daySlot']['customIdName'],
        locationId: s['location']['customIdName'],
        requestUUID: s['bookingRequestUUID'],
        type: requestSlotTypes.tourSlot,
        roomUUID: s['room']['UUID'],
      };
      this.sendEventlerNotification(s, date, payload);
      this.sendLocationOwnersNotification(s, date, payload);
    }
  }

  private sendEventlerNotification(s, date, payload) {
    this.notificationService.sendNotification(
      this.currentUser.uid,
      getEventlerTxt(s['location']['name'], date),
      notificationTypes.bookingStarted,
      payload
    );
  }

  private sendLocationOwnersNotification(slot, date, payload) {
    let locationOwners = getLocationOwners(slot.location);
    for (let i = 0; i < locationOwners.length; i++) {
      const uid = locationOwners[i]['uid'];
      this.notificationService.sendNotification(
        uid,
        getLocationOwnerTxtRequest('Tour', slot.location['name'], date),
        notificationTypes.requestSlot,
        payload
      );
    }
  }

  private saveTour(tourId) {
    let tour = this.createTour(tourId);
    this.firestore
      .collection('tours')
      .doc(tour['tourId'])
      .set(tour)
      .then(() => {
        this.message.snackbar('Die Venues wurden angefragt.');
        this.router.navigate(['my-tours']);
        console.log('Added Tour successfully');
      })
      .catch((error) => {
        console.error(error, 'Error occured while Adding the tour ', tour);
      });
  }

  private createTour(tourId) {
    let tour = {
      uid: this.currentUser['uid'],
      tourType: this.tourType,
      tourName: this.tourName,
      tourId: tourId,
      tourSlots: [],
    };
    for (let i = 0; i < this.selectedLocations.length; i++) {
      const s = this.selectedLocations[i];
      let tourSlot = {
        locationId: s['location']['customIdName'],
        roomUUID: s['room']['UUID'],
        eventSlotId: s['daySlot']['customIdName'],
        status: eventSlotStatus.reserved,
      };
      tour['tourSlots'].push(tourSlot);
    }
    return tour;
  }

  focusLocation(location) {
    this.changeLatLng(location['location'].lat, location['location'].lng);
  }

  changeLatLng(lat, lng) {
    this.lat = lat;
    this.lng = lng;
  }

  private getLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.lat = position.coords.latitude;
        this.lng = position.coords.longitude;
      });
    }
  }

  private setFilteredLocations(
    range: number,
    date: Date,
    priceRange: number,
    minStageSize: stageSize
  ): void {
    let prefiltered = this.locations.filter((l) => {
      return this.getDistance(l) <= range && isWithinStageSize(l, minStageSize);
    });
    this.setFilteredByDateAndPrice(prefiltered, date, priceRange);
  }

  private subscribeLocations() {
    this.firestore
      .collection('locations')
      .valueChanges({ idField: 'customIdName' })
      .subscribe((locations) => {
        // only valid Locations
        let prefiltered = locations.filter((l) => {
          return isPreValidLocation(l);
        });
        this.locations = prefiltered.filter((l) => {
          return this.isValidLocation(l);
        });
        this.setFilteredLocations(
          this.selectedRange,
          this.selectedBookingDate,
          this.selectedPriceRange,
          this.selectedStageSize
        );
      });
  }

  private async isValidLocation(l: {}) {
    let locationEventSlots = await this.getLocationEventSlots(l);
    for (let i = 0; i < l['rooms'].length; i++) {
      const room = l['rooms'][i];
      if (
        isImgInRoom(room) &&
        this.isEventSlotInRoom(locationEventSlots, room)
      ) {
        return true;
      }
    }
    return false;
  }

  private async getLocationEventSlots(l) {
    let slots = await this.firestore
      .collection('event-slots', (ref) =>
        ref.where('locationId', '==', l['customIdName'])
      )
      .valueChanges({ idField: 'customIdName' })
      .pipe(first())
      .toPromise();
    return slots.filter(s => s['status'] != 'Gebucht');
  }

  private isEventSlotInRoom(eventSlots: {}[], room): boolean {
    for (let i = 0; i < eventSlots.length; i++) {
      const slot = eventSlots[i];
      if (slot['roomUUID'] === room['UUID']) {
        return true;
      }
    }
    return false;
  }

  private async setFilteredByDateAndPrice(
    prefiltered: {}[],
    date: Date,
    priceRange: number
  ) {
    let filtered = [];
    for (let i = 0; i < prefiltered.length; i++) {
      const l = prefiltered[i];
      let slots = await this.getLocationEventSlots(l);
      if (isValidDateAndPrice(slots, date, priceRange)) {
        filtered.push(l);
      }
    }
    this.filteredLocations = filtered;
  }
}
