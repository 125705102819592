<app-slideshow #slideshow [images]="locationService.currentLocationRoom()['images']"></app-slideshow>

<div class="card-round page-card">
    <div class="space-between">
        <h2>Bilder ({{ locationService.currentLocationName() }}, {{ locationService.currentLocationRoomName() }})</h2>
        <app-location-picker></app-location-picker>

    </div>
   

    <input hidden #input mat-raised-button color="primary" type="file" name="file" (change)="handleImgInput($event.target.files)" onclick="this.value = null">
    <!--  accept="image/*" -->

    <div class="margin-top-32 image-row" *ngIf="locationService.currentLocationRoom()['images'] && locationService.currentLocationRoom()['images'].length > 0; else no_images">
        <div *ngFor="let url of locationService.currentLocationRoom()['images']; let i = index;" class="image-container">
            <mat-icon (click)="removeFile(url)" matTooltip="Bild löschen" matTooltipPosition="above">close</mat-icon>
            <img (click)="slideshow.open(i)" *ngIf="url" [src]="url">
        </div>
    </div>

    <h2>Downloads (Grundrisse, technische Zeichnungen etc.)</h2>


    <ul class="margin-top-32" *ngIf="locationService.currentLocationRoom()['downloads'] && locationService.currentLocationRoom()['downloads'].length > 0; else no_images2">
        <li *ngFor="let url of locationService.currentLocationRoom()['downloads']; let i = index;">
            <a [href]="url" target="_blank"> {{ getFileName(url) }}</a>
        </li>
    </ul>


    <ng-template #no_images2>
        alle gängigen Dokumente (pdf, jpg, txt, ...) sind möglich und werden erkannt
    </ng-template>

    <ng-template #no_images>
        Bitte lade min. 3-5 Bilder hoch, in Querformat, farbig und möglichst hoch aufgelöst
    </ng-template>
    <button class="fab-button" matTooltip="Bild hochladen" matTooltipPosition="above" *ngIf="location?.imgSrcs?.length < MAX_IMG_UPLOAD" (click)="input.click()" mat-fab color="primary">
        <mat-icon>add</mat-icon>
    </button>
    <!-- <button *ngIf="!user?.accountUnlocked" (click)="activateAccount()" [disabled]="!hasMinImgs()" mat-raised-button color="primary" class="account-unlock">Account Freischalten</button> -->
</div>