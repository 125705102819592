import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-tour-planner-search-result',
  templateUrl: './tour-planner-search-result.component.html',
  styleUrls: ['./tour-planner-search-result.component.scss']
})
export class TourPlannerSearchResultComponent implements OnInit {
  constructor() { }
  @Input() location = {};
  @Input() distance = '';
  @Output() select = new EventEmitter();

  ngOnInit(): void {
  }

  getDistance() {
    return (this.distance + '').replace('.', ',');
  }

}
