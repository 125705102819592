<div class="flex-column flex-center-v text-center">
      <h1>Datenschutzerklärung</h1>
      <p class="c0 c2 "><span class="c1 "></span></p>
      <p class="c0 c2 "><span class="c1 "></span></p>
      <p class="c0 "><span class="c1 ">Verantwortlicher im Sinne der Datenschutzgesetze, insbesondere der
                  EU-Datenschutzgrundverordnung (DSGVO), ist:</span></p>
      <p class="c0 c2 "><span class="c1 "></span></p>
      <p class="c0 "><span class="c1 ">SADEY UG, Geschäftsführung: Leona Gieske</span></p>
      <p class="c0 "><span class="c1 ">Alt-Stralau 52</span></p>
      <p class="c0 "><span class="c1 "> D-10245 Berlin</span></p>
      <p class="c0 c2 "><span class="c1 "></span></p>
      <p class="c0 "><span class="c1 ">vertretungsberechtigte Gesellschafter: Leona Gieske</span></p>
      <p class="c0 c2 "><span class="c1 "></span></p>
      <p class="c0 "><span class="c7 ">Ihre Betroffenenrechte</span></p>
      <p class="c0 "><span class="c1 ">Unter den angegebenen Kontaktdaten unseres Datenschutzbeauftragten k&ouml;nnen
                  Sie jederzeit folgende Rechte aus&uuml;ben:</span></p>
      <p class="c0 c2 "><span class="c1 "></span></p>
      <p class="c0 "><span class="c1 ">Auskunft &uuml;ber Ihre bei uns gespeicherten Daten und deren Verarbeitung (Art.
                  15 DSGVO),</span></p>
      <p class="c0 "><span class="c1 ">Berichtigung unrichtiger personenbezogener Daten (Art. 16 DSGVO),</span></p>
      <p class="c0 "><span class="c1 ">L&ouml;schung Ihrer bei uns gespeicherten Daten (Art. 17 DSGVO),</span></p>
      <p class="c0 "><span class="c1 ">Einschr&auml;nkung der Datenverarbeitung, sofern wir Ihre Daten aufgrund
                  gesetzlicher Pflichten noch nicht l&ouml;schen d&uuml;rfen (Art. 18 DSGVO),</span></p>
      <p class="c0 "><span class="c1 ">Widerspruch gegen die Verarbeitung Ihrer Daten bei uns (Art. 21 DSGVO) und</span>
      </p>
      <p class="c0 "><span class="c1 ">Daten&uuml;bertragbarkeit, sofern Sie in die Datenverarbeitung eingewilligt haben
                  oder einen Vertrag mit uns abgeschlossen haben (Art. 20 DSGVO).</span></p>
      <p class="c0 "><span class="c1 ">Sofern Sie uns eine Einwilligung erteilt haben, k&ouml;nnen Sie diese jederzeit
                  mit Wirkung f&uuml;r die Zukunft widerrufen.</span></p>
      <p class="c0 c2 "><span class="c1 "></span></p>
      <p class="c0 "><span class="c1 ">Sie k&ouml;nnen sich jederzeit mit einer Beschwerde an eine Aufsichtsbeh&ouml;rde
                  wenden, z. B. an die zust&auml;ndige Aufsichtsbeh&ouml;rde des Bundeslands Ihres Wohnsitzes oder
                  an die f&uuml;r uns als verantwortliche Stelle zust&auml;ndige Beh&ouml;rde.</span></p>
      <p class="c0 c2 "><span class="c1 "></span></p>
      <p class="c0 "><span class="c1 ">Eine Liste der Aufsichtsbeh&ouml;rden (f&uuml;r den nicht&ouml;ffentlichen
                  Bereich) mit Anschrift finden Sie unter:
                  https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html.</span></p>
      <p class="c0 c2 "><span class="c1 "></span></p>
      <p class="c0 "><span class="c7 ">Erfassung allgemeiner Informationen beim Besuch unserer Website</span></p>
      <p class="c0 "><span class="c1 ">Art und Zweck der Verarbeitung:</span></p>
      <p class="c0 "><span class="c1 ">Wenn Sie auf unsere Website zugreifen, d.h., wenn Sie sich nicht registrieren
                  oder anderweitig Informationen &uuml;bermitteln, werden automatisch Informationen allgemeiner
                  Natur erfasst. Diese Informationen (Server-Logfiles) beinhalten etwa die Art des Webbrowsers,
                  das verwendete Betriebssystem, den Domainnamen Ihres Internet-Service-Providers, Ihre IP-Adresse
                  und &auml;hnliches.</span></p>
      <p class="c0 c2 "><span class="c1 "></span></p>
      <p class="c0 "><span class="c1 ">Sie werden insbesondere zu folgenden Zwecken verarbeitet:</span></p>
      <p class="c0 c2 "><span class="c1 "></span></p>
      <p class="c0 "><span class="c1 ">Sicherstellung eines problemlosen Verbindungsaufbaus der Website,</span></p>
      <p class="c0 "><span class="c1 ">Sicherstellung einer reibungslosen Nutzung unserer Website,</span></p>
      <p class="c0 "><span class="c1 ">Auswertung der Systemsicherheit und -stabilit&auml;t sowie</span></p>
      <p class="c0 "><span class="c1 ">zu weiteren administrativen Zwecken.</span></p>
      <p class="c0 "><span class="c1 ">Wir verwenden Ihre Daten nicht, um R&uuml;ckschl&uuml;sse auf Ihre Person zu
                  ziehen. Informationen dieser Art werden von uns ggfs. statistisch ausgewertet, um unseren
                  Internetauftritt und die dahinterstehende Technik zu optimieren.</span></p>
      <p class="c0 c2 "><span class="c1 "></span></p>
      <p class="c0 "><span class="c1 ">Rechtsgrundlage:</span></p>
      <p class="c0 "><span class="c1 ">Die Verarbeitung erfolgt gem&auml;&szlig; Art. 6 Abs. 1 lit. f DSGVO auf Basis
                  unseres berechtigten Interesses an der Verbesserung der Stabilit&auml;t und Funktionalit&auml;t
                  unserer Website.</span></p>
      <p class="c0 c2 "><span class="c1 "></span></p>
      <p class="c0 "><span class="c1 ">Empf&auml;nger:</span></p>
      <p class="c0 "><span class="c1 ">Empf&auml;nger der Daten sind ggf. technische Dienstleister, die f&uuml;r den
                  Betrieb und die Wartung unserer Webseite als Auftragsverarbeiter t&auml;tig werden.</span></p>
      <p class="c0 c2 "><span class="c1 "></span></p>
      <p class="c0 "><span class="c1 ">Speicherdauer:</span></p>
      <p class="c0 "><span class="c1 ">Die Daten werden gel&ouml;scht, sobald diese f&uuml;r den Zweck der Erhebung
                  nicht mehr erforderlich sind. Dies ist f&uuml;r die Daten, die der Bereitstellung der Webseite
                  dienen, grunds&auml;tzlich der Fall, wenn die jeweilige Sitzung beendet ist.</span></p>
      <p class="c0 c2 "><span class="c1 "></span></p>
      <p class="c0 "><span class="c1 ">Bereitstellung vorgeschrieben oder erforderlich:</span></p>
      <p class="c0 "><span class="c1 ">Die Bereitstellung der vorgenannten personenbezogenen Daten ist weder gesetzlich
                  noch vertraglich vorgeschrieben. Ohne die IP-Adresse ist jedoch der Dienst und die
                  Funktionsf&auml;higkeit unserer Website nicht gew&auml;hrleistet. Zudem k&ouml;nnen einzelne
                  Dienste und Services nicht verf&uuml;gbar oder eingeschr&auml;nkt sein. Aus diesem Grund ist ein
                  Widerspruch ausgeschlossen.</span></p>
      <p class="c0 c2 "><span class="c1 "></span></p>
      <h2 class="c10 " id="h.l7g2w9vg8aop "><span class="c3 ">Cookies</span></h2>
      <p class="c0 "><span class="c1 ">Art und Zweck der Verarbeitung:</span></p>
      <p class="c0 "><span class="c1 ">Wie viele andere Webseiten verwenden wir auch so genannte &bdquo;Cookies&ldquo;.
                  Bei Cookies handelt es sich um kleine Textdateien, die auf Ihrem Endger&auml;t (Laptop, Tablet,
                  Smartphone o.&auml;.) gespeichert werden, wenn Sie unsere Webseite besuchen.</span></p>
      <p class="c0 c2 "><span class="c1 "></span></p>
      <p class="c0 "><span class="c1 ">Hierdurch erhalten wir bestimmte Daten wie z. B. IP-Adresse, verwendeter Browser
                  und Betriebssystem.</span></p>
      <p class="c0 c2 "><span class="c1 "></span></p>
      <p class="c0 "><span class="c1 ">Cookies k&ouml;nnen nicht verwendet werden, um Programme zu starten oder Viren
                  auf einen Computer zu &uuml;bertragen. Anhand der in Cookies enthaltenen Informationen
                  k&ouml;nnen wir Ihnen die Navigation erleichtern und die korrekte Anzeige unserer Webseiten
                  erm&ouml;glichen.</span></p>
      <p class="c0 c2 "><span class="c1 "></span></p>
      <p class="c0 "><span class="c1 ">In keinem Fall werden die von uns erfassten Daten an Dritte weitergegeben oder
                  ohne Ihre Einwilligung eine Verkn&uuml;pfung mit personenbezogenen Daten hergestellt.</span></p>
      <p class="c0 c2 "><span class="c1 "></span></p>
      <p class="c0 "><span class="c1 ">Nat&uuml;rlich k&ouml;nnen Sie unsere Website grunds&auml;tzlich auch ohne
                  Cookies betrachten. Internet-Browser sind regelm&auml;&szlig;ig so eingestellt, dass sie Cookies
                  akzeptieren. Im Allgemeinen k&ouml;nnen Sie die Verwendung von Cookies jederzeit &uuml;ber die
                  Einstellungen Ihres Browsers deaktivieren. Bitte verwenden Sie die Hilfefunktionen Ihres
                  Internetbrowsers, um zu erfahren, wie Sie diese Einstellungen &auml;ndern k&ouml;nnen. Bitte
                  beachten Sie, dass einzelne Funktionen unserer Website m&ouml;glicherweise nicht funktionieren,
                  wenn Sie die Verwendung von Cookies deaktiviert haben.</span></p>
      <p class="c0 c2 "><span class="c1 "></span></p>
      <p class="c0 "><span class="c1 ">Speicherdauer und eingesetzte Cookies:</span></p>
      <p class="c0 "><span class="c1 ">Soweit Sie uns durch Ihre Browsereinstellungen oder Zustimmung die Verwendung von
                  Cookies erlauben, k&ouml;nnen folgende Cookies auf unseren Webseiten zum Einsatz kommen:</span>
      </p>
      <p class="c0 c2 "><span class="c1 "></span></p>
      <p class="c0 "><span class="c1 ">- Google Ads (ca. 180 Tage)</span></p>
      <p class="c0 "><span class="c1 ">- Facebook Pixel (ca. 180 Tage)</span></p>
      <p class="c0 "><span class="c1 ">- Google Analytics (ca. 180 Tage)</span></p>
      <p class="c0 c2 "><span class="c1 "></span></p>
      <p class="c0 "><span class="c1 ">Soweit diese Cookies (auch) personenbezogene Daten betreffen k&ouml;nnen,
                  informieren wir Sie dar&uuml;ber in den folgenden Abschnitten.</span></p>
      <p class="c0 c2 "><span class="c1 "></span></p>
      <p class="c0 "><span class="c1 ">Sie k&ouml;nnen &uuml;ber Ihre Browsereinstellungen einzelne Cookies oder den
                  gesamten Cookie-Bestand l&ouml;schen. Dar&uuml;ber hinaus erhalten Sie Informationen und
                  Anleitungen, wie diese Cookies gel&ouml;scht oder deren Speicherung vorab blockiert werden
                  k&ouml;nnen. Je nach Anbieter Ihres Browsers finden Sie die notwendigen Informationen unter den
                  nachfolgenden Links:</span></p>
      <p class="c0 c2 "><span class="c1 "></span></p>
      <p class="c0 "><span class="c6 ">Mozilla Firefox: </span><span
                  class="c1 ">https://support.mozilla.org/de/kb/cookies-loeschen-daten-von-websites-entfernen</span>
      </p>
      <p class="c0 "><span class="c6 ">Internet Explorer: </span><span
                  class="c1 ">https://support.microsoft.com/de-de/help/17442/windows-internet-explorer-delete-manage-cookies</span>
      </p>
      <p class="c0 "><span class="c6 ">Google Chrome:</span><span
                  class="c1 ">&nbsp;https://support.google.com/accounts/answer/61416?hl=de</span></p>
      <p class="c0 "><span class="c6 ">Opera:</span><span class="c1 ">&nbsp;http://www.opera.com/de/help</span></p>
      <p class="c0 "><span class="c6 ">Safari:</span><span
                  class="c1 ">&nbsp;https://support.apple.com/kb/PH17191?locale=de_DE&amp;viewlocale=de_DE</span>
      </p>
      <p class="c0 c2 "><span class="c1 "></span></p>
      <h2 class="c10 " id="h.ktgpvcqufoa "><span class="c3 ">Registrierung auf unserer Website</span></h2>
      <p class="c0 c2 "><span class="c1 "></span></p>
      <p class="c0 "><span class="c1 ">Art und Zweck der Verarbeitung:</span></p>
      <p class="c0 "><span class="c1 ">Bei der Registrierung f&uuml;r die Nutzung unserer personalisierten Leistungen
                  werden einige personenbezogene Daten erhoben, wie Name, Anschrift, Kontakt- und
                  Kommunikationsdaten (z. B. Telefonnummer und E-Mail-Adresse). Sind Sie bei uns registriert,
                  k&ouml;nnen Sie auf Inhalte und Leistungen zugreifen, die wir nur registrierten Nutzern
                  anbieten. Angemeldete Nutzer haben zudem die M&ouml;glichkeit, bei Bedarf die bei Registrierung
                  angegebenen Daten jederzeit zu &auml;ndern oder zu l&ouml;schen. Selbstverst&auml;ndlich
                  erteilen wir Ihnen dar&uuml;ber hinaus jederzeit Auskunft &uuml;ber die von uns &uuml;ber Sie
                  gespeicherten personenbezogenen Daten.</span></p>
      <p class="c0 c2 "><span class="c1 "></span></p>
      <p class="c0 "><span class="c1 ">Rechtsgrundlage:</span></p>
      <p class="c0 "><span class="c1 ">Die Verarbeitung der bei der Registrierung eingegebenen Daten erfolgt auf
                  Grundlage einer Einwilligung des Nutzers (Art. 6 Abs. 1 lit. a DSGVO).</span></p>
      <p class="c0 c2 "><span class="c1 "></span></p>
      <p class="c0 "><span class="c1 ">Dient die Registrierung der Erf&uuml;llung eines Vertrages, dessen Vertragspartei
                  die betroffene Person ist oder der Durchf&uuml;hrung vorvertraglicher Ma&szlig;nahmen, so ist
                  zus&auml;tzliche Rechtsgrundlage f&uuml;r die Verarbeitung der Daten Art. 6 Abs. 1 lit. b
                  DSGVO.</span></p>
      <p class="c0 c2 "><span class="c1 "></span></p>
      <p class="c0 "><span class="c1 ">Empf&auml;nger:</span></p>
      <p class="c0 "><span class="c1 ">Empf&auml;nger der Daten sind ggf. technische Dienstleister, die f&uuml;r den
                  Betrieb und die Wartung unserer Website als Auftragsverarbeiter t&auml;tig werden.</span></p>
      <p class="c0 c2 "><span class="c1 "></span></p>
      <p class="c0 "><span class="c1 ">Speicherdauer:</span></p>
      <p class="c0 "><span class="c1 ">Daten werden in diesem Zusammenhang nur verarbeitet, solange die entsprechende
                  Einwilligung vorliegt. Danach werden sie gel&ouml;scht, soweit keine gesetzlichen
                  Aufbewahrungspflichten entgegenstehen. Zur Kontaktaufnahme in diesem Zusammenhang nutzen Sie
                  bitte die am Ende dieser Datenschutzerkl&auml;rung angegebenen Kontaktdaten.</span></p>
      <p class="c0 c2 "><span class="c1 "></span></p>
      <p class="c0 "><span class="c1 ">Bereitstellung vorgeschrieben oder erforderlich:</span></p>
      <p class="c0 "><span class="c1 ">Die Bereitstellung Ihrer personenbezogenen Daten erfolgt freiwillig, allein auf
                  Basis Ihrer Einwilligung. Ohne die Bereitstellung Ihrer personenbezogenen Daten k&ouml;nnen wir
                  Ihnen keinen Zugang auf unsere angebotenen Inhalte und Leistungen gew&auml;hren.</span></p>
      <p class="c0 c2 "><span class="c1 "></span></p>
      <h2 class="c10 " id="h.j3ftiwu1fjgv "><span class="c3 ">Erbringung kostenpflichtiger Leistungen</span></h2>
      <p class="c0 "><span class="c1 ">Art und Zweck der Verarbeitung:</span></p>
      <p class="c0 "><span class="c1 ">Zur Erbringung kostenpflichtiger Leistungen werden von uns zus&auml;tzliche Daten
                  erfragt, wie z.B. Zahlungsangaben, um Ihre Bestellung ausf&uuml;hren zu k&ouml;nnen.</span></p>
      <p class="c0 c2 "><span class="c1 "></span></p>
      <p class="c0 "><span class="c1 ">Rechtsgrundlage:</span></p>
      <p class="c0 "><span class="c1 ">Die Verarbeitung der Daten, die f&uuml;r den Abschluss des Vertrages erforderlich
                  ist, basiert auf Art. 6 Abs. 1 lit. b DSGVO.</span></p>
      <p class="c0 c2 "><span class="c1 "></span></p>
      <p class="c0 "><span class="c1 ">Empf&auml;nger:</span></p>
      <p class="c0 "><span class="c1 ">Empf&auml;nger der Daten sind ggf. Auftragsverarbeiter.</span></p>
      <p class="c0 c2 "><span class="c1 "></span></p>
      <p class="c0 "><span class="c1 ">Speicherdauer:</span></p>
      <p class="c0 "><span class="c1 ">Wir speichern diese Daten in unseren Systemen bis die gesetzlichen
                  Aufbewahrungsfristen abgelaufen sind. Diese betragen grunds&auml;tzlich 6 oder 10 Jahre aus
                  Gr&uuml;nden der ordnungsm&auml;&szlig;igen Buchf&uuml;hrung und steuerrechtlichen
                  Anforderungen.</span></p>
      <p class="c0 c2 "><span class="c1 "></span></p>
      <p class="c0 "><span class="c1 ">Bereitstellung vorgeschrieben oder erforderlich:</span></p>
      <p class="c0 "><span class="c1 ">Die Bereitstellung Ihrer personenbezogenen Daten erfolgt freiwillig. Ohne die
                  Bereitstellung Ihrer personenbezogenen Daten k&ouml;nnen wir Ihnen keinen Zugang auf unsere
                  angebotenen Inhalte und Leistungen gew&auml;hren.</span></p>
      <p class="c0 c2 "><span class="c1 "></span></p>
      <p class="c0 "><span class="c1 ">Kontaktformular</span></p>
      <p class="c0 "><span class="c1 ">Art und Zweck der Verarbeitung:</span></p>
      <p class="c0 "><span class="c1 ">Die von Ihnen eingegebenen Daten werden zum Zweck der individuellen Kommunikation
                  mit Ihnen gespeichert. Hierf&uuml;r ist die Angabe einer validen E-Mail-Adresse sowie Ihres
                  Namens erforderlich. Diese dient der Zuordnung der Anfrage und der anschlie&szlig;enden
                  Beantwortung derselben. Die Angabe weiterer Daten ist optional.</span></p>
      <p class="c0 c2 "><span class="c1 "></span></p>
      <p class="c0 "><span class="c1 ">Rechtsgrundlage:</span></p>
      <p class="c0 "><span class="c1 ">Die Verarbeitung der in das Kontaktformular eingegebenen Daten erfolgt auf der
                  Grundlage eines berechtigten Interesses (Art. 6 Abs. 1 lit. f DSGVO).</span></p>
      <p class="c0 c2 "><span class="c1 "></span></p>
      <p class="c0 "><span class="c1 ">Durch Bereitstellung des Kontaktformulars m&ouml;chten wir Ihnen eine
                  unkomplizierte Kontaktaufnahme erm&ouml;glichen. Ihre gemachten Angaben werden zum Zwecke der
                  Bearbeitung der Anfrage sowie f&uuml;r m&ouml;gliche Anschlussfragen gespeichert.</span></p>
      <p class="c0 c2 "><span class="c1 "></span></p>
      <p class="c0 "><span class="c1 ">Sofern Sie mit uns Kontakt aufnehmen, um ein Angebot zu erfragen, erfolgt die
                  Verarbeitung der in das Kontaktformular eingegebenen Daten zur Durchf&uuml;hrung
                  vorvertraglicher Ma&szlig;nahmen (Art. 6 Abs. 1 lit. b DSGVO).</span></p>
      <p class="c0 c2 "><span class="c1 "></span></p>
      <p class="c0 "><span class="c1 ">Empf&auml;nger:</span></p>
      <p class="c0 "><span class="c1 ">Empf&auml;nger der Daten sind ggf. Auftragsverarbeiter.</span></p>
      <p class="c0 c2 "><span class="c1 "></span></p>
      <p class="c0 "><span class="c1 ">Speicherdauer:</span></p>
      <p class="c0 "><span class="c1 ">Daten werden sp&auml;testens 6 Monate nach Bearbeitung der Anfrage
                  gel&ouml;scht.</span></p>
      <p class="c0 c2 "><span class="c1 "></span></p>
      <p class="c0 "><span class="c1 ">Sofern es zu einem Vertragsverh&auml;ltnis kommt, unterliegen wir den
                  gesetzlichen Aufbewahrungsfristen nach HGB und l&ouml;schen Ihre Daten nach Ablauf dieser
                  Fristen.</span></p>
      <p class="c0 c2 "><span class="c1 "></span></p>
      <p class="c0 "><span class="c1 ">Bereitstellung vorgeschrieben oder erforderlich:</span></p>
      <p class="c0 "><span class="c1 ">Die Bereitstellung Ihrer personenbezogenen Daten erfolgt freiwillig. Wir
                  k&ouml;nnen Ihre Anfrage jedoch nur bearbeiten, sofern Sie uns Ihren Namen, Ihre E-Mail-Adresse
                  und den Grund der Anfrage mitteilen.</span></p>
      <p class="c0 c2 "><span class="c1 "></span></p>
      <p class="c0 "><span class="c7 ">Verwendung von Google Analytics</span></p>
      <p class="c0 "><span class="c1 ">Art und Zweck der Verarbeitung:</span></p>
      <p class="c0 "><span class="c1 ">Diese Website benutzt Google Analytics, einen Webanalysedienst der Google LLC,
                  1600 Amphitheatre Parkway, Mountain View, CA 94043 USA (nachfolgend: &bdquo;Google&ldquo;).
                  Google Analytics verwendet sog. &bdquo;Cookies&ldquo;, also Textdateien, die auf Ihrem Computer
                  gespeichert werden und die eine Analyse der Benutzung der Webseite durch Sie erm&ouml;glichen.
                  Die durch das Cookie erzeugten Informationen &uuml;ber Ihre Benutzung dieser Webseite werden in
                  der Regel an einen Server von Google in den USA &uuml;bertragen und dort gespeichert. Aufgrund
                  der Aktivierung der IP-Anonymisierung auf diesen Webseiten, wird Ihre IP-Adresse von Google
                  jedoch innerhalb von Mitgliedstaaten der Europ&auml;ischen Union oder in anderen Vertragsstaaten
                  des Abkommens &uuml;ber den Europ&auml;ischen Wirtschaftsraum zuvor gek&uuml;rzt. Nur in
                  Ausnahmef&auml;llen wird die volle IP-Adresse an einen Server von Google in den USA
                  &uuml;bertragen und dort gek&uuml;rzt. Im Auftrag des Betreibers dieser Website wird Google
                  diese Informationen benutzen, um Ihre Nutzung der Webseite auszuwerten, um Reports &uuml;ber die
                  Webseitenaktivit&auml;ten zusammenzustellen und um weitere mit der Websitenutzung und der
                  Internetnutzung verbundene Dienstleistungen gegen&uuml;ber dem Webseitenbetreiber zu erbringen.
                  Die im Rahmen von Google Analytics von Ihrem Browser &uuml;bermittelte IP-Adresse wird nicht mit
                  anderen Daten von Google zusammengef&uuml;hrt.</span></p>
      <p class="c0 c2 "><span class="c1 "></span></p>
      <p class="c0 "><span class="c1 ">Die Zwecke der Datenverarbeitung liegen in der Auswertung der Nutzung der Website
                  und in der Zusammenstellung von Reports &uuml;ber Aktivit&auml;ten auf der Website. Auf
                  Grundlage der Nutzung der Website und des Internets sollen dann weitere verbundene
                  Dienstleistungen erbracht werden.</span></p>
      <p class="c0 c2 "><span class="c1 "></span></p>
      <p class="c0 "><span class="c1 ">Rechtsgrundlage:</span></p>
      <p class="c0 "><span class="c1 ">Die Verarbeitung der Daten erfolgt auf Grundlage einer Einwilligung des Nutzers
                  (Art. 6 Abs. 1 lit. a DSGVO).</span></p>
      <p class="c0 c2 "><span class="c1 "></span></p>
      <p class="c0 "><span class="c1 ">Empf&auml;nger:</span></p>
      <p class="c0 "><span class="c1 ">Empf&auml;nger der Daten ist Google als Auftragsverarbeiter. Hierf&uuml;r haben
                  wir mit Google den entsprechenden Auftragsverarbeitungsvertrag abgeschlossen.</span></p>
      <p class="c0 c2 "><span class="c1 "></span></p>
      <p class="c0 "><span class="c1 ">Speicherdauer:</span></p>
      <p class="c0 "><span class="c1 ">Die L&ouml;schung der Daten erfolgt, sobald diese f&uuml;r unsere
                  Aufzeichnungszwecke nicht mehr erforderlich sind.</span></p>
      <p class="c0 c2 "><span class="c1 "></span></p>
      <p class="c0 "><span class="c1 ">Drittlandtransfer:</span></p>
      <p class="c0 "><span class="c1 ">Google verarbeitet Ihre Daten in den USA und hat sich dem EU_US Privacy Shield
                  unterworfen https://www.privacyshield.gov/EU-US-Framework.</span></p>
      <p class="c0 c2 "><span class="c1 "></span></p>
      <p class="c0 "><span class="c1 ">Bereitstellung vorgeschrieben oder erforderlich:</span></p>
      <p class="c0 "><span class="c1 ">Die Bereitstellung Ihrer personenbezogenen Daten erfolgt freiwillig, allein auf
                  Basis Ihrer Einwilligung. Sofern Sie den Zugriff unterbinden, kann es hierdurch zu
                  Funktionseinschr&auml;nkungen auf der Website kommen.</span></p>
      <p class="c0 c2 "><span class="c1 "></span></p>
      <p class="c0 "><span class="c1 ">Widerruf der Einwilligung:</span></p>
      <p class="c0 "><span class="c1 ">Sie k&ouml;nnen die Speicherung der Cookies durch eine entsprechende Einstellung
                  Ihrer Browser-Software verhindern; wir weisen Sie jedoch darauf hin, dass Sie in diesem Fall
                  gegebenenfalls nicht s&auml;mtliche Funktionen dieser Website vollumf&auml;nglich werden nutzen
                  k&ouml;nnen. Sie k&ouml;nnen dar&uuml;ber hinaus die Erfassung der durch das Cookie erzeugten
                  und auf Ihre Nutzung der Webseite bezogenen Daten (inkl. Ihrer IP-Adresse) an Google sowie die
                  Verarbeitung dieser Daten durch Google verhindern, indem sie das unter dem folgenden Link
                  verf&uuml;gbare Browser-Plugin herunterladen und installieren: Browser Add On zur Deaktivierung
                  von Google Analytics.</span></p>
      <p class="c0 c2 "><span class="c1 "></span></p>
      <p class="c0 "><span class="c1 ">Zus&auml;tzlich oder als Alternative zum Browser-Add-On k&ouml;nnen Sie das
                  Tracking durch Google Analytics auf unseren Seiten unterbinden, indem Sie diesen Link anklicken.
                  Dabei wird ein Opt-out-Cookie auf Ihrem Ger&auml;t installiert. Damit wird die Erfassung durch
                  Google Analytics f&uuml;r diese Website und f&uuml;r diesen Browser zuk&uuml;nftig verhindert,
                  so lange das Cookie in Ihrem Browser installiert bleibt.</span></p>
      <p class="c0 c2 "><span class="c1 "></span></p>
      <p class="c0 "><span class="c1 ">Profiling:</span></p>
      <p class="c0 "><span class="c1 ">Mit Hilfe des Tracking-Tools Google Analytics kann das Verhalten der Besucher der
                  Webseite bewertet und die Interessen analysiert werden. Hierzu erstellen wir ein pseudonymes
                  Nutzerprofil.</span></p>
      <p class="c0 c2 "><span class="c1 "></span></p>
      <p class="c0 "><span class="c7 ">Verwendung von Scriptbibliotheken (Google Webfonts)</span></p>
      <p class="c0 "><span class="c1 ">Art und Zweck der Verarbeitung:</span></p>
      <p class="c0 "><span class="c1 ">Um unsere Inhalte browser&uuml;bergreifend korrekt und grafisch ansprechend
                  darzustellen, verwenden wir auf dieser Website &bdquo;Google Web Fonts&ldquo; der Google LLC
                  (1600 Amphitheatre Parkway, Mountain View, CA 94043, USA; nachfolgend &bdquo;Google&ldquo;) zur
                  Darstellung von Schriften.</span></p>
      <p class="c0 c2 "><span class="c1 "></span></p>
      <p class="c0 "><span class="c1 ">Die Datenschutzrichtlinie des Bibliothekbetreibers Google finden Sie hier:
                  https://www.google.com/policies/privacy/</span></p>
      <p class="c0 c2 "><span class="c1 "></span></p>
      <p class="c0 "><span class="c1 ">Rechtsgrundlage:</span></p>
      <p class="c0 "><span class="c1 ">Rechtsgrundlage f&uuml;r die Einbindung von Google Webfonts und dem damit
                  verbundenen Datentransfer zu Google ist Ihre Einwilligung (Art. 6 Abs. 1 lit. a DSGVO).</span>
      </p>
      <p class="c0 c2 "><span class="c1 "></span></p>
      <p class="c0 "><span class="c1 ">Empf&auml;nger:</span></p>
      <p class="c0 "><span class="c1 ">Der Aufruf von Scriptbibliotheken oder Schriftbibliotheken l&ouml;st automatisch
                  eine Verbindung zum Betreiber der Bibliothek aus. Dabei ist es theoretisch m&ouml;glich &ndash;
                  aktuell allerdings auch unklar ob und ggf. zu welchen Zwecken &ndash; dass der Betreiber in
                  diesem Fall Google Daten erhebt.</span></p>
      <p class="c0 c2 "><span class="c1 "></span></p>
      <p class="c0 "><span class="c1 ">Speicherdauer:</span></p>
      <p class="c0 "><span class="c1 ">Wir erheben keine personenbezogenen Daten, durch die Einbindung von Google
                  Webfonts.</span></p>
      <p class="c0 c2 "><span class="c1 "></span></p>
      <p class="c0 "><span class="c1 ">Weitere Informationen zu Google Web Fonts finden Sie unter
                  https://developers.google.com/fonts/faq und in der Datenschutzerkl&auml;rung von Google:
                  https://www.google.com/policies/privacy/.</span></p>
      <p class="c0 c2 "><span class="c1 "></span></p>
      <p class="c0 "><span class="c1 ">Drittlandtransfer:</span></p>
      <p class="c0 "><span class="c1 ">Google verarbeitet Ihre Daten in den USA und hat sich dem EU_US Privacy Shield
                  unterworfen https://www.privacyshield.gov/EU-US-Framework.</span></p>
      <p class="c0 c2 "><span class="c1 "></span></p>
      <p class="c0 "><span class="c1 ">Bereitstellung vorgeschrieben oder erforderlich:</span></p>
      <p class="c0 "><span class="c1 ">Die Bereitstellung der personenbezogenen Daten ist weder gesetzlich, noch
                  vertraglich vorgeschrieben. Allerdings kann ggfs. die korrekte Darstellung der Inhalte durch
                  Standardschriften nicht m&ouml;glich sein.</span></p>
      <p class="c0 c2 "><span class="c1 "></span></p>
      <p class="c0 "><span class="c1 ">Widerruf der Einwilligung:</span></p>
      <p class="c0 "><span class="c1 ">Zur Darstellung der Inhalte wird regelm&auml;&szlig;ig die Programmiersprache
                  JavaScript verwendet. Sie k&ouml;nnen der Datenverarbeitung daher widersprechen, indem Sie die
                  Ausf&uuml;hrung von JavaScript in Ihrem Browser deaktivieren oder einen JavaScript-Blocker
                  installieren. Bitte beachten Sie, dass es hierdurch zu Funktionseinschr&auml;nkungen auf der
                  Website kommen kann.</span></p>
      <p class="c0 c2 "><span class="c1 "></span></p>
      <p class="c0 "><span class="c7 ">Verwendung von Adobe Typekit</span></p>
      <p class="c0 "><span class="c1 ">Art und Zweck der Verarbeitung:</span></p>
      <p class="c0 "><span class="c1 ">Wir setzen Adobe Typekit zur visuellen Gestaltung unserer Website ein. Typekit
                  ist ein Dienst der Adobe Systems Software Ireland Companies (4-6 Riverwalk, Citywest Business
                  Campus, Dublin 24, Republic of Ireland; nachfolgend &bdquo;Adobe&ldquo;), der uns den Zugriff
                  auf eine Schriftartenbibliothek gew&auml;hrt. Zur Einbindung der von uns benutzten Schriftarten,
                  muss Ihr Browser eine Verbindung zu einem Server von Adobe in den USA aufbauen und die f&uuml;r
                  unsere Website ben&ouml;tigte Schriftart herunterladen. Adobe erh&auml;lt hierdurch die
                  Information, dass von Ihrer IP-Adresse unsere Website aufgerufen wurde. Weitere Informationen zu
                  Adobe Typekit finden Sie in den Datenschutzhinweisen von Adobe, die Sie hier abrufen
                  k&ouml;nnen: https://www.adobe.com/de/privacy/policy.html</span></p>
      <p class="c0 c2 "><span class="c1 "></span></p>
      <p class="c0 "><span class="c1 ">Rechtsgrundlage:</span></p>
      <p class="c0 "><span class="c1 ">Rechtsgrundlage f&uuml;r die Einbindung von Adobe Typekit und dem damit
                  verbundenen Datentransfer zu Adobe ist Ihre Einwilligung (Art. 6 Abs. 1 lit. a DSGVO).</span>
      </p>
      <p class="c0 c2 "><span class="c1 "></span></p>
      <p class="c0 "><span class="c1 ">Empf&auml;nger:</span></p>
      <p class="c0 "><span class="c1 ">Der Aufruf von Scriptbibliotheken oder Schriftbibliotheken l&ouml;st automatisch
                  eine Verbindung zum Betreiber der Bibliothek aus. Informationen &uuml;ber die Nutzung Ihrer
                  Daten durch Adobe Typekit Web Fonts Sie unter https://typekit.com/ und in der
                  Datenschutzerkl&auml;rung von Adobe Typekit:
                  https://www.adobe.com/de/privacy/policies/typekit.html.</span></p>
      <p class="c0 c2 "><span class="c1 "></span></p>
      <p class="c0 "><span class="c1 ">Speicherdauer:</span></p>
      <p class="c0 "><span class="c1 ">Wir erheben keine personenbezogenen Daten durch die Einbindung von Adobe Typekit
                  Web Fonts.</span></p>
      <p class="c0 c2 "><span class="c1 "></span></p>
      <p class="c0 "><span class="c1 ">Drittlandtransfer:</span></p>
      <p class="c0 "><span class="c1 ">Adobe ist unter dem Privacy-Shield-Abkommen zertifiziert und bietet hierdurch
                  eine Garantie, das europ&auml;ische Datenschutzrecht einzuhalten
                  (https://www.privacyshield.gov/participant?id=a2zt0000000TNo9AAG&amp;status=Active).</span></p>
      <p class="c0 c2 "><span class="c1 "></span></p>
      <p class="c0 "><span class="c1 ">Bereitstellung vorgeschrieben oder erforderlich:</span></p>
      <p class="c0 "><span class="c1 ">Die Bereitstellung der personenbezogenen Daten ist weder gesetzlich, noch
                  vertraglich vorgeschrieben. Allerdings kann ohne die korrekte Darstellung der Inhalte von
                  Standardschriften nicht erm&ouml;glicht werden.</span></p>
      <p class="c0 c2 "><span class="c1 "></span></p>
      <p class="c0 "><span class="c7 ">Verwendung von Google Maps</span></p>
      <p class="c0 "><span class="c1 ">Art und Zweck der Verarbeitung:</span></p>
      <p class="c0 "><span class="c1 ">Auf dieser Webseite nutzen wir das Angebot von Google Maps. Google Maps wird von
                  Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA (nachfolgend
                  &bdquo;Google&ldquo;) betrieben. Dadurch k&ouml;nnen wir Ihnen interaktive Karten direkt in der
                  Webseite anzeigen und erm&ouml;glichen Ihnen die komfortable Nutzung der Karten-Funktion.</span>
      </p>
      <p class="c0 c2 "><span class="c1 "></span></p>
      <p class="c0 "><span class="c1 ">N&auml;here Informationen &uuml;ber die Datenverarbeitung durch Google
                  k&ouml;nnen Sie den Google-Datenschutzhinweisen entnehmen. Dort k&ouml;nnen Sie im
                  Datenschutzcenter auch Ihre pers&ouml;nlichen Datenschutz-Einstellungen ver&auml;ndern.</span>
      </p>
      <p class="c0 c2 "><span class="c1 "></span></p>
      <p class="c0 "><span class="c1 ">Ausf&uuml;hrliche Anleitungen zur Verwaltung der eigenen Daten im Zusammenhang
                  mit Google-Produkten finden Sie hier.</span></p>
      <p class="c0 c2 "><span class="c1 "></span></p>
      <p class="c0 "><span class="c1 ">Rechtsgrundlage:</span></p>
      <p class="c0 "><span class="c1 ">Rechtsgrundlage f&uuml;r die Einbindung von Google Maps und dem damit verbundenen
                  Datentransfer zu Google ist Ihre Einwilligung (Art. 6 Abs. 1 lit. a DSGVO).</span></p>
      <p class="c0 c2 "><span class="c1 "></span></p>
      <p class="c0 "><span class="c1 ">Empf&auml;nger:</span></p>
      <p class="c0 "><span class="c1 ">Durch den Besuch der Webseite erh&auml;lt Google Informationen, dass Sie die
                  entsprechende Unterseite unserer Webseite aufgerufen haben. Dies erfolgt unabh&auml;ngig davon,
                  ob Google ein Nutzerkonto bereitstellt, &uuml;ber das Sie eingeloggt sind, oder ob keine
                  Nutzerkonto besteht. Wenn Sie bei Google eingeloggt sind, werden Ihre Daten direkt Ihrem Konto
                  zugeordnet.</span></p>
      <p class="c0 c2 "><span class="c1 "></span></p>
      <p class="c0 "><span class="c1 ">Wenn Sie die Zuordnung in Ihrem Profil bei Google nicht w&uuml;nschen,
                  m&uuml;ssen Sie sich vor Aktivierung des Buttons bei Google ausloggen. Google speichert Ihre
                  Daten als Nutzungsprofile und nutzt sie f&uuml;r Zwecke der Werbung, Marktforschung und/oder
                  bedarfsgerechter Gestaltung seiner Webseite. Eine solche Auswertung erfolgt insbesondere (selbst
                  f&uuml;r nicht eingeloggte Nutzer) zur Erbringung bedarfsgerechter Werbung und um andere Nutzer
                  des sozialen Netzwerks &uuml;ber Ihre Aktivit&auml;ten auf unserer Webseite zu informieren.
                  Ihnen steht ein Widerspruchsrecht zu gegen die Bildung dieser Nutzerprofile, wobei Sie sich zur
                  Aus&uuml;bung dessen an Google richten m&uuml;ssen.</span></p>
      <p class="c0 c2 "><span class="c1 "></span></p>
      <p class="c0 "><span class="c1 ">Speicherdauer:</span></p>
      <p class="c0 "><span class="c1 ">Wir erheben keine personenbezogenen Daten, durch die Einbindung von Google
                  Maps.</span></p>
      <p class="c0 c2 "><span class="c1 "></span></p>
      <p class="c0 "><span class="c1 ">Drittlandtransfer:</span></p>
      <p class="c0 "><span class="c1 ">Google verarbeitet Ihre Daten in den USA und hat sich dem EU_US Privacy Shield
                  unterworfen https://www.privacyshield.gov/EU-US-Framework.</span></p>
      <p class="c0 c2 "><span class="c1 "></span></p>
      <p class="c0 "><span class="c1 ">Widerruf der Einwilligung:</span></p>
      <p class="c0 "><span class="c1 ">Wenn Sie nicht m&ouml;chten, dass Google &uuml;ber unseren Internetauftritt Daten
                  &uuml;ber Sie erhebt, verarbeitet oder nutzt, k&ouml;nnen Sie in Ihrem Browsereinstellungen
                  JavaScript deaktivieren. In diesem Fall k&ouml;nnen Sie unsere Webseite jedoch nicht oder nur
                  eingeschr&auml;nkt nutzen.</span></p>
      <p class="c0 c2 "><span class="c1 "></span></p>
      <p class="c0 "><span class="c1 ">Bereitstellung vorgeschrieben oder erforderlich:</span></p>
      <p class="c0 "><span class="c1 ">Die Bereitstellung Ihrer personenbezogenen Daten erfolgt freiwillig, allein auf
                  Basis Ihrer Einwilligung. Sofern Sie den Zugriff unterbinden, kann es hierdurch zu
                  Funktionseinschr&auml;nkungen auf der Website kommen.</span></p>
      <p class="c0 c2 "><span class="c1 "></span></p>
      <p class="c0 "><span class="c7 ">Google AdWords</span></p>
      <p class="c0 "><span class="c1 ">Art und Zweck der Verarbeitung:</span></p>
      <p class="c0 "><span class="c1 ">Unsere Webseite nutzt das Google Conversion-Tracking. Betreibergesellschaft der
                  Dienste von Google AdWords ist die Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA
                  94043, USA. Sind Sie &uuml;ber eine von Google geschaltete Anzeige auf unsere Webseite gelangt,
                  wird von Google Adwords ein Cookie auf Ihrem Rechner gesetzt. Das Cookie f&uuml;r
                  Conversion-Tracking wird gesetzt, wenn ein Nutzer auf eine von Google geschaltete Anzeige
                  klickt.</span></p>
      <p class="c0 c2 "><span class="c1 "></span></p>
      <p class="c0 "><span class="c1 ">Besucht der Nutzer bestimmte Seiten unserer Website und das Cookie ist noch nicht
                  abgelaufen, k&ouml;nnen wir und Google erkennen, dass der Nutzer auf die Anzeige geklickt hat
                  und zu dieser Seite weitergeleitet wurde. Jeder Google AdWords-Kunde erh&auml;lt ein anderes
                  Cookie. Cookies k&ouml;nnen somit nicht &uuml;ber die Websites von AdWords-Kunden nachverfolgt
                  werden. Die mithilfe des Conversion-Cookies eingeholten Informationen dienen dazu,
                  Conversion-Statistiken f&uuml;r AdWords-Kunden zu erstellen, die sich f&uuml;r
                  Conversion-Tracking entschieden haben. Die Kunden erfahren die Gesamtanzahl der Nutzer, die auf
                  ihre Anzeige geklickt haben und zu einer mit einem Conversion-Tracking-Tag versehenen Seite
                  weitergeleitet wurden. Sie erhalten jedoch keine Informationen, mit denen sich Nutzer
                  pers&ouml;nlich identifizieren lassen.</span></p>
      <p class="c0 c2 "><span class="c1 "></span></p>
      <p class="c0 "><span class="c1 ">Rechtsgrundlage:</span></p>
      <p class="c0 "><span class="c1 ">Rechtsgrundlage f&uuml;r die Einbindung von Google AdWords und dem damit
                  verbundenen Datentransfer zu Google ist Ihre Einwilligung (Art. 6 Abs. 1 lit. a DSGVO).</span>
      </p>
      <p class="c0 c2 "><span class="c1 "></span></p>
      <p class="c0 "><span class="c1 ">Empf&auml;nger:</span></p>
      <p class="c0 "><span class="c1 ">Bei jedem Besuch unsere Webseite werden personenbezogene Daten,
                  einschlie&szlig;lich Ihrer IP-Adresse an Google in die USA &uuml;bertragen. Diese
                  personenbezogenen Daten werden durch Google gespeichert. Google gibt diese &uuml;ber das
                  technische Verfahren erhobenen personenbezogenen Daten unter Umst&auml;nden an Dritte
                  weiter.</span></p>
      <p class="c0 c2 "><span class="c1 "></span></p>
      <p class="c0 "><span class="c1 ">Unser Unternehmen enth&auml;lt keine Informationen von Google, mittels derer die
                  betroffene Person identifiziert werden k&ouml;nnte.</span></p>
      <p class="c0 c2 "><span class="c1 "></span></p>
      <p class="c0 "><span class="c1 ">Speicherdauer:</span></p>
      <p class="c0 "><span class="c1 ">Diese Cookies verlieren nach 30 Tagen ihre G&uuml;ltigkeit und dienen nicht der
                  pers&ouml;nlichen Identifizierung.</span></p>
      <p class="c0 c2 "><span class="c1 "></span></p>
      <p class="c0 "><span class="c1 ">Drittlandtransfer:</span></p>
      <p class="c0 "><span class="c1 ">Google verarbeitet Ihre Daten in den USA und hat sich dem EU_US Privacy Shield
                  unterworfen https://www.privacyshield.gov/EU-US-Framework.</span></p>
      <p class="c0 c2 "><span class="c1 "></span></p>
      <p class="c0 "><span class="c1 ">Widerruf der Einwilligung:</span></p>
      <p class="c0 "><span class="c1 ">M&ouml;chten Sie nicht am Tracking teilnehmen, k&ouml;nnen Sie das hierf&uuml;r
                  erforderliche Setzen eines Cookies ablehnen &ndash; etwa per Browser-Einstellung, die das
                  automatische Setzen von Cookies generell deaktiviert oder Ihren Browser so einstellen, dass
                  Cookies von der Domain &bdquo;googleleadservices.com&ldquo; blockiert werden.</span></p>
      <p class="c0 c2 "><span class="c1 "></span></p>
      <p class="c0 "><span class="c1 ">Bitte beachten Sie, dass Sie die Opt-out-Cookies nicht l&ouml;schen d&uuml;rfen,
                  solange Sie keine Aufzeichnung von Messdaten w&uuml;nschen. Haben Sie alle Ihre Cookies im
                  Browser gel&ouml;scht, m&uuml;ssen Sie das jeweilige Opt-out Cookie erneut setzen.</span></p>
      <p class="c0 c2 "><span class="c1 "></span></p>
      <p class="c0 "><span class="c1 ">Bereitstellung vorgeschrieben oder erforderlich:</span></p>
      <p class="c0 "><span class="c1 ">Die Bereitstellung Ihrer personenbezogenen Daten erfolgt freiwillig, allein auf
                  Basis Ihrer Einwilligung. Sofern Sie den Zugriff unterbinden, kann es hierdurch zu
                  Funktionseinschr&auml;nkungen auf der Website kommen.</span></p>
      <p class="c0 c2 "><span class="c1 "></span></p>
      <p class="c0 "><span class="c7 ">Einsatz von Google Remarketing</span></p>
      <p class="c0 "><span class="c1 ">Art und Zweck der Verarbeitung:</span></p>
      <p class="c0 "><span class="c1 ">Diese Webseite verwendet die Remarketing-Funktion der Google Inc.
                  Betreibergesellschaft der Dienste von Google Remarketing ist die Google LLC, 1600 Amphitheatre
                  Parkway, Mountain View, CA 94043, USA (nachfolgend &bdquo;Google&ldquo;).</span></p>
      <p class="c0 c2 "><span class="c1 "></span></p>
      <p class="c0 "><span class="c1 ">Die Funktion dient dazu, Webseitenbesuchern innerhalb des Google-Werbenetzwerks
                  interessenbezogene Werbeanzeigen zu pr&auml;sentieren. Im Browser des Webseitenbesuchers wird
                  ein sog. &bdquo;Cookie&ldquo; gespeichert, der es erm&ouml;glicht, den Besucher
                  wiederzuerkennen, wenn dieser Webseiten aufruft, die dem Werbenetzwerk von Google
                  angeh&ouml;ren. Auf diesen Seiten k&ouml;nnen dem Besucher Werbeanzeigen pr&auml;sentiert
                  werden, die sich auf Inhalte beziehen, die der Besucher zuvor auf Webseiten aufgerufen hat, die
                  die Remarketing Funktion von Google verwenden.</span></p>
      <p class="c0 c2 "><span class="c1 "></span></p>
      <p class="c0 "><span class="c1 ">Rechtsgrundlage:</span></p>
      <p class="c0 "><span class="c1 ">Rechtsgrundlage f&uuml;r die Einbindung von Google Remarketing und dem damit
                  verbundenen Datentransfer zu Google ist Ihre Einwilligung (Art. 6 Abs. 1 lit. a DSGVO).</span>
      </p>
      <p class="c0 c2 "><span class="c1 "></span></p>
      <p class="c0 "><span class="c1 ">Empf&auml;nger:</span></p>
      <p class="c0 "><span class="c1 ">Bei jedem Besuch unsere Webseite werden personenbezogene Daten,
                  einschlie&szlig;lich Ihrer IP-Adresse an Google in die USA &uuml;bertragen. Diese
                  personenbezogenen Daten werden durch Google gespeichert. Google gibt diese &uuml;ber das
                  technische Verfahren erhobenen personenbezogenen Daten unter Umst&auml;nden an Dritte
                  weiter.</span></p>
      <p class="c0 c2 "><span class="c1 "></span></p>
      <p class="c0 "><span class="c1 ">Unser Unternehmen enth&auml;lt keine Informationen von Google, mittels derer die
                  betroffene Person identifiziert werden k&ouml;nnte.</span></p>
      <p class="c0 c2 "><span class="c1 "></span></p>
      <p class="c0 "><span class="c1 ">Widerruf der Einwilligung:</span></p>
      <p class="c0 "><span class="c1 ">Nach eigenen Angaben erhebt Google bei diesem Vorgang keine personenbezogenen
                  Daten. Sollten Sie die Funktion Remarketing von Google dennoch nicht w&uuml;nschen, k&ouml;nnen
                  Sie diese grunds&auml;tzlich deaktivieren, indem Sie die entsprechenden Einstellungen unter
                  https://support.google.com/adwordspolicy/answer/143465 vornehmen. Alternativ k&ouml;nnen Sie den
                  Einsatz von Cookies f&uuml;r interessenbezogene Werbung &uuml;ber die Werbenetzwerkinitiative
                  deaktivieren, indem Sie den Anweisungen unter
                  http://www.networkadvertising.org/managing/opt_out.asp folgen.</span></p>
      <p class="c0 c2 "><span class="c1 "></span></p>
      <p class="c0 "><span class="c1 ">Bereitstellung vorgeschrieben oder erforderlich:</span></p>
      <p class="c0 "><span class="c1 ">Die Bereitstellung Ihrer personenbezogenen Daten erfolgt freiwillig, allein auf
                  Basis Ihrer Einwilligung. Sofern Sie den Zugriff unterbinden, kann es hierdurch zu
                  Funktionseinschr&auml;nkungen auf der Website kommen.</span></p>
      <p class="c0 c2 "><span class="c1 "></span></p>
      <p class="c0 c2 "><span class="c1 "></span></p>
      <p class="c0 "><span class="c7 ">Einsatz von Wufoo</span></p>
      <p class="c0 c2 "><span class="c1 "></span></p>
      <p class="c0 "><span class="c1 ">Art und Zweck der Verarbeitung:</span></p>
      <p class="c0 "><span class="c1 ">Das Tool Wufoo von &bdquo;Infinity Box&ldquo;, erlaubt uns unsere
                  Bewerbungsformulare zu bauen und anzubieten. Beim Anbieter handelt es sich um die Infinity Box
                  Inc., 3050 South Delaware Street, San Mateo, CA, 94403, USA. Infinity Box Inc. ist eine
                  Tochtergesellschaft von SurveyMonkey Inc., 1 Curiosity Way, San Mateo, California 94403,
                  USA.</span></p>
      <p class="c0 c2 "><span class="c1 "></span></p>
      <p class="c0 "><span class="c1 ">Rechtsgrundlage:</span></p>
      <p class="c0 "><span class="c1 ">Rechtsgrundlage f&uuml;r die Einbindung von Wufoo und dem damit verbundenen
                  Datentransfer zur Infinity Box ist Ihre Einwilligung (Art. 6 Abs. 1 lit. a DSGVO).</span></p>
      <p class="c0 c2 "><span class="c1 "></span></p>
      <p class="c0 "><span class="c1 ">Empf&auml;nger:</span></p>
      <p class="c0 "><span class="c1 ">Beim Ausf&uuml;llen des Bewerbungsformulars auf unserer Webseite werden Daten,
                  einschlie&szlig;lich Ihrer IP-Adresse, E-Mail-Adresse und Telefonnummer an Infinity Box in die
                  USA &uuml;bertragen. Diese personenbezogenen Daten werden durch die Infinity Box gespeichert.
                  Die Infinity Box gibt die Daten nach eigener Aussage nicht an Dritte weiter.</span></p>
      <p class="c0 c2 "><span class="c1 "></span></p>
      <p class="c0 "><span class="c1 ">Drittlandtransfer:</span></p>
      <p class="c0 "><span class="c1 ">Infinity Box verarbeitet Ihre Daten in den USA und hat sich dem EU_US Privacy
                  Shield unterworfen https://www.privacyshield.gov/EU-US-Framework.</span></p>
      <p class="c0 c2 "><span class="c1 "></span></p>
      <p class="c0 "><span class="c1 ">Widerruf der Einwilligung:</span></p>
      <p class="c0 "><span>Sollten Sie die eine Datenspeicherung durch Infinity Box nicht w&uuml;nschen, k&ouml;nnen
                  Sie dieser grunds&auml;tzlich widersprechen, indem Sie &uuml;ber das Kontaktformular auf
            </span><span class="c5 "><a class="c9 "
                        href="https://www.google.com/url?q=https://help.wufoo.com/contact&amp;sa=D&amp;ust=1586545975577000 ">https://help.wufoo.com/contact</a></span><span>&nbsp;mit
                  Infinity Box Kontakt aufnehmen und der Datenspeicherung darin widersprechen</span><span class="c1 ">.
            </span></p>
      <p class="c0 c2 "><span class="c1 "></span></p>
      <p class="c0 "><span class="c1 ">Bereitstellung vorgeschrieben oder erforderlich:</span></p>
      <p class="c0 "><span class="c1 ">Die Bereitstellung Ihrer personenbezogenen Daten erfolgt freiwillig, allein auf
                  Basis Ihrer Einwilligung. Sofern Sie den Zugriff unterbinden, kann es hierdurch zu
                  Funktionseinschr&auml;nkungen auf der Website kommen.</span></p>
      <p class="c0 c2 "><span class="c1 "></span></p>
      <p class="c0 "><span class="c7 ">Einsatz von Clickfunnels</span></p>
      <p class="c0 c2 "><span class="c1 "></span></p>
      <p class="c0 "><span class="c1 ">Art und Zweck der Verarbeitung:</span></p>
      <p class="c0 "><span>Das Tool Clickfunnels der &bdquo;Etison LLC&ldquo;, erlaubt uns Webseiten einfach zu bauen
                  und logisch miteinander zu verkn&uuml;pfen. Beim Anbieter handelt es sich um die Etison LLC in
                  1860 W. Lakes Place, Meridian, Idaho 83646, E-Mail-Adresse: </span><span class="c5 "><a class="c9 "
                        href="mailto:support@clickfunnels.com ">support@clickfunnels.com</a></span><span
                  class="c1 ">.</span></p>
      <p class="c0 c2 "><span class="c1 "></span></p>
      <p class="c0 "><span class="c1 ">Clickfunnels erlaubt uns unsere Webseiten schnell zu bauen und auch flexibel
                  &auml;ndern zu k&ouml;nnen. So k&ouml;nnen wir dem Kunden einen aktuellen Web-Service
                  pr&auml;sentieren und Sicherheitsl&uuml;cken vermeiden, die bei einer isolierten L&ouml;sung
                  auftreten k&ouml;nnten. Des Weiteren kann Etison LLCs nach diese Daten zur Verbesserung und
                  Optimierung der eigenen Dienste nutzen.</span></p>
      <p class="c0 c2 "><span class="c1 "></span></p>
      <p class="c0 "><span class="c1 ">Rechtsgrundlage:</span></p>
      <p class="c0 "><span class="c1 ">Rechtsgrundlage f&uuml;r die Einbindung von Clickfunnels und dem damit
                  verbundenen Datentransfer zur Etison LLC ist Ihre Einwilligung (Art. 6 Abs. 1 lit. a
                  DSGVO).</span></p>
      <p class="c0 c2 "><span class="c1 "></span></p>
      <p class="c0 "><span class="c1 ">Empf&auml;nger:</span></p>
      <p class="c0 "><span class="c1 ">Beim Besuch unserer Webseite werden Daten, einschlie&szlig;lich Ihrer IP-Adresse
                  an Etison LLC in die USA &uuml;bertragen. Diese personenbezogenen Daten werden durch die Etison
                  LLC gespeichert. Die Etison LLC gibt die Daten nach eigener Aussage nicht an Dritte
                  weiter.</span></p>
      <p class="c0 c2 "><span class="c1 "></span></p>
      <p class="c0 "><span class="c1 ">Unser Unternehmen enth&auml;lt keine Informationen, mittels derer die betroffene
                  Person identifiziert werden k&ouml;nnte. </span></p>
      <p class="c0 c2 "><span class="c1 "></span></p>
      <p class="c0 "><span>Mehr Informationen &uuml;ber die Datenverarbeitung durch die Etison LLC bei der Nutzung on
                  clickfunnels finden Sie unter </span><span class="c5 "><a class="c9 "
                        href="https://www.google.com/url?q=https://signup.clickfunnels.com/privacy-policy&amp;sa=D&amp;ust=1586545975580000 ">https://signup.clickfunnels.com/privacy-policy</a></span><span
                  class="c1 ">.</span></p>
      <p class="c0 c2 "><span class="c1 "></span></p>
      <p class="c0 "><span class="c1 ">Drittlandtransfer:</span></p>
      <p class="c0 "><span>Etison LLC verarbeitet Ihre Daten in den USA und hat sich dem EU_US Privacy Shield
                  unterworfen https://www.privacyshield.gov/EU-US-Framework.</span></p>
      <p class="c0 c2 "><span class="c1 "></span></p>
      <p class="c0 "><span class="c1 ">Widerruf der Einwilligung:</span></p>
      <p class="c0 "><span>Nach eigenen Angaben erhebt Etison LLC bei diesem Vorgang nur dann personenbezogenen Daten,
                  wenn der Nutzer diese direkt in die jeweiligen Formulare eingibt. Sollten Sie die eine
                  Datenspeicherung durch Clickfunnels nicht w&uuml;nschen, k&ouml;nnen Sie dieser
                  grunds&auml;tzlich widersprechen, indem Sie dies in einer E-Mail an </span><span class="c5 "><a
                        class="c9 "
                        href="mailto:compliance@clickfunnels.com ">compliance@clickfunnels.com</a></span><span
                  class="c1 ">&nbsp;erkl&auml;ren. </span></p>
      <p class="c0 c2 "><span class="c1 "></span></p>
      <p class="c0 "><span class="c1 ">Bereitstellung vorgeschrieben oder erforderlich:</span></p>
      <p class="c0 "><span class="c1 ">Die Bereitstellung Ihrer personenbezogenen Daten erfolgt freiwillig, allein auf
                  Basis Ihrer Einwilligung. Sofern Sie den Zugriff unterbinden, kann es hierdurch zu
                  Funktionseinschr&auml;nkungen auf der Website kommen.</span></p>
      <p class="c0 c2 "><span class="c1 "></span></p>
      <p class="c0 c2 "><span class="c1 "></span></p>
      <p class="c0 "><span class="c7 ">SSL-Verschl&uuml;sselung</span></p>
      <p class="c0 "><span class="c1 ">Um die Sicherheit Ihrer Daten bei der &Uuml;bertragung zu sch&uuml;tzen,
                  verwenden wir dem aktuellen Stand der Technik entsprechende Verschl&uuml;sselungsverfahren (z.
                  B. SSL) &uuml;ber HTTPS.</span></p>
      <p class="c0 c2 "><span class="c1 "></span></p>
      <p class="c0 "><span class="c1 ">&Auml;nderung unserer Datenschutzbestimmungen</span></p>
      <p class="c0 "><span class="c1 ">Wir behalten uns vor, diese Datenschutzerkl&auml;rung anzupassen, damit sie stets
                  den aktuellen rechtlichen Anforderungen entspricht oder um &Auml;nderungen unserer Leistungen in
                  der Datenschutzerkl&auml;rung umzusetzen, z.B. bei der Einf&uuml;hrung neuer Services. F&uuml;r
                  Ihren erneuten Besuch gilt dann die neue Datenschutzerkl&auml;rung.</span></p>
      <p class="c0 c2 "><span class="c1 "></span></p>
      <p class="c0 "><span class="c1 ">Fragen an den Datenschutzbeauftragten</span></p>
      <p class="c0 "><span class="c1 ">Wenn Sie Fragen zum Datenschutz haben, schreiben Sie uns bitte eine E-Mail oder
                  wenden Sie sich direkt an die f&uuml;r den Datenschutz verantwortliche Person in unserer
                  Organisation:</span></p>
      <p class="c0 c2 "><span class="c1 "></span></p>
      <p class="c0 padding-bottom"><span class="c1">Die Datenschutzerkl&auml;rung wurde mithilfe der activeMind AG
                  erstellt,
                  den
                  Experten f&uuml;r externe Datenschutzbeauftragte (Version #2019-04-10). Eine Ausnahme stellt die
                  Erkl&auml;rung &uuml;ber due Verwendung von Clickfunnels dar, die sich aber an der Struktur von
                  activeMind orientiert. </span></p>
</div>