<div class="dialog-container">
    <mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
    <form *ngIf="!loading" (submit)="submitSelectedEvent()" ngNativeValidate>
        <div mat-dialog-title class="flex-space-between">
            <h1>Terminauswahl für Tag {{ choosenDate | date:'shortDate' }}</h1>
            <mat-icon class="pointer" (click)="onNoClick()">close</mat-icon>
        </div>

        <div mat-dialog-content>
            <div *ngFor="let s of bookableSlots; let i = index;">

                <app-event-slot-selector (click)="selectedSlot = i;" *ngIf="s['room']" [slot]="s" [selected]="selectedSlot == i"></app-event-slot-selector>
                <app-event-slot-selector *ngIf="!s['room']" [deactivated]="true" [slot]="s" [selected]="selectedSlot == i"></app-event-slot-selector>
            </div>


            <div>
                <mat-form-field class="width-100">
                    <mat-label>Website der Veranstaltung</mat-label>
                    <input [disabled]="disableForm" type="url"  (keyup)="validateURL()" [(ngModel)]="bookingRequest['website']" name="website" matInput required placeholder="Z.B. www.deine-veranstaltung.de">
                </mat-form-field>
            </div>



            <div>
                <mat-form-field class="width-100">
                    <mat-label>Name Deiner Veranstaltung</mat-label>
                    <textarea [(ngModel)]="bookingRequest['description']" name="description" matInput required placeholder="Z.B. Konzert"></textarea>
                </mat-form-field>
            </div>

            <div>
                <mat-form-field class="width-100">
                    <mat-label>Nachricht an Venue</mat-label>
                    <textarea rows="10" matInput required name="message" [(ngModel)]="bookingRequest['message']" placeholder="Sehr geehrter Herr / Frau ..."></textarea>
                </mat-form-field>
            </div>
        </div>

        <div *ngIf="eventSlotService.slotDescription" mat-dialog-actions class="flex-space-between">
            <button type="button" mat-button (click)="setEventSlotText()">Text aus vorherigem Event kopieren</button>
            <div>
                <button type="button" mat-button (click)="onNoClick()">Abbrechen</button>
                <button type="submit" mat-raised-button color="primary">Übernehmen</button>
            </div>
        </div>

        <div *ngIf="!eventSlotService.slotDescription" mat-dialog-actions class="flex-right">
            <button type="button" mat-button (click)="onNoClick()">Abbrechen</button>
            <button type="submit" mat-raised-button color="primary">Übernehmen</button>
        </div>
    </form>

</div>