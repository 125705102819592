import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-room-info-fields',
  templateUrl: './room-info-fields.component.html',
  styleUrls: ['./room-info-fields.component.scss'],
})
export class RoomInfoFieldsComponent implements OnInit {
  @Input() fields = [];
  @Input() filterField = '';
  @Input() dataJSON = {};

  constructor() {}

  ngOnInit(): void {
    // setTimeout(() => {
    //   console.log('fo');
    //   console.log('fields: ', this.fields);
    //   console.log('filterField:', this.filterField);
    //   console.log('dataJSON', this.dataJSON);
    // }, 1000);
  }

  getFields() {
    return this.fields.filter((f) => f['category'] == this.filterField);
  }
}
