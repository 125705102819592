import { FormControl, FormGroup } from '@angular/forms';
import {
  endOfHour,
  getDate,
  getHours,
  getMilliseconds,
  getMinutes,
  getMonth,
  getSeconds,
  getTime,
  getYear,
  isEqual,
  isExists,
  isSameDay,
  set,
  startOfHour,
} from 'date-fns';
import { eventUnixTs } from '../types/eventUnixTs.type';
import { eventJSDate } from '../types/eventJSDate.type';
import { stageSize } from '../types/stageSize.type';
import { selectedEventSlot } from '../types/selectedEventSlot.type';
import { locationStatus } from './constants/locationStatus';
import { locationUserRoles } from './constants/locationUserRoles';
import { firebaseTS } from '../types/firebaseTS.type';
import { eventSlotStatus } from 'src/app/utils/constants/eventSlotStatus';
import { eventFbDate } from '../types/eventFbDate.type';
import { tour } from '../types/tour.type';

export function validateForm(form: FormGroup) {
  for (const key of Object.keys(form.controls)) {
    if (form.controls[key].invalid) {
      // const selector = '[formControlName]="' + key + '"'; // Before: [formcontrolname="' + key + '"]
      const selector = 'id="' + key + '"';
      // const invalidControl: any = document.querySelector(selector);
      const invalidControl: any = document.getElementById(key);
      try {
        console.log('Focus invalid field', key);
        invalidControl.focus();
      } catch (e) {
        console.error('There is no <input> field with ' + selector);
      }
      break;
    }
  }

  return form.valid;
}

export function validateFormControl(formControl: FormControl, key: string) {
  if (formControl.invalid) {
    const invalidControl: any = document.querySelector(
      '[data-placeholder="' + key + '"]'
    );
    try {
      console.log('Focus invalid field', key);
      invalidControl.focus();
    } catch (e) {
      console.error(
        'There is no <input> field with [formcontrolname="' + key + '"]'
      );
    }
  }

  return formControl.valid;
}

export function distance(lat1, lon1, lat2, lon2) {
  const unit = 'K';
  if (lat1 == lat2 && lon1 == lon2) {
    return 0;
  } else {
    let radlat1 = (Math.PI * lat1) / 180;
    let radlat2 = (Math.PI * lat2) / 180;
    let theta = lon1 - lon2;
    let radtheta = (Math.PI * theta) / 180;

    let dist =
      Math.sin(radlat1) * Math.sin(radlat2) +
      Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    if (dist > 1) {
      dist = 1;
    }
    dist = Math.acos(dist);
    dist = (dist * 180) / Math.PI;
    dist = dist * 60 * 1.1515;
    if (unit == 'K') {
      dist = dist * 1.609344;
    }
    // if (unit=="N") { dist = dist * 0.8684 }
    return dist;
  }
}

export function isNotPastDay(d: Date): boolean {
  d.setHours(0, 0, 0, 0);
  let today = new Date();
  today.setHours(0, 0, 0, 0);
  return d.getTime() - today.getTime() >= 0;
}

export function isInEventSlots(d: Date, slots: {}[]) {
  return slots.some((s) => {
    return isSlotInSameDay(d, s);
  });
}

export function isSlotInSameDay(d: Date, s: {}) {
  return isSameDay(d, s['startSlot']) || isSameDay(d, s['endSlot']);
}

export function isEventSlotInRoom(room: any): boolean {
  return 'eventSlots' in room && room['eventSlots'].length > 0;
}

export function getEventlerTxt(lName, date): string {
  return `Du hast eine Buchung für "${lName}" angefragt am ${date} angefragt. Du erhälst hier eine Benachrichtigung, sobald dein Gegenüber geantwortet hat.`;
}

export function getEventlerTxtAcceptance(lName, date): string {
  return `Deine Buchung für "${lName}" wurde am ${date} akzeptiert. Bitte akzeptiere noch den angehängten Vertrag.`;
}

export function getLocationOwnerTxtRequest(type, lName, date): string {
  return `Eine Buchung für "${lName}" (${type}) wurde am ${date} angefragt.`;
}

export function getLocationOwnerTxtAccept(lName, date): string {
  return `Du hast für "${lName}" am ${date} eine Buchung akzeptiert`;
}

export function getTourNotification(notifications: any[], start: Date) {
  for (let i = 0; i < notifications.length; i++) {
    const notification = notifications[i];
    let nStartFire: firebaseTS = notification['payload']['startSlot'];
    let nStart = getJsDateFromFireTs(
      nStartFire['seconds'],
      nStartFire['nanoseconds']
    );
    if (
      'payload' in notification &&
      'tourId' in notification['payload'] &&
      'locationId' in notification['payload'] &&
      'startSlot' in notification['payload'] &&
      isEqual(nStart, start)
    ) {
      let n = { ...notification };
      console.log('getTourNotification n', notification);
      return n;
    }
  }

  return notifications.filter((notification) => {
    let nStartFire: firebaseTS = notification['payload']['startSlot'];
    let nStart = getJsDateFromFireTs(
      nStartFire['seconds'],
      nStartFire['nanoseconds']
    );
    return isEqual(nStart, start);
  });
}

export function getFilteredEventSlots(date: Date, slots: {}[]) {
  let a = slots.filter((s) => {
    return isSlotInSameDay(date, s);
  });
  return a;
}

export function getBookableSlots(slots: {}[]) {
  return slots.filter((s) => {
    return s['status'] !== eventSlotStatus.booked;
  });
}

export function getSlotsInJsDate(filteredSlots: {}[]): {}[] {
  return filteredSlots.map((slot) => {
    let s = { ...slot };
    s['startSlot'] = getJsDate(s['startSlot']);
    s['endSlot'] = getJsDate(s['endSlot']);
    return s;
  });
}

export function getLocationWithRoom(locations: {}[], room: {}): {} {
  for (let i = 0; i < locations.length; i++) {
    const l: {} = locations[i];

    if (!l['rooms']) {
      return;
    }

    for (let j = 0; j < l['rooms'].length; j++) {
      const r = l['rooms'][j];
      if (isRoomCreated(r) && r['UUID'] === room['UUID']) {
        return l;
      }
    }
    // search for room in rooms
  }
  return undefined;
}

export function ConvertKeysToLowerCase(obj) {
  var output = {};
  for (let i in obj) {
    if (Object.prototype.toString.apply(obj[i]) === '[object Object]') {
      output[i.toLowerCase()] = ConvertKeysToLowerCase(obj[i]);
    } else if (Object.prototype.toString.apply(obj[i]) === '[object Array]') {
      output[i.toLowerCase()] = [];
      output[i.toLowerCase()].push(ConvertKeysToLowerCase(obj[i][0]));
    } else {
      output[i.toLowerCase()] = obj[i];
    }
  }
  return output;
};

export function isRoomCreated(r: {}) {
  return Object.keys(r).length > 0;
  // return r && 'UUID' in r;
}

export function getLocationOwners(l: {}): {}[] {
  return l['users'].filter(isLocationOwner);
}

export function isLocationOwner(user) {
  return user.role === locationUserRoles.owner;
}

export function getEventByStartDate(
  location: any,
  startDate: Date
): eventUnixTs {
  return location.places[0].bookableEventSlots.find((event: eventUnixTs) =>
    isEqual(
      getJsDateFromFireTs(event.startSlot.seconds, event.startSlot.nanoseconds),
      startDate
    )
  );
}

export function isNewLocation(locations: any[], location: any) {
  for (let i = 0; i < locations.length; i++) {
    const l = locations[i];
    if (l['customIdName'] === location.customIdName) {
      return false;
    }
  }
  return true;
}

export function getLocationById(locations: any[], id: string): {} {
  for (let i = 0; i < locations.length; i++) {
    const l = locations[i];
    if (l['customIdName'] === id) {
      return l;
    }
  }
  return null;
}

export function isDateExisting(date: Date): boolean {
  return isExists(getYear(date), getMonth(date), getDate(date));
}

export function slotsToJsDate(slotsFb: {}[]) {
  return slotsFb.map((s) => {
    let slot = { ...s };
    slot['startSlot'] = getJsDate(s['startSlot']);
    slot['endSlot'] = getJsDate(s['endSlot']);
    return slot;
  });
}

export function isOverlappingSlotPresent(eventSlots, slot: {}) {
  for (let i = 0; i < eventSlots.length; i++) {
    const s = eventSlots[i];
    if (
      isOverlappingTimeslot(
        s['startSlot'],
        s['endSlot'],
        slot['startSlot'],
        slot['endSlot']
      )
    ) {
      return true;
    }
  }
  return false;
}

export function isOverlappingTimeslot(
  startDateA: Date,
  endDateA: Date,
  startDateB: Date,
  endDateB: Date
): boolean {
  return (
    getTime(startDateA) <= getTime(endDateB) &&
    getTime(startDateB) <= getTime(endDateA)
  );
}

export function getJsDateFromFireTs(
  seconds: number,
  nanoseconds: number
): Date {
  return new Date(Math.floor(seconds * 1000 + nanoseconds / 1000000));
}

export function getJsDate(date: firebaseTS): Date {
  if (date.seconds) {
    return new Date(Math.floor(date.seconds * 1000 + date.nanoseconds / 1000000));
  } else {
    return (date as any); // Date has as wrong format, probably Date
  }
}

export function getTourSlotsAmount(tours: tour[]) {
  let occurence = 0;
  for (let i = 0; i < tours.length; i++) {
    const tourSlot = tours[i]['tourSlots'];
    for (let j = 0; j < tourSlot.length; j++) {
      const slot = tourSlot[j];
      occurence++;
    }
  }
  return occurence;
}

export function getJsDateSlotByFbDate(eventSlot: eventFbDate): eventJSDate {
  let slotTemp: any = { ...eventSlot };
  slotTemp['startSlot'] = getJsDate(slotTemp['startSlot']);
  slotTemp['endSlot'] = getJsDate(slotTemp['endSlot']);
  let slotJsDate: eventJSDate = { ...slotTemp };
  return slotJsDate;
}

export function getRoomOfSlot(slot, location) {
  for (let i = 0; i < location['rooms'].length; i++) {
    const r = location['rooms'][i];
    if (r['UUID'] === slot['roomUUID']) {
      return r;
    }
  }
  console.error('ERROR Location ' + location['name'] + 'does not contain room', slot['roomUUID'], slot, location,'---> SlotID', slot['customIdName']);
  return undefined;
}

export function isWithinStageSize(l: any, minStageSize: stageSize): boolean {
  for (let i = 0; i < l['rooms'].length; i++) {
    const room = l['rooms'][i];
    if (roomIsWithinStageSize(room, minStageSize)) {
      return true;
    }
  }
  return false;
}


export function roomIsWithinStageSize(room, minStageSize) {
  return parseInt(room['mainStageDepth']) >= minStageSize.stageDepth &&
    parseInt(room['mainStageHeight']) >= minStageSize.stageHeight &&
    parseInt(room['mainStageWidth']) >= minStageSize.stageBreadth;
}

function isValidDate(eventSlot, date) {
  if (!date) {
    return true;
  }

  return (
    isSameDay(eventSlot['startSlot'], date) ||
    isSameDay(eventSlot['endSlot'], date)
  );
}

/**
 * Returns true if at least ONE slot fits the requirements
 * 
 * @param slots 
 * @param date 
 * @param priceRange 
 * @returns 
 */
export function isValidDateAndPrice(
  slots: {}[],
  date: Date,
  priceRange: number
): boolean {
  for (let i = 0; i < slots.length; i++) {
    const s = slots[i];
    s['startSlot'] = getJsDate(s['startSlot']);
    s['endSlot'] = getJsDate(s['endSlot']);
    if (isValidDate(s, date) && s['bookingFee'] <= priceRange) {
      return true;
    }
  }
  return false;
}


/**
 * Returns all slots which fit the given requirements
 * 
 * @param slots 
 * @param date 
 * @param priceRange 
 * @returns 
 */
export function filterSlotsForDateAndPrice(
  slots: {}[],
  date: Date,
  priceRange: number
): Array<any> {
  let filteredSlots = [];

  for (let i = 0; i < slots.length; i++) {
    const s = slots[i];
    s['startSlot'] = getJsDate(s['startSlot']);
    s['endSlot'] = getJsDate(s['endSlot']);
    if (isValidDate(s, date) && s['bookingFee'] <= priceRange) {
      filteredSlots.push(s);
    }
  }
  return filteredSlots;
}

export function isImgScrsExistent(l: any) {
  return 'imgSrcs' in l;
}

export function hasImgs(l: any) {
  return true;
  // return isImgScrsExistent(l) && l['imgSrcs'].length > 0;
}

export function isImgInRoom(r): boolean {
  return 'images' in r && r['images'].length > 0;
}

export function isWithValidRoom(l: {}) {
  if ('rooms' in l) {
  }
  for (let i = 0; i < l['rooms'].length; i++) {
    const r: {} = l['rooms'][i];
  }
}

export function hasEventSlots(l: any) {
  return true;
  // return (
  //   isPlacesExistent(l) &&
  //   isBookableEventsExistent(l) &&
  //   l.places[0]['bookableEventSlots'].length > 0
  // );
}

export function isSlotAlreadychoosen(
  selectedSlots: selectedEventSlot[],
  slot: selectedEventSlot
) {
  return selectedSlots.some((s) => {
    return s['daySlot']['customIdName'] === slot['daySlot']['customIdName'];
  });
}

export function isPlacesExistent(l: any) {
  return 'places' in l;
}

export function isBookableEventsExistent(l: any) {
  return 'bookableEventSlots' in l.rooms[0];
}

export function isBookableEventsEmpty(l: any) {
  return (
    isBookableEventsExistent(l) &&
    l.places[0]['bookableEventSlots'].length === 0
  );
}

export function isPreValidLocation(l) {
  return isApproved(l) || isLocationLngLatExistent(l); //&& hasLocationContract(l)
}

export function isLocationLngLatExistent(l: any) {
  return (
    'location' in l &&
    'lat' in l['location'] &&
    'lng' in l['location'] &&
    l['location']['lat'] !== 0 &&
    l['location']['lng'] !== 0
  );
}

export function isApproved(l: any) {
  return l['status'] === locationStatus.approved;
}

export function isLocationContractExistent(l: any) {
  return 'contractTemplateSrcs' in l;
}

export function hasLocationContract(l: any) {
  return isLocationContractExistent(l) && l['contractTemplateSrcs'].length > 0;
}

export function isLocationImgSrcsExistent(l: any) {
  return 'imgSrcs' in l;
}

export function hasLocationImg(l: any) {
  return l['imgSrcs'].length > 0;
}

export function clearTimeEventSlots(eventSlots: eventJSDate[]): eventJSDate[] {
  for (let i = 0; i < eventSlots.length; i++) {
    const eventSlot: eventJSDate = eventSlots[i];
    eventSlot.startSlot.setMilliseconds(0);
    eventSlot.startSlot.setSeconds(0);
    eventSlot.startSlot.setMinutes(0);
    eventSlot.endSlot.setMilliseconds(0);
    eventSlot.endSlot.setSeconds(59);
    eventSlot.endSlot.setMinutes(59);
  }
  return eventSlots;
}

export function setDatetoStartOfHour(d: Date): Date {
  return startOfHour(d);
}

export function setDateToEndOfHour(d: Date): Date {
  return endOfHour(d);
}

export function getDateUpToHour(d: Date): Date {
  let result = set(new Date(), {
    year: getYear(d),
    month: getMonth(d),
    date: getDate(d),
    hours: getHours(d),
    minutes: getMinutes(d),
    seconds: getSeconds(d),
    milliseconds: getMilliseconds(d),
  });
  return result;
}

export function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function minimizeFirstLetter(string: string) {
  return string.charAt(0).toLowerCase() + string.slice(1);
}

export function isEmptyJSON(obj: any) {
  return obj && Object.keys(obj).length === 0 && obj.constructor === Object;
}

export function firstNumberFromString(str) {
  return +str.replace(/(^.+\D)(\d+)(\D.+$)/i, '$2');
}
