import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ArchiveComponent } from './components/archive/archive.component';
import { CalendarComponent } from './components/calendar/calendar.component';
import { ContractsComponent } from './components/contracts/contracts.component';
import { EditLocationComponent } from './components/edit-location/edit-location.component';
import { ImprintComponent } from './components/imprint/imprint.component';
import { LandingComponent } from './components/landing/landing.component';
import { LocationComponent } from './components/location/location.component';
import { MailboxComponent } from './components/mailbox/mailbox.component';
import { MediaComponent } from './components/media/media.component';
import { MyLocationsComponent } from './components/my-locations/my-locations.component';
import { ProfileComponent } from './components/profile/profile.component';
import { LoggedInGuard } from 'ngx-auth-firebaseui';
import { TourPlannerComponent } from './components/tour-planner/tour-planner.component';
import { ExamineLocationComponent } from './components/examine-location/examine-location.component';
import { AngularFireAuthGuard } from '@angular/fire/auth-guard';
import { PublicProfileComponent } from './components/profile/public-profile/public-profile.component';
import { RegistrationCompleteGuard } from './guards/registration-complete.guard';
import { LocationSearchComponent } from './components/location-search/location-search.component';
import { TourInfoComponent } from './components/tour-info/tour-info.component';
import { DataProtectionComponent } from './components/legal/data-protection/data-protection.component';
import { AgbComponent } from './components/legal/agb/agb.component';

const routes: Routes = [
  {
    path: '',
    component: LandingComponent,
  },
  {
    path: 'profile',
    component: ProfileComponent,
    canActivate: [AngularFireAuthGuard],
  },
  {
    path: 'profile/:uid',
    component: PublicProfileComponent,
    canActivate: [AngularFireAuthGuard],
  },
  {
    path: 'my-tours',
    component: TourInfoComponent,
  },
  {
    path: 'my-locations',
    component: MyLocationsComponent,
    canActivate: [AngularFireAuthGuard, RegistrationCompleteGuard],
  },
  {
    path: 'location-search',
    component: LocationSearchComponent,
    canActivate: [],
  },
  {
    path: 'location/edit/:id',
    component: EditLocationComponent,
    canActivate: [AngularFireAuthGuard],
  },
  {
    path: 'location/:id',
    component: LocationComponent,
    canActivate: [],
  },
  {
    path: 'media',
    component: MediaComponent,
    canActivate: [AngularFireAuthGuard, RegistrationCompleteGuard],
  },
  {
    path: 'calendar',
    component: CalendarComponent,
    canActivate: [AngularFireAuthGuard],
  },
  {
    path: 'mailbox',
    component: MailboxComponent,
    canActivate: [AngularFireAuthGuard],
  },
  {
    path: 'archive',
    component: ArchiveComponent,
    canActivate: [AngularFireAuthGuard],
  },
  {
    path: 'contracts',
    component: ContractsComponent,
    canActivate: [AngularFireAuthGuard],
  },
  {
    path: 'imprint',
    component: ImprintComponent,
    canActivate: [],
  },
  {
    path: 'agb',
    component: AgbComponent,
    canActivate: [],
  },
  {
    path: 'data-protection',
    component: DataProtectionComponent,
    canActivate: [],
  },
  {
    path: 'tour-planner',
    component: TourPlannerComponent,
    canActivate: [AngularFireAuthGuard],
  },
  {
    path: 'examine-location',
    component: ExamineLocationComponent,
    canActivate: [AngularFireAuthGuard],
  },
  {
    path: '**',
    component: LandingComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
