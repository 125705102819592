import { ConvertKeysToLowerCase } from "src/app/utils/helpers";

// Hier bearbeiten https://sodeve.net/sencha/json-table-editor/
export const PLACE_FIELDS = [
  {
    field: 'name',
    label: 'Name',
    placeholder:
      'Trage hier den vollen Namen von der Räumlichkeit ein. SADEY empfiehlt, den größten Raum zuerst anzulegen und den kleinsten zuletzt.',
    type: 'text',
    category: 'NAME',
    optional: false,
  },
  {
    field: 'seatsRow',
    label: 'Sitzplätze Reihe',
    placeholder: 'max. Sitzplätze bei Reihenbestuhlung',
    type: 'number',
    category: 'SITZPLÄTZE',
    optional: false,
  },
  {
    field: 'seatsParlament',
    label: 'Sitzplätze Parlament',
    placeholder: 'max. Sitzplätze bei Parlament-Bestuhlung',
    type: 'number',
    category: 'SITZPLÄTZE',
    optional: false,
  },
  {
    field: 'seatsBankett',
    label: 'Sitzplätze Bankett',
    placeholder: 'max. Sitzplätze bei Bankett-Bestuhlung',
    type: 'number',
    category: 'SITZPLÄTZE',
    optional: false,
  },
  {
    field: 'standingPlaces',
    label: 'Stehplätze',
    placeholder: 'max. Stehplätze',
    type: 'number',
    category: 'SITZPLÄTZE',
    optional: false,
  },
  {
    field: 'placesDuringCorona',
    label: 'Corona- Edition',
    placeholder: 'max. Sitzplätze bei geltender Hygieneordnung',
    type: 'number',
    category: 'SITZPLÄTZE',
    optional: false,
  },

  // ----------------- GRUNDRISS:

  {
    field: 'spaceGesamt',
    label: 'Gesamtfläche Raum in m2',
    placeholder: 'Z.B. 1000qm',
    type: 'number',
    category: 'GRUNDRISS',
    optional: false,
  },
  {
    field: 'spaceLength',
    label: 'Länge',
    placeholder: 'Z.B. 40m',
    type: 'number',
    category: 'GRUNDRISS',
    optional: false,
  },
  {
    field: 'spaceWidth',
    label: 'Breite',
    placeholder: 'Z.B. 60m',
    type: 'number',
    category: 'GRUNDRISS',
    optional: false,
  },
  {
    field: 'spaceHeight',
    label: 'Höhe',
    placeholder: 'Z.B. 10m',
    type: 'number',
    category: 'GRUNDRISS',
    optional: false,
  },
  {
    field: 'spaceLights',
    label: 'Lichter Höhe',
    placeholder: 'Z.B. 10m',
    type: 'number',
    category: 'GRUNDRISS',
    optional: false,
  },

  // ----------------- BÜHNE
  {
    field: 'mainStageWidth',
    label: 'Breite Hauptbühne in meter',
    placeholder: '',
    type: '',
    category: 'MAßE HAUPTBÜHNE',
    optional: false,
  },
  {
    field: 'mainStageDepth',
    label: 'Tiefe Hauptbühne in meter',
    placeholder: 'Tiefe Hauptbühne in meter',
    type: '',
    category: 'MAßE HAUPTBÜHNE',
    optional: false,
  },
  {
    field: 'mainStageHeight',
    label: 'Höhe Hauptbühne in meter',
    placeholder: 'Höhe Hauptbühne in meter',
    type: '',
    category: 'MAßE HAUPTBÜHNE',
    optional: false,
  },
  {
    field: 'stageSpace',
    label: 'Bühnenfläche Hauptbühne gesamt in m2',
    placeholder: '',
    type: 'number',
    category: 'MAßE HAUPTBÜHNE',
    optional: false,
  },

  {
    field: 'stageWithPodest',
    label: 'Bühne mit Podesten vergrößerbar?',
    placeholder:
      'wenn ja, gibt es solche Podeste? Wieviel Spielraum hat man an den Kanten?',
    type: 'text',
    category: 'BÜHNENRAUM',
    optional: false,
  },
  {
    field: 'sideStageSize',
    label: 'Seitenbühne',
    placeholder: 'wenn ja, Gesamtfläche in m2',
    type: 'number',
    category: 'BÜHNENRAUM',
    optional: false,
  },
  {
    field: 'backStageSize',
    label: 'Hinterbühne',
    placeholder: 'wenn ja, Gesamtfläche in m2',
    type: 'number',
    category: 'BÜHNENRAUM',
    optional: false,
  },
  {
    field: 'quickchangeArea',
    label: 'Quickchange- Bereich',
    placeholder:
      'wenn ja, in der Nähe der Bühne? Ist hier genug Platz (für Tische, Stühle, Spiegel, Licht, Cases?) seitlich der Bühne?',
    type: 'text',
    category: 'BÜHNENRAUM',
    optional: false,
  },
  {
    field: 'sperrungen',
    label: 'Sperrungen',
    placeholder:
      'gibt es bei speziellen Bühnenbilder (zB Guckkastenbühne) gesperrte Plätze?',
    type: 'text',
    category: 'BÜHNENRAUM',
    optional: false,
  },
  {
    field: 'bandOrOrchester',
    label: 'Band/Orchester',
    placeholder:
      'gibt es einen Orchestergraben? Platz in der Nähe, oder auf der Bühne?',
    type: 'text',
    category: 'BÜHNENRAUM',
    optional: false,
  },
  {
    field: 'stromOderStarkstrom',
    label: 'Strom & Starkstrom',
    placeholder:
      'welche Stromanschlüsse gibt es? Bitte so genau es geht differenzieren: wie viele/ wo',
    type: 'text',
    category: 'BÜHNENRAUM',
    optional: false,
  },
  {
    field: 'besonderheiten',
    label: 'Besonderheiten',
    placeholder:
      'Wie ist der Zugang zur Bühne? Gibt es einen Backstage Eingang? Muss der Künstler durch einen öffentl. Bereich?',
    type: 'text',
    category: 'BÜHNENRAUM',
    optional: false,
  },
  {
    field: 'einlass',
    label: 'Einlass',
    placeholder:
      'Wieviele Stunden vor offiziellem Beginn ist in der Regel Einlass?',
    type: 'text',
    category: 'BÜHNENRAUM',
    optional: false,
  },
  {
    field: 'proben',
    label: 'Proben',
    placeholder:
      'Wieweit ist die Bühne vom Foyer entfernt? Kann bis zum Einlass geprobt werden, ohne das sich jemand gestört fühlt?',
    type: 'text',
    category: 'BÜHNENRAUM',
    optional: false,
  },

  {
    field: 'veranstaltungsTyp',
    label: 'Veranstaltungs- Typ',
    placeholder:
      'Für welche Art von Veranstaltung ist dieser Raum besonders geeignet?',
    type: 'text',
    category: 'AUSSTATTUNG',
    optional: false,
  },
  {
    field: 'bodenbeschaffenheitUndTon',
    label: 'Bodenbeschaffenheit und Ton',
    placeholder:
      'Teppich oder Parkett? Wie wirkt sich die Beschaffenheit auf den Sound aus?',
    type: 'text',
    category: 'AUSSTATTUNG',
    optional: false,
  },
  {
    field: 'tageslicht?',
    label: 'Tageslicht?',
    placeholder: 'Wenn ja, ist die Räumlichkeit verdunkelbar?',
    type: 'text',
    category: 'AUSSTATTUNG',
    optional: false,
  },
  {
    field: 'klima',
    label: 'Klima',
    placeholder:
      'Wie ist das Klima? Gibt es eine Klimaanlage? Kann die manuell geregelt werden?',
    type: 'text',
    category: 'AUSSTATTUNG',
    optional: false,
  },
  {
    field: 'barrierefrei',
    label: 'Ist der Raum barrierefrei?',
    placeholder: 'Wenn ja, wirkt sich das auch vorteilig auf die Ladewege aus.',
    type: 'text',
    category: 'AUSSTATTUNG',
    optional: false,
  },
  {
    field: 'LKWRampe',
    label: 'LKW-Rampe',
    placeholder:
      'Wenn ja, bitte auch erklären, dass diese im Winter zB eisfrei gehalten werden',
    type: 'text',
    category: 'AUSSTATTUNG',
    optional: false,
  },
  {
    field: 'wifi',
    label: 'Gibt es freies WLAN?',
    placeholder:
      'Wenn ja_  verfügbare Bandbreite? Gibt es Bereiche, wo das Wlan besonders stark oder schwach ist? ',
    type: 'text',
    category: 'AUSSTATTUNG',
    optional: false,
  },
  {
    field: 'schwarzerAushang ',
    label: 'Schwarzer Aushang',
    placeholder:
      'Verfügt das Haus über schwarzen Aushang oder Soffitten zur kostenlosen Nutzung? Ja oder Nein',
    type: 'text',
    category: 'AUSSTATTUNG',
    optional: false,
  },
  {
    field: 'merchandise',
    label: 'Merchandise',
    placeholder: 'erlaubt? in welchem Umfang?',
    type: 'text',
    category: 'AUSSTATTUNG',
    optional: false,
  },
  {
    field: 'programmverkauf',
    label: 'Programmverkauf',
    placeholder: 'erlaubt? wenn ja, wo?',
    type: 'text',
    category: 'AUSSTATTUNG',
    optional: false,
  },

  {
    field: 'beleuchtung',
    label: 'Beleuchtung',
    placeholder:
      'Hat der Raum Beleuchtung inklusive? Verfolger Spots? Ist hier etwas separat zu mieten?',
    type: 'text',
    category: 'TECHNIK',
    optional: false,
  },
  {
    field: 'ton',
    label: 'Ton',
    placeholder:
      'Hat der Raum eine Beschallungsanlage, wo sich eingekoppelt werden kann? Inklusive der Hallenmiete?',
    type: 'text',
    category: 'TECHNIK',
    optional: false,
  },
  {
    field: 'specialEffects',
    label: 'Special Effects',
    placeholder:
      'grundsätzlich erlaubt? Wenn ja, welche? Wer schaltet die Rauchmelder aus?',
    type: 'text',
    category: 'TECHNIK',
    optional: false,
  },
  {
    field: 'mischpulte',
    label: 'Mischpulte',
    placeholder:
      'bietet das Haus Mischpulte an? Gibt es einen dedizierten Platz? Wenn im Publikumsbereich, werden diese bewacht?',
    type: 'text',
    category: 'TECHNIK',
    optional: false,
  },
  {
    field: 'haengepunkte',
    label: 'Hängepunkte',
    placeholder:
      'wie viele? wo? welche Lasten? Bitte so konkret wie möglich ausfüllen und darauf hinweisen, wenn es hierzu ein pdf gibt',
    type: 'text',
    category: 'TECHNIK',
    optional: false,
  },
  {
    field: 'prospektzuege',
    label: 'Prospektzüge',
    placeholder:
      'Gibt es Prospektzüge; Wie viele und wo? Wieviel Gewicht? Bitte so konkret wie möglich und ggf. auf download hinweisen.',
    type: 'text',
    category: 'TECHNIK',
    optional: false,
  },
  {
    field: 'hauspersonalTechn',
    label: 'Hauspersonal techn.',
    placeholder:
      'Braucht das Hauspersonal zum Be- und Entladen der Kontergewichte für die Prospektzüge auf dem Schnürboden Hilfe?',
    type: 'text',
    category: 'TECHNIK',
    optional: false,
  },
  {
    field: 'technischeDienstleistungen',
    label: 'technische Dienstleistungen',
    placeholder:
      'Welche weiteren Dienstleistungen sind erhältlich? Technisches Personal?',
    type: 'text',
    category: 'TECHNIK',
    optional: false,
  },
  {
    field: 'leerkisten',
    label: 'Leerkisten',
    placeholder: 'Gibt es Platz zum Verstauen? Wenn ja, wo?',
    type: 'text',
    category: 'TECHNIK',
    optional: false,
  },
  {
    field: 'ladeweg',
    label: 'Ladeweg',
    placeholder:
      'ist der Ladweg ungewöhnlich lang, oder kompliziert? Sind Aufzüge involviert?',
    type: 'text',
    category: 'TECHNIK',
    optional: false,
  },
  {
    field: 'aufbauUndAbbauInStunden',
    label: 'Aufbau & Abbau in Stunden',
    placeholder: 'Zeitangabe einer durchschnittlichen Produktion in Stunden',
    type: 'text',
    category: 'TECHNIK',
    optional: false,
  },
  {
    field: 'extraWissenTechnik',
    label: 'extra Wissen',
    placeholder:
      'Was gibt es noch zu wissen? Was überzeugt potentielle Mieter in der Regel am meisten?',
    type: 'text',
    category: 'TECHNIK',
    optional: false,
  },
];


let CATEGORY_DESCRIPTIONS = {
  "name":"Trage hier den vollen Namen von der Räumlichkeit ein. SADEY empfiehlt, den größten Raum zuerst anzulegen und den kleinsten zuletzt.",
  "SITZPLÄTZE":"Bitte achte hier auf korrekte Angaben. Die Zahlen werden in unserem Filter genutzt und sind maßgeblich für die Auffindbarkeit & den Match zu suchenden Veranstaltern.",
  "GRUNDRISS": "Bitte trage hier die jeweiligen Angaben der Räumlichkeit ein. Diese Zahlen sind für die Such-Filter nicht von Bedeutung.",
  "MAßE HAUPTBÜHNE": "Bitte achte hier auf korrekte Angaben. Die Zahlen sind relevant für unsere Suchalgorythmen.",
  "BÜHNENRAUM": "Die folgenden Fragen sind in der Entscheidungsfindung einer Produktion und vor allem für die tatsächliche Durchführbarkeit von größter Wichtigkeit. Bitte so detailliert wie möglich beantworten.",
  "ausstattung": "Bedenke bitte bei den folgenden Fagen, dass du mit deinen Antworten keine Besucher ansprichst, sondern potentielle Mieter. Je mehr Details, desto besser!",
  "TECHNIK": "Die folgenden Felder bedürfen technischer Expertise. "
};

export function ROOM_CATEGORIES() {
  let categories = [];
  PLACE_FIELDS.forEach((field, i) => {
    if (!field) {
      console.info('field ' + i + ' is undefined!', field, PLACE_FIELDS);
    }
    if (field['category'] && !categories.includes(field['category'])) {
      categories.push(field['category']);
    }
  });
  return categories;
}




export function GET_DESCRIPTION(category) {
  return ConvertKeysToLowerCase(CATEGORY_DESCRIPTIONS)[category.toLowerCase()] || category; 
}

// Alte Felder

// {
// 	"field": "buildTime",
// 	"label": "Aufbau / Abbau",
// 	"placeholder": "",
// 	"type": "",
// 	"category": "Alte Felder, soll ich die löschen?",
// 	"optional": false
// },
// {
// 	"field": "capacity",
// 	"label": "Kapazitäten",
// 	"placeholder": "Z.B.  800",
// 	"type": "number",
// 	"category": "Alte Felder, soll ich die löschen?",
// 	"optional": false
// }, {
// 	"field": "maxWeight",
// 	"label": "Zulässiges Gesamtgewicht",
// 	"placeholder": "Z.B. 10 Tonnen",
// 	"type": "",
// 	"category": "Alte Felder, soll ich die löschen?",
// 	"optional": false
// }, {
// 	"field": "emptyWeight",
// 	"label": "Leergewicht",
// 	"placeholder": "z. B. 1 Tonne",
// 	"type": "",
// 	"category": "Alte Felder, soll ich die löschen?",
// 	"optional": false
// },  {
// 	"field": "backstageHeight",
// 	"label": "Maße Hinterbühne (Höhe in Meter)",
// 	"placeholder": "",
// 	"type": "",
// 	"category": "Alte Felder, soll ich die löschen?",
// 	"optional": false
// }, {
// 	"field": "backstageWidth",
// 	"label": "Maße Hinterbühne (Breite in Meter)",
// 	"placeholder": "",
// 	"type": "",
// 	"category": "Alte Felder, soll ich die löschen?",
// 	"optional": false
// }, {
// 	"field": "backstageDepth",
// 	"label": "Maße Hinterbühne (Tiefe in Meter)",
// 	"placeholder": "",
// 	"type": "",
// 	"category": "Alte Felder, soll ich die löschen?",
// 	"optional": false
// }, {
// 	"field": "sideStageHeight",
// 	"label": "Maße Seitenbühne (Höhe in Meter)",
// 	"placeholder": "",
// 	"type": "",
// 	"category": "Alte Felder, soll ich die löschen?",
// 	"optional": false
// }, {
// 	"field": "sideStageWidth",
// 	"label": "Maße Seitenbühne (Breite in Meter)",
// 	"placeholder": "",
// 	"type": "",
// 	"category": "Alte Felder, soll ich die löschen?",
// 	"optional": false
// }, {
// 	"field": "sideStageDepth",
// 	"label": "Maße Seitenbühne (Tiefe in Meter)",
// 	"placeholder": "",
// 	"type": "",
// 	"category": "Alte Felder, soll ich die löschen?",
// 	"optional": false
// }, {
// 	"field": "sideTrailerHeight",
// 	"label": "Maße Anhänger (Höhe in Meter)",
// 	"placeholder": "",
// 	"type": "",
// 	"category": "Alte Felder, soll ich die löschen?",
// 	"optional": true
// }, {
// 	"field": "sideTrailerWidth",
// 	"label": "Maße Anhänger (Breite in Meter)",
// 	"placeholder": "",
// 	"type": "",
// 	"category": "Alte Felder, soll ich die löschen?",
// 	"optional": true
// }, {
// 	"field": "sideTrailerDepth",
// 	"label": "Maße Anhänger (Tiefe in Meter)",
// 	"placeholder": "",
// 	"type": "",
// 	"category": "Alte Felder, soll ich die löschen?",
// 	"optional": true
// }, {
// 	"field": "lightHeight",
// 	"label": "Lichterhöhe",
// 	"placeholder": "",
// 	"type": "",
// 	"category": "Alte Felder, soll ich die löschen?",
// 	"optional": false
// }, {
// 	"field": "prospektzuege",
// 	"label": "Prospektzüge",
// 	"placeholder": "",
// 	"type": "",
// 	"category": "Alte Felder, soll ich die löschen?",
// 	"optional": false
// }, {
// 	"field": "haengepunkte",
// 	"label": "Hängepunkte",
// 	"placeholder": "",
// 	"type": "",
// 	"category": "Alte Felder, soll ich die löschen?",
// 	"optional": false
// }, {
// 	"field": "light",
// 	"label": "Beleuchtung",
// 	"placeholder": "",
// 	"type": "",
// 	"category": "Alte Felder, soll ich die löschen?",
// 	"optional": false
// }, {
// 	"field": "specialEffects",
// 	"label": "Spezialeffekts",
// 	"placeholder": "",
// 	"type": "",
// 	"category": "Alte Felder, soll ich die löschen?",
// 	"optional": false
// }, {
// 	"field": "mixer",
// 	"label": "Mischpulte",
// 	"placeholder": "",
// 	"type": "",
// 	"category": "Alte Felder, soll ich die löschen?",
// 	"optional": false
// }, {
// 	"field": "orchestra",
// 	"label": "Orchester",
// 	"placeholder": "",
// 	"type": "",
// 	"category": "Alte Felder, soll ich die löschen?",
// 	"optional": false
// }, {
// 	"field": "powerSupply",
// 	"label": "Stromanschlüsse",
// 	"placeholder": "",
// 	"type": "",
// 	"category": "Alte Felder, soll ich die löschen?",
// 	"optional": false
// }, {
// 	"field": "catering",
// 	"label": "Catering",
// 	"placeholder": "",
// 	"type": "",
// 	"category": "Alte Felder, soll ich die löschen?",
// 	"optional": false
// }, {
// 	"field": "einlasspersonal",
// 	"label": "Einlasspersonal",
// 	"placeholder": "",
// 	"type": "",
// 	"category": "Alte Felder, soll ich die löschen?",
// 	"optional": false
// }, {
// 	"field": "ordnerpersonal",
// 	"label": "Ordnerpersonal",
// 	"placeholder": "",
// 	"type": "",
// 	"category": "Alte Felder, soll ich die löschen?",
// 	"optional": false
// }
