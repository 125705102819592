<div class="calendarInforamtionContainer">
    <div class="datePickerContainer">
        <mat-calendar [class.transparent]="!caldenarLoadingComplete" [selected]="selectedDate"
            (selectedChange)="selectedDate = $event">
        </mat-calendar>
    </div>
    
    
    <div class="BookContainer flex-right">
        <button (click)="dateInquiryInput()" mat-raised-button color="primary">Buchen</button>
    </div>
</div>

<!--
    [dateFilter]="datePickerDateFilter"
    (dateChange)="dateInquiryInput($event.value)"
    [matDatepickerFilter]="datePickerDateFilter"
    [matDatepicker]="datepicker"
<mat-form-field class="datepicker" appearance="fill">
    <mat-label>Frage Termin an</mat-label>
    <input matInput [matDatepickerFilter]="datePickerDateFilter" [matDatepicker]="datepicker" disabled
        (dateChange)="dateInquiryInput($event.value)">
    <mat-datepicker-toggle matSuffix [for]="datepicker"></mat-datepicker-toggle>
    <mat-datepicker touchUi #datepicker disabled="false"></mat-datepicker>
</mat-form-field>
-->