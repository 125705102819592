import { Component, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { EventEmitter } from '@angular/core';
import { first } from 'rxjs/operators';
import { LocationService } from 'src/app/services/location.service';

@Component({
  selector: 'app-location-room-picker',
  templateUrl: './location-room-picker.component.html',
  styleUrls: ['./location-room-picker.component.scss'],
})
export class LocationRoomPickerComponent implements OnInit {
  @Input() public locationId: string;
  public location: {};
  public roomControl = new FormControl();
  public loaded: boolean = false;
  @Output() selectedRoomEvent = new EventEmitter<any>();

  constructor(public locationService: LocationService) { }

  ngOnInit(): void {
    this.getMyLocation().then(() => {
      this.roomControl.valueChanges.subscribe((room) => {
        this.selectedRoomEvent.emit(room);
      });
      this.loaded = true;
      this.roomControl.setValue(this.location['rooms'][0]);
      this.selectQuery();
    });

  }

  private async getMyLocation() {
    let location$ = await this.locationService.getLocation(this.locationId);
    this.location = await location$.pipe(first()).toPromise();
  }

  private selectQuery() {
    const urlParams = new URLSearchParams(window.location.search);
    const room = urlParams.get('room');
    if (room) {
      const selectedRoom = this.location['rooms'].find(r => r['UUID'] == room);
      if (selectedRoom) {
        this.roomControl.setValue(selectedRoom);
      }
    }
  }
}
