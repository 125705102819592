<mat-form-field>
    <mat-label>Venue wechseln</mat-label>
    <mat-select [(ngModel)]="locationService.currentSelection">
        <!--  (selectionChange)="triggerLocationChange()"-->
        <mat-optgroup *ngFor="let l of locations;" [label]="l.name">
            <mat-option *ngFor="let r of l.rooms;let i = index;" [value]="{'location': l, 'room': i}">
                {{ r.name }}
            </mat-option>
        </mat-optgroup>
    </mat-select>
</mat-form-field>