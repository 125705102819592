<div class="dialog-container">
    <div mat-dialog-title>
        <h1>Information</h1>
    </div>
    <div mat-dialog-content>
        {{ msg }}
    </div>
    <div mat-dialog-actions class="flex-right">
        <button mat-button mat-dialog-close>OK</button>
    </div>

</div>