import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { LoginComponent } from '../dialogs/login/login.component';
import { RegistrationComponent } from '../dialogs/registration/registration.component';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})
export class LandingComponent implements OnInit, AfterViewInit {
  @ViewChild('video') video: ElementRef;

  constructor() { }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    // this.video.nativeElement.muted = "muted";
  }


  scroll(id: string) {
    document.getElementById(id).scrollIntoView({behavior: "smooth"});
  }

}
