import { Component, OnInit } from '@angular/core';
import { LocationService } from 'src/app/services/location.service';

@Component({
  selector: 'app-mailbox',
  templateUrl: './mailbox.component.html',
  styleUrls: ['./mailbox.component.scss']
})
export class MailboxComponent implements OnInit {

  requests = [
    {
      date: '23.11.2021',
      name: 'Tour Fantastische Einz',
      type: 'Konzert',
      duration: { begin: 18, end: 22},
      requester: 'Franz Frieden'
    },
    {
      date: '20.10.2021',
      name: 'Tour Fantastische Zwei',
      type: 'Konzert',
      duration: { begin: 10, end: 14},
      requester: 'Franz Freiheit'
    },
    {
      date: '15.05.2020',
      name: 'Tour Fantastische Drei',
      type: 'Konzert',
      duration: { begin: 15, end: 18},
      requester: 'Franz Freiden'
    },
    {
      date: '05.07.2020',
      name: 'Tour Fantastische Vier',
      type: 'Konzert',
      duration: { begin: 18, end: 22},
      requester: 'Franz Freiheit'
    }
  ];
  
  constructor() { }

  panelOpenState = false;

  ngOnInit(): void {
  }
}
