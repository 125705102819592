import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { first } from 'rxjs/operators';
import { NotificationService } from 'src/app/services/notification.service';
import { UserService } from 'src/app/services/user.service';
import { notificationTypes } from 'src/app/utils/constants/notificationTypes';
import {
  getEventlerTxt,
  getLocationOwnerTxtRequest,
} from 'src/app/utils/helpers';
import { concertTypes } from 'src/app/utils/constants/concertTypes';
import { requestSlotTypes } from 'src/app/utils/constants/requestSlotTypes';
import { eventSlotStatus } from 'src/app/utils/constants/eventSlotStatus';
import { bookingRequest } from 'src/app/types/bookingRequest.type';
import { KeyValueService } from 'src/app/services/key-value.service';

const UUID = new Date().getTime();

@Component({
  selector: 'app-book-location',
  templateUrl: './book-location.component.html',
  styleUrls: ['./book-location.component.scss'],
})
export class BookLocationComponent implements OnInit {
  public loading = false;
  public disableForm = false;

  public location = {};
  public selectedPlace = 0;
  public selectedRoom: number = 0;
  public selectedDate = new Date();

  public bookingStatus = 'booking';
  public bookingRequest: bookingRequest = {
    UUID: undefined,
    message: '',
    category: '',
    status: undefined,
    user: undefined,
    description: '',
    website: '',
  };

  public eventSlots = [];
  public selectedSlot = 0;
  public concertTypes = concertTypes;
  public concertType = concertTypes[0] + '';

  private currentUser: {};

  constructor(
    private firestore: AngularFirestore,
    private datePipe: DatePipe,
    private notificationService: NotificationService,
    private userService: UserService,
    private store: KeyValueService
  ) { }

  async ngOnInit() {
    this.currentUser = await this.userService
      .getMyUser()
      .pipe(first())
      .toPromise();
    this.initBookingRequest();
    // console.log('Location is', this.location);
    console.log('eventSlots:', this.eventSlots);
    if (this.eventSlots.length == 0) {
      this.disableForm = true;
    }
  }

  public validateURL() {
    if (this.bookingRequest['website'].startsWith('www.')) {
      this.bookingRequest['website'] = 'https://' + this.bookingRequest['website'];
    }
  }

  private async initBookingRequest() {
    this.bookingRequest['message'] = `
Sehr geehrte Damen und Herren,

mit dieser Anfrage möchte ich gerne ihren Veranstaltungsort für den angegebenen Zeitpunkt mieten.
Ich freue mich über eine positive Rückmeldung auf meine Anfrage.


Viele Grüße

${this.currentUser['firstName']} ${this.currentUser['lastName']}
`;

    this.bookingRequest['description'] = this.store.get('tourDescription');
  }

  async requestEvent() {
    let slot = this.getChoosenSlot();
    let bookingRequest = this.getBookingRequest();
    slot['requests'].push(bookingRequest);
    this.loading = true;
    this.sendNotifications(slot);
    this.updateEventSlot(slot);
    this.store.set('tourDescription', this.bookingRequest['description']);
  }

  private getChoosenSlot(): {} {
    let slot = this.eventSlots[this.selectedSlot];
    console.log('!slot.request', !slot.requests);
    if (!slot.requests) {
      slot.requests = [];
    }
    slot['status'] = 'Reserviert';
    return slot;
  }

  private getBookingRequest() {
    this.bookingRequest['user'] = this.currentUser['customIdName'];
    this.bookingRequest['category'] = this.concertType;
    this.bookingRequest['UUID'] = UUID;
    this.bookingRequest['status'] = eventSlotStatus.reserved;
    return this.bookingRequest;
  }

  private getPayload(slot: {}) {
    return {
      locationId: this.location['customIdName'],
      roomUUID: slot['roomUUID'],
      slotId: slot['customIdName'],
      requestUUID: UUID,
      type: requestSlotTypes.singleSlot,
    };
  }

  private async sendNotifications(slot: {}) {
    const payload = this.getPayload(slot);
    const date = this.datePipe.transform(slot['startSlot'], 'short');
    const msg = getLocationOwnerTxtRequest(
      this.concertType,
      this.location['name'],
      date
    );
    this.notificationService.sendLocationOwnersNotification(
      this.location,
      payload,
      notificationTypes.requestSlot,
      msg
    );
    // Notification to Eventler / Current User
    let eventlerMsg = getEventlerTxt(this.location['name'], date);
    await this.notificationService.createNotification(
      eventlerMsg,
      notificationTypes.bookingStarted,
      payload
    );
  }

  private updateEventSlot(slot: {}) {
    console.log('Updating event slot', slot);
    this.firestore
      .collection('event-slots')
      .doc(slot['customIdName'])
      .update(slot)
      .then(() => {
        this.loading = false;
        this.bookingStatus = 'success';
      })
      .catch((error) => {
        console.error('error');
        this.loading = false;
        this.bookingStatus = 'error';
      });
  }
}
