import {
  AfterViewInit,
  Component,
  HostListener,
  OnInit,
  ViewChild,
  ChangeDetectorRef,
} from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { MatDialog } from '@angular/material/dialog';
import { MatDrawer } from '@angular/material/sidenav';
import { NavigationEnd, Router } from '@angular/router';
import { filter, first } from 'rxjs/operators';
import { LoginComponent } from './components/dialogs/login/login.component';
import { RegistrationComponent } from './components/dialogs/registration/registration.component';
import { WelcomeMessageComponent } from './components/dialogs/welcome-message/welcome-message.component';
import { LocationService } from './services/location.service';
import { NotificationService } from './services/notification.service';
import { UserService } from './services/user.service';
import { DESCRIPTION_EVENTLER, DESCRIPTION_LOCATION } from './types/description.type';
import { isEmptyJSON } from './utils/helpers';

const MANDATORY_PAGES = ['/', '/imprint', '/data-protection', '/agb'];

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, AfterViewInit {

  DESCRIPTION_EVENTLER = DESCRIPTION_EVENTLER;
  DESCRIPTION_LOCATION = DESCRIPTION_LOCATION;

  currentRoute = '';
  menuOpened = false;
  user: any;
  sideNavMode = 'side';
  newRequests = [];
  userInfo = {};
  unreadNotifications = [];
  newMessages = 0;
  @ViewChild('drawer') drawer: MatDrawer;

  menuSelectorLocation = 230;

  constructor(
    private router: Router,
    private locationService: LocationService,
    private dialog: MatDialog,
    private notificationService: NotificationService,
    private auth: AngularFireAuth,
    private userService: UserService,
    private cdRef: ChangeDetectorRef
  ) { }

  async ngOnInit() {
    this.subscribeNotifications();

    this.userService.getMyUser().subscribe((userInfo) => {
      console.log('-------- Received update', userInfo);
      this.userInfo = userInfo;
      this.highlightCurrentRoute(this.currentRoute);
      this.showWelcomeMessage();

    });

    this.notificationService.getMyNotifications().subscribe((notifications) => {
      this.unreadNotifications = notifications.filter((n) => !n['read']);
      this.newMessages =
        this.unreadNotifications.length + this.newRequests.length;
    });
  }

  ngAfterViewInit() {
    localStorage.removeItem('noAlerts');

    this.resolveSidenavMode();

    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.highlightCurrentRoute(event.url);
        this.currentRoute = event.url;
        this.scrollToTop();

        console.log('Route change', this.menuSelectorLocation);

        this.resolveMenuOpenState();
      });

    this.auth.onAuthStateChanged((user) => {
      this.user = user;
      this.loggedinGuard();
    });
  }

  scrollToTop() {
    document.getElementsByTagName('mat-drawer-content')[0].scrollTo(0, 0);
  }

  showSadeyLogoInToolbar() {
    return this.isLegalRoute();
  }

  isLegalRoute() {
    return this.currentRoute.endsWith('/agb')
      || this.currentRoute.endsWith('/imprint')
      || this.currentRoute.endsWith('/data-protection');
  }

  isEventler() {
    return this.userInfo && this.userInfo['userType'] == 'eventler';
  }

  isAdmin() {
    return this.userInfo && this.userInfo['isAdmin'];
  }

  openRegistrationDialog(userType: 'string') {
    const dialog = this.dialog.open(RegistrationComponent);
    dialog.componentInstance.userType = userType;
    dialog.componentInstance.dialogRef.afterClosed().subscribe(() => {
      this.reloadUser();
    });
  }

  openLoginDialog() {
    let c = this.dialog.open(LoginComponent).componentInstance;
    c.dialogRef.afterClosed().subscribe(() => {
      this.reloadUser();
    });
  }

  reloadUser() {
    this.userService
      .getMyUser()
      .pipe(first())
      .subscribe((userInfo) => {
        this.userInfo = userInfo;

        this.locationService.loadLocations();
      });
  }

  logOut() {
    this.auth.signOut().then(() => {
      this.user = undefined;
      window.location.href = '/';
    });
  }

  loggedinGuard() {
    if (!MANDATORY_PAGES.includes(this.currentRoute) && !this.user) {
      this.router.navigate(['/']);
    }
  }

  @HostListener('window:resize', ['$event'])
  private resolveSidenavMode() {
    if (window.innerWidth > 850) {
      this.sideNavMode = 'side';
    } else {
      this.sideNavMode = 'over';
    }

    //resolve "Expression has changed after it was checked." error
    this.cdRef.detectChanges();

    this.resolveMenuOpenState();
  }

  private resolveMenuOpenState() {
    if (window.innerWidth > 850) {
      this.menuOpened = !MANDATORY_PAGES.includes(this.currentRoute);
    } else {
      this.menuOpened = false;
      this.drawer.close();
    }

    //resolve "Expression has changed after it was checked." error
    this.cdRef.detectChanges();
  }

  highlightCurrentRoute(url: string) {

    setTimeout(() => {
      const activeMenu = document.getElementsByClassName('mainColor')[0];
      if (activeMenu) {
        this.menuSelectorLocation = activeMenu.getBoundingClientRect().y + 17;
      }
    }, 100);

  }



  containsMeineLocation(url: string) {
    if (url === '/location-search') {
      return false;
    }
    let pattern1 = '/location/*';
    let pattern2 = '/location/edit/*';
    let result1 = url.match(pattern1);
    let result2 = url.match(pattern2);
    if (url === '/my-locations' || result1 || result2) {
      // this.menuSelectorLocation = 270;
      return true;
    }
    return false;
  }

  private async subscribeNotifications() {
    (await this.locationService.getLocationRequests('Reserviert'))
      .subscribe(
        (newRequests) => {
          this.newRequests = newRequests;
          this.newMessages =
            this.unreadNotifications.length + this.newRequests.length;
        }
      );
  }

  private showWelcomeMessage() {
    if (!this.userInfo['lastMessageAccepted'] && !isEmptyJSON(this.userInfo)) {
      console.log('Userinfo', this.userInfo);
      if (this.dialog.openDialogs.length == 0) {
        this.dialog.open(WelcomeMessageComponent);
      }
    }
  }
}
