<div *ngIf="!eventSlot; else tourslotContainer">
    Termin nicht vorhanden
</div>

<ng-template #tourslotContainer>
    <div class="selectedEventSlot" (click)="toggle()">
        <div class="date">
            {{ eventSlot['startSlot'].toDate() | date:'dd.MM' }}
        </div>
        <div class="location-name">
            <div clss="flex-center-v" style="display: flex;">
                <div>

                    <app-location-name [id]="eventSlot['locationId']"></app-location-name> ({{ location['city'] }})<br>
                    <span>Status: {{ status }}</span>
                </div>
            </div>
            <div class="collapsed" [class.expanded]="opened">
                {{ location['street'] }} {{ location['houseNumber'] }}, {{
                location['zipCode'] }}
                <br> Kontakt:
                <br> Name: {{ location['responsiblePerson'] }}
                <br> Tel.: {{ location['responsiblePersonTelefon'] }}
                <br> eMail: {{ location['responsiblePersonEmail'] }}
                <br>
            </div>
        </div>
        <div class="expand">
            <div class="icons">
                <a routerLink="/location/{{location['customIdName']}}">
                    <mat-icon style="font-size: 12px;display: flex; justify-content: center; align-items: center;"
                        matTooltip="Öffnen" matTooltipPosition="above">launch</mat-icon>
                </a>

                <mat-icon color="primary" class="pointer" [inline]="true">add</mat-icon>
            </div>
        </div>
    </div>
</ng-template>