import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { locationExamination } from 'src/app/types/locationExamination.type';
import { locationStatus } from 'src/app/utils/constants/locationStatus';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AngularFireAuth } from '@angular/fire/auth';
import { first } from 'rxjs/operators';
import {
  isBookableEventsExistent,
  hasEventSlots,
  isLocationImgSrcsExistent,
  hasLocationImg,
  isLocationContractExistent,
  hasLocationContract,
} from 'src/app/utils/helpers';

@Component({
  selector: 'app-examine-location',
  templateUrl: './examine-location.component.html',
  styleUrls: ['./examine-location.component.scss'],
})
export class ExamineLocationComponent implements OnInit {
  /*   public locations: any[] = [];
  public locationsHolder: locationsHolder[] = [];
  public locationsHolderObservable: Observable<
    locationsHolder[]
  > = new BehaviorSubject(this.locationsHolder); */

  filteredLocatios: any[] = [];

  constructor(
    private firestore: AngularFirestore,
    private authService: AngularFireAuth,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    //this.getLocations();
    this.subscribeLocations();
  }

  async subscribeLocations() {
    let myUser = await this.authService.user.pipe(first()).toPromise();

    this.firestore
      .collection('locations')
      .valueChanges()
      .subscribe((allLocations) => {
        this.filteredLocatios = [];
        this.filterLocations(allLocations);
        // Das bräuchte man, wenn man noch nach usern überprüfen will
        // this.filteredLocatios = allLocations.filter(location => {
        //   return location['users'].find(u => u['uid'] == myUser['uid']);
        // });
      });
  }

  /*   private getLocations() {
    this.firestore
      .collection('locations', (ref) =>
        ref.where('status', '==', 'Wird überprüft')
      )
      .valueChanges({ idField: 'customIdName' })
      .subscribe((element) => {
        console.log('getLocation', element);

        this.locations = element;
        this.chooseExaminableLocations();
      });
  }

  private chooseExaminableLocations() {
    let tmp_array: Array<any> = [];
    this.locations.forEach((location) => {
      if (
        this.isPending(location) &&
        this.isContractExisting(location) &&
        this.isPictureExisting(location) &&
        this.isTimeSlotExisting(location)
      ) {
        tmp_array.push(location);
      }
    });
    this.loadIntoLocations(tmp_array);
    this.loadResponsibleUsers(this.locations);
  }

  private loadIntoLocations(tmp_array: any[]): void {
    this.locations = [];
    tmp_array.forEach((location) => {
      this.locations.push(location);
    });
  }

  private loadResponsibleUsers(locations: any[]) {
    let locationsOwner: locationOwner[] = [];
    this.getLocationOwners(locations, locationsOwner);
    this.loadLocationsOwnersDetails(locationsOwner);
  }

  private loadLocationsOwnersDetails(locationsOwner: locationOwner[]) {
    locationsOwner.forEach((locationOwner: locationOwner) => {
      this.firestore
        .collection('users')
        .doc(locationOwner.locationOwnerId)
        .valueChanges({ idField: 'uid' })
        .subscribe((user) => {
          if (!this.isLocationInArray(locationOwner)) {
            this.locationsHolder.push({
              location: this.getLocationById(locationOwner.locationId),
              owner: user,
            });
          }
        });
    });
  }

  private isLocationInArray(newLocationOwner: locationOwner) {
    for (let i = 0; i < this.locationsHolder.length; i++) {
      const locationHolder: locationsHolder = this.locationsHolder[i];
      if (
        locationHolder.location.customIdName === newLocationOwner.locationId
      ) {
        return true;
      }
    }
    return false;
  }

  private getLocationOwners(locations: any, locationsOwner: any) {
    locations.forEach((location: any) => {
      for (let i = 0; i < location.users.length; i++) {
        const user: any = location.users[i];
        if (user.role === 'Owner') {
          locationsOwner.push({
            locationId: location.customIdName,
            locationOwnerId: user.uid,
          });
        }
      }
    });
  }

  private getLocationById(locationId): any {
    for (let i = 0; i < this.locations.length; i++) {
      const location = this.locations[i];
      if (location.customIdName === locationId) {
        return location;
      }
    }
    console.error('Venue was not found');
  } */

  public onExaminationResult(event: locationExamination) {
    this.addExaminationResul(event);
    this.uploadExaminationResult(event);
  }

  private uploadExaminationResult(event: locationExamination) {
    if (event.result) {
      this.updateLocation(
        event.location.customIdName,
        event.location,
        'Venue gespeichert als Freigegeben!'
      );
      return;
    }
    this.updateLocation(
      event.location.customIdName,
      event.location,
      'Venue gespeichert als Ablehnen!'
    );
  }

  private addExaminationResul(event: locationExamination) {
    if (event.result) {
      event.location.status = locationStatus.approved;
      return;
    }
    event.location.status = locationStatus.declined;
  }

  private updateLocation(
    locationId: string,
    location: Object,
    notification?: string
  ) {
    this.firestore
      .collection('locations')
      .doc(locationId)
      .update(location)
      .then(() => {
        if (notification) {
          this.snackBar.open(notification, null, {
            duration: 3000,
          });
        }
      });
  }

  private filterLocations(allLocations: any) {
    for (let i = 0; i < allLocations.length; i++) {
      const location: any = allLocations[i];
      if (this.isValid(location)) {
        this.filteredLocatios.push(location);
      }
    }
  }

  private isValid(l: any) {
    return l['status'] == locationStatus.pending;
    // return (
    //   this.isPending(l) &&
    //   this.isContractExisting(l) &&
    //   this.isPictureExisting(l) &&
    //   this.isTimeSlotExisting(l)
    // );
  }

  private isPending(l: any): boolean {
    return l['status'] === locationStatus.pending;
  }

  private isContractExisting(l: any): boolean {
    return isLocationContractExistent(l) && hasLocationContract(l);
  }

  private isPictureExisting(l: any): boolean {
    return isLocationImgSrcsExistent(l) && hasLocationImg(l);
  }

  private isTimeSlotExisting(l: any): boolean {
    return isBookableEventsExistent(l) && hasEventSlots(l);
  }
}
