import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.scss'],
})
export class DetailsComponent implements OnInit {
  constructor() {}

  @Input() location: any;
  @Input() selectedRoom = 0;

  /*  Elements for Navigation    */
  @ViewChild('capacity')
  private capacityNavigation: ElementRef<HTMLSpanElement>;
  @ViewChild('stage')
  private stageNavigation: ElementRef<HTMLSpanElement>;
  @ViewChild('technology')
  private technologyNavigation: ElementRef<HTMLSpanElement>;
  @ViewChild('personell')
  private personellNavigation: ElementRef<HTMLSpanElement>;
  @ViewChild('driveTo')
  private driveToNavigation: ElementRef<HTMLSpanElement>;

  /*   Tabs for detail information   */
  @ViewChild('capacityDetails')
  private capacityDetails: ElementRef<HTMLDivElement>;
  @ViewChild('stageDetails')
  private stageDetails: ElementRef<HTMLDivElement>;
  @ViewChild('technologyDetails')
  private technologyDetails: ElementRef<HTMLDivElement>;
  @ViewChild('personellDetails')
  private personellDetails: ElementRef<HTMLDivElement>;
  @ViewChild('driveToDetails')
  private driveToDetails: ElementRef<HTMLDivElement>;

  @ViewChild('description')
  private elForScrollTop: ElementRef<HTMLHeadingElement>;

  ngOnInit(): void {}

  ngAfterViewInit() {}

  showDetailInfo(className: string) {
    switch (className) {
      case 'capacityDetails':
        this.showCapacity();
        break;

      case 'stageDetails':
        this.showStage();
        break;

      case 'technologyDetails':
        this.showTechnology();
        break;

      case 'personellDetails':
        this.showPersonell();
        break;

      case 'driveTo':
        this.showDriveTo();
        break;

      default:
        break;
    }
  }

  private showCapacity() {
    this.hideStage();
    this.hideTechnology();
    this.hidePersonell();
    this.hideDriveTo();
    this.setNavElementActive(this.capacityNavigation.nativeElement);
    this.capacityDetails.nativeElement.style.visibility = 'visible';
    this.srollToTop();
  }

  private showStage() {
    this.hideCapacity();
    this.hideTechnology();
    this.hidePersonell();
    this.hideDriveTo();
    this.setNavElementActive(this.stageNavigation.nativeElement);
    this.stageDetails.nativeElement.style.visibility = 'visible';
    this.srollToTop();
  }

  private showTechnology() {
    this.hideCapacity();
    this.hideStage();
    this.hidePersonell();
    this.hideDriveTo();
    this.setNavElementActive(this.technologyNavigation.nativeElement);
    this.technologyDetails.nativeElement.style.visibility = 'visible';
    this.srollToTop();
  }

  private showPersonell() {
    this.hideCapacity();
    this.hideStage();
    this.hideTechnology();
    this.hideDriveTo();
    this.setNavElementActive(this.personellNavigation.nativeElement);
    this.personellDetails.nativeElement.style.visibility = 'visible';
    this.srollToTop();
  }

  private showDriveTo() {
    this.hideCapacity();
    this.hideStage();
    this.hideTechnology();
    this.hidePersonell();
    this.setNavElementActive(this.driveToNavigation.nativeElement);
    this.driveToDetails.nativeElement.style.visibility = 'visible';
    this.srollToTop();
  }

  private hideStage() {
    this.setNavElementInactive(this.stageNavigation.nativeElement);
    this.stageDetails.nativeElement.style.visibility = 'hidden';
  }

  private hideCapacity() {
    this.setNavElementInactive(this.capacityNavigation.nativeElement);
    this.capacityDetails.nativeElement.style.visibility = 'hidden';
  }

  private hideTechnology() {
    this.setNavElementInactive(this.technologyNavigation.nativeElement);
    this.technologyDetails.nativeElement.style.visibility = 'hidden';
  }

  private hidePersonell() {
    this.setNavElementInactive(this.personellNavigation.nativeElement);
    this.personellDetails.nativeElement.style.visibility = 'hidden';
  }

  private hideDriveTo() {
    this.setNavElementInactive(this.driveToNavigation.nativeElement);
    this.driveToDetails.nativeElement.style.visibility = 'hidden';
  }

  private setNavElementActive(navElement: HTMLSpanElement): void {
    navElement.style.fontWeight = 'bold';
    navElement.style.opacity = '1';
  }

  private setNavElementInactive(navElement: HTMLSpanElement) {
    navElement.style.fontWeight = 'normal';
    navElement.style.opacity = '0.5';
  }

  private srollToTop() {
    let el = this.elForScrollTop.nativeElement;
    el.parentElement.parentElement.parentElement.scrollTop = 0;
  }
}
