<div class="detailsContainer">
    <div class="detailsLabelContainer">
        <div class="labelLine"></div>
        <div class="LabelText">Technische Daten</div>
    </div>
    <div *ngIf="location" class="detailInformationContainer">
        <div id="NavigationContainer" #NavigationContainer class="detailsNavigationContainer">
            <span #capacity (click)="showDetailInfo('capacityDetails')" id="capacity">Kapazitäten</span><span class="divider">|</span><span #stage (click)="showDetailInfo('stageDetails')" id="stage">Bühne</span><span class="divider">|</span><span #technology
                (click)="showDetailInfo('technologyDetails')" id="technology">Technik</span><span class="divider">|</span><span #personell (click)="showDetailInfo('personellDetails')" id="personell">Personal</span>
                
                <span class="divider hidden">|</span>
                <span #driveTo (click)="showDetailInfo('driveTo')" class="hidden" id="driveTo">Anfahrt</span>
        </div>
        <div class="detailContentContainer">
            <div class="detailContentContainerAdjusted">
                <!--  ########## CAPACITY   Beginn ###########  -->
                <div #capacityDetails class="capacityDetails" id="capacityDetails">
                    <table>
                        <tr>
                            <td>Venue Fläche:</td>
                            <td>{{ location.rooms[0].space }} qm</td>
                        </tr>
                        <tr>
                            <td>Bühne Fläche:</td>
                            <td>{{ location.rooms[0].stageSpace }} qm</td>
                        </tr>
                        <tr>
                            <td>Sitzplätze:</td>
                            <td>{{ location.rooms[0].seats }}</td>
                        </tr>
                        <tr>
                            <td>Stehplätze:</td>
                            <td>{{ location.rooms[0].standingrooms }}</td>
                        </tr>
                        <tr>
                            <td>Bestuhlungsvarianten:</td>
                            <td>
                                {{ location.rooms[0].seatingVariation }}
                            </td>
                        </tr>
                        <tr>
                            <td>Räume:</td>
                            <td>
                                {{ location.rooms[0].rooms }}
                            </td>
                        </tr>
                    </table>
                    <h3 #description>Beschreibung:</h3>
                    <p>
                        {{ location.description }}
                    </p>
                </div>
                <!--  ########## CAPACITY   END ##############  -->
                <!--  ########## STAGE   Beginn ##############  -->
                <div #stageDetails class="stageDetails" id="stageDetails">
                    <mat-list>
                        <mat-list-item>
                            <h6 matLine>Bühnengewichte:</h6>
                            <span matLine>zulässiges Gesamtgewicht: {{ location.rooms[0].maxWeight }}</span>
                            <span matLine>Leergewicht (einschließlich Zubehör):</span>
                            <span matLine>&#60; {{ location.rooms[0].emptyWeight }}</span>
                        </mat-list-item>
                        <mat-list-item>
                            <h6 matLine>Abmaße als Anhänger:</h6>
                            <span matLine>Länge: {{ location.rooms[0].sideTrailerDepth }} m</span>
                            <span matLine>Breite: {{ location.rooms[0].sideTrailerWidth }} m</span>
                            <span matLine>Höhe: {{ location.rooms[0].sideTrailerHeight }} m</span>
                        </mat-list-item>
                        <mat-list-item>
                            <h6 matLine>Abmaße Seitenbühne:</h6>
                            <span matLine>Breite: {{ location.rooms[0].sideStageWidth }} m</span>
                            <span matLine>Tiefe: {{ location.rooms[0].sideStageDepth }} m</span>
                            <span matLine>Höhe: {{ location.rooms[0].sideStageHeight }} m</span>
                            <span matLine>Fläche: {{ location.rooms[0].sideStageSize }}</span>
                        </mat-list-item>
                        <mat-list-item>
                            <h6 matLine>Abmaße Hinterbühne:</h6>
                            <span matLine>Breite: {{ location.rooms[0].backstageWidth }} m</span>
                            <span matLine>Tiefe: {{ location.rooms[0].backstageDepth }} m</span>
                            <span matLine>Höhe: {{ location.rooms[0].backstageHeight }} m</span>
                            <span matLine>Fläche: {{ location.rooms[0].backstageSize }}</span>
                        </mat-list-item>
                        <mat-list-item>
                            <h6 matLine>Maße Hauptbühne:</h6>
                            <span matLine>Breite: {{ location.rooms[0].mainStageWidth }} m</span>
                            <span matLine>Tiefe: {{ location.rooms[0].mainStageDepth }} m</span>
                            <span matLine>Höhe: {{ location.rooms[0].mainStageHeight }} m</span>
                            <span matLine>Fläche: {{ location.rooms[0].stageSpace }} m</span>
                        </mat-list-item>
                        <mat-list-item>
                            <h6 matLine>Bühnenmeister: {{ location.rooms[0].responsiblePerson }}</h6>
                            <span matLine>Telefonnummer: {{ location.rooms[0].responsiblePersonEmail }}</span>
                            <span matLine>eMail: {{ location.rooms[0].responsiblePersonTelefon }}</span>
                        </mat-list-item>
                    </mat-list>
                </div>
                <!--  ########## STAGE   END #################  -->
                <!--  ########## TECHNOLOGY   Beginn #########  -->
                <div #technologyDetails class="technologyDetails" id="technologyDetails">
                    <mat-list>
                        <mat-list-item>
                            <h6 matLine>Bühnentechnik:</h6>
                            <span matLine>Hängepunkte: {{ location.rooms[0].haengepunkte }}</span>
                            <span matLine>Prospektzüge: {{ location.rooms[0].prospektzuege }}</span>
                            <span matLine>Bühnenlichter: {{ location.rooms[0].light }}</span>
                            <span matLine>Höhe der Lichter: {{ location.rooms[0].lightHeight }}</span>
                            <span matLine>Stromanschluss: {{ location.rooms[0].powerSupply }}</span>
                            <span matLine>Mischpulte: {{ location.rooms[0].mixer }}</span>
                            <span matLine>Spezialeffekte: {{ location.rooms[0].specialEffects }}</span>
                        </mat-list-item>
                        <mat-list-item>
                            <h6 matLine>Aufbauzeit:</h6>
                            <span matLine>{{ location.rooms[0].buildTime }}</span>
                        </mat-list-item>
                    </mat-list>
                </div>
                <!--  ########## TECHNOLOGY   End ############  -->
                <!--  ########## PERSONELL   Beginn ##########  -->
                <div #personellDetails class="personellDetails" id="personellDetails">
                    <mat-list>
                        <mat-list-item>
                            <h6 matLine>Eventpersonal:</h6>
                            <span matLine>Einlasspersonal: {{ location.rooms[0].einlasspersonal }}</span>
                            <span matLine>Ordnerpersonal: {{ location.rooms[0].ordnerpersonal }}</span>
                        </mat-list-item>
                        <mat-list-item>
                            <h6 matLine>Zusätzliches:</h6>
                            <span matLine>Garderobe: {{ location.rooms[0].cloakroom }}</span>
                            <span matLine>Catering: {{ location.rooms[0].catering }}</span>
                            <span matLine>Orchestra: {{ location.rooms[0].orchestra }}</span>
                        </mat-list-item>
                    </mat-list>
                </div>
                <!--  ########## PERSONELL   End #############  -->
                <!--  ########## Anfahrt   Beginn ############  -->
                <div #driveToDetails class="driveToDetails" id="driveToDetails">
                    <iframe *ngIf="location.rooms[0].street && location.rooms[0].houseNumber && location.rooms[0].city" class="GoogleMaps" frameborder="0" style="border: 0" [src]="
            location
              | generateMapsUrl
                : location.rooms[0].street
                : location.rooms[0].houseNumber
                : location.rooms[0].city
          " allowfullscreen>
          </iframe>
                </div>
                <!--  ########## Anfahrt   End ###############  -->
            </div>

        </div>
    </div>
</div>