<div class="dialog-container">
    <mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>

    <h1 mat-dialog-title>Anfrage annehmen</h1>
    <div mat-dialog-content>
        <p>Bitte wähle eine Vertragsvorlage aus.</p>


        <div *ngIf="location['contractTemplateSrcs'].length == 0; else selectContractSection">
            Du kannst diese Anfrage erst annehmen, wenn du in der Sektion "Verträge" mindestens einen Vertrag hochgeladen hast.
        </div>


        <ng-template #selectContractSection>

            <mat-radio-group *ngIf="location['contractTemplateSrcs']" class="example-radio-group" [(ngModel)]="selectedContract">
                <mat-radio-button class="example-radio-button" *ngFor="let url of location['contractTemplateSrcs']; let i = index;" [value]="i">
                    <span class="flex-center-v">{{ contractService.getFileName(url) }}
            <a [href]="url" class="flex-center-v" target="_blank" matTooltip="Öffnen" matTooltipPosition="above">
              <mat-icon>launch</mat-icon>
            </a>
          </span>
                </mat-radio-button>
            </mat-radio-group>


            <mat-card class="info-card">
                <mat-icon>info</mat-icon>
                <div>
                    Die Annahme der Anfrage ist <b>verbindlich</b>. Dein Gegenüber muss den ausgewählten Vertrag noch unterschreiben.
                </div>
            </mat-card>
        </ng-template>
    </div>
    <div mat-dialog-actions class="flex-right">
        <button [disabled]="loading" mat-button mat-dialog-close cdkFocusInitial>Abbrechen</button>
        <button [disabled]="loading" mat-button (click)="acceptRequest()">Anfrage annehmen</button>
    </div>
</div>