<div class="description-container">
    <!-- <h2>So funktioniert's</h2> -->

    <p class="after-headline">
        <!-- Sadey bringt das planen von Veranstaltungen ins 21ste Jahrhundert. -->
        How to get your perfect Match:
    </p>

    <!-- <p class="tutorial-description">
        Das buchen von Veranstaltungen war noch nie so einfach. Durch das hochmoderne Buchungssystem von Sadey kannst du problemlos und in wenigen Minuten alle relevanten Informationen zu deiner Tour recherchieren und direkt den Verantstaltungsort buchen.
    </p> -->
</div>

<div class="divi-tutorial-container">
    <div class="column-container">
        <div class="column-description" [matTooltip]="DESCRIPTION_LOCATION">Venue</div>

        <app-divi-btn (click)="setVideo(0)">
            Locations verwalten
        </app-divi-btn>
        <app-divi-btn (click)="setVideo(1)">
            Bilder hochladen
        </app-divi-btn>
        <app-divi-btn (click)="setVideo(2)">
            Termine verwalten
        </app-divi-btn>

        <app-divi-btn (click)="setVideo(5)" class="hide-desktop">
            Detailierte Informationen
        </app-divi-btn>

    </div>

    <div class="column-container">

        <video #videoelements [class.hidden]="currentVideo != i" autoplay muted loop plazsinline repeat *ngFor="let v of videos; let i = index;">
      <source [src]="ROOT_FOLDER + v">
    </video>
    </div>

    <div class="column-container">
        <div class="column-description" [matTooltip]="DESCRIPTION_EVENTLER">Eventler</div>
        <app-divi-btn (click)="setVideo(3)">
            Tour planen
        </app-divi-btn>
        <app-divi-btn (click)="setVideo(4)">
            Venues suchen
        </app-divi-btn>
        <app-divi-btn (click)="setVideo(5)" class="hide-mobile">
            Detailierte Informationen
        </app-divi-btn>
    </div>


</div>