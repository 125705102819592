<button (click)="dialogRef.close()" mat-button>
    <mat-icon>close</mat-icon>
</button>

<ngx-auth-firebaseui-login logoUrl='./assets/img/favicon.png' loginButtonText="Einloggen" appearance="legacy"
    providers="AuthProvider.none" (onSuccess)="loginSuccessful($event)" (onResetPasswordRequested)="resetPassword()"
    [titleText]="'titleText' | translate" [loginButtonText]="'loginButtonText' | translate"
    [forgotPasswordText]="'forgotPasswordText' | translate" [emailText]="'emailText' | translate"
    [emailErrorRequiredText]="'emailErrorRequiredText' | translate"
    [emailErrorPatternText]="'emailErrorPatternText' | translate" [passwordText]="'passwordText' | translate"
    [passwordErrorRequiredText]="'passwordErrorRequiredText' | translate">
</ngx-auth-firebaseui-login>

<div *ngIf="needsVerification">
    <span>{{'emailVerificationNeededText' | translate}}</span>
</div>