import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { first } from 'rxjs/operators';
import { FileUploadService } from 'src/app/services/file-upload.service';
import { LocationService } from 'src/app/services/location.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-media',
  templateUrl: './media.component.html',
  styleUrls: ['./media.component.scss'],
})
export class MediaComponent implements OnInit {
  MAX_IMG_UPLOAD = 7;
  MIN_IMG_UPLOAD = 1;
  MAX_IMG_SIZE = 5000000; //BYTES
  IMG_TYPES = /\.(jfif|jpg|jpeg|png|gif|JFFIF|JPG|JPEG|PNG|GIF|webp|pdf|PDF)$/;
  locationId: string;
  location: any;
  userId: string;
  user: any;
  url = new Subject<string>();
  url$ = this.url.asObservable();
  myLocations;

  constructor(
    private storage: AngularFireStorage,
    private firestore: AngularFirestore,
    private fileUpload: FileUploadService,
    private userService: UserService,
    public locationService: LocationService
  ) { }

  ngOnInit() {
    this.locationService.loadLocations();

    this.configFileUpload();

    this.url.subscribe(async (url) => {
      console.log('New URL', url);
      await this.updateLocationImgSrcs(url);
    });

    this.loadMyLocations();
  }

  async loadMyLocations(){
    setTimeout(async () => {
      this.myLocations = await this.locationService.getMyLocationsOnce()

    }, 1000);
  }

  getFileName(url) {
    let start = url.indexOf('undefined');
    let end = url.indexOf('?alt=');
    return url.substring(start + 22, end);
  }



  async updateLocationImgSrcs(url) {
    let location = this.locationService.currentLocation();
    let roomIndex = this.locationService.currentSelection['room'];

    if (!location['rooms'][roomIndex]['images']) {
      location['rooms'][roomIndex]['images'] = [];
    }

    if (!location['rooms'][roomIndex]['downloads']) {
      location['rooms'][roomIndex]['downloads'] = [];
    }

    if (['.pdf?', '.PDF?'].some(v => url.includes(v))) { // Put to download
      location['rooms'][roomIndex]['downloads'].push(url);
    } else {
      location['rooms'][roomIndex]['images'].push(url);
    }

    await this.firestore
      .collection('locations')
      .doc(location['customIdName'])
      .update(location);

    // await this.firestore
    //   .collection('locations')
    //   .doc(this.location.customIdName)
    //   .update({ imgSrcs: firebase.firestore.FieldValue.arrayUnion(url) });
  }

  async removeFile(url: any) {
    this.storage.refFromURL(url).delete();
    let location = this.locationService.currentLocation();
    let roomIndex = this.locationService.currentSelection['room'];

    location['rooms'][roomIndex]['images'] = location['rooms'][roomIndex]['images'].filter(u => u !== url);

    await this.firestore
      .collection('locations')
      .doc(location['customIdName'])
      .update(location);
  }

  hasMinImgs() {
    return this.location?.imgSrcs.length == this.MIN_IMG_UPLOAD;
  }

  activateAccount() {
    this.firestore.collection('users').doc(this.userId).set(
      {
        accountUnlocked: true,
      },
      { merge: true }
    );
  }

  async handleImgInput(files: FileList) {
    const userId = await this.userService.getMyId().pipe(first()).toPromise();
    this.fileUpload.fileToUpload.next({ file: files[0], userId });
  }

  private configFileUpload() {
    this.fileUpload.maxSize = this.MAX_IMG_SIZE;
    this.fileUpload.fileAcceptedTypes = this.IMG_TYPES;
    this.fileUpload.url = this.url;
  }
}
