<!-- <h1>Anfragen</h1>

<div *ngFor="let request of requests" class="request-container">
    <div class="request">
        <div class="details">
            {{request.date}} {{request.requester}}, {{request.name}}
        </div>
    </div>
    <button mat-icon-button>
        <mat-icon class="main-color">add</mat-icon>
        <mat-icon class="main-color">remove</mat-icon>
    </button>
    <button mat-button>Vertragsvorlage Wählen</button>
</div> -->

<app-show-requests [showNotifications]="true"></app-show-requests>

<!--
<mat-list>
    <div *ngFor="let request of requests" class="margin-top-16">
        <div class="request-container">
            <mat-accordion class="margin-right-32">
                <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                    <mat-expansion-panel-header>
                        <span class="margin-right-32">{{request.date}}</span> {{request.requester}}, {{request.name}}
                        <!-- <mat-panel-description>
                      Currently I am {{panelOpenState ? 'open' : 'closed'}}
                    </mat-panel-description> -->
<!--
                    </mat-expansion-panel-header>
                    <p>{{request.type}}, {{request.duration.begin}} - {{request.duration.end}} Uhr</p>
                </mat-expansion-panel>
            </mat-accordion>
            <button mat-raised-button>Vertragsvorlage Wählen</button>
        </div>
        <button mat-icon-button>
            <mat-icon class="color-main">checked</mat-icon>
        </button>
        <button  mat-icon-button>
            <mat-icon class="color-warm">clear</mat-icon>
        </button>

        <mat-divider></mat-divider>
    </div>
</mat-list>

-->