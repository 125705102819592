import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { first } from 'rxjs/operators';
import { LocationService } from 'src/app/services/location.service';

@Component({
  selector: 'app-room-picker',
  templateUrl: './room-picker.component.html',
  styleUrls: ['./room-picker.component.scss']
})
export class RoomPickerComponent implements OnInit {

  locations = [];
  roomControl = new FormControl();
  @Output() selectedRoomEvent = new EventEmitter<any>();

  @ViewChild('formField') formField;
  currentSelection = {};
  constructor(
    public locationService: LocationService
  ) {
  }

  ngOnInit() {
    this.setMyLocations()
      .then(() => {
        this.roomControl.valueChanges.subscribe(room => {
          console.log('Room changed to', room);
          this.selectedRoomEvent.emit(room);
        });
        this.roomControl.setValue(this.locations[0].rooms[0]);
      });

   
    // let locationsObservable = await this.locationService.getMyLocations();
    // locationsObservable.subscribe((locations) => {
    //   this.locations = locations;

    //   // Set default location if no location has been selected
    //   if (!this.locationService.currentSelection) {
    //     this.locationService.updateCurrentSelection({'location': locations[0], 'room': 0});
    //   }

    //   // Update Current Location when changes occur
    //   for (let i = 0; i < this.locations.length; i++) {
    //     const location = this.locations[i];
    //     if (location.customIdName === this.locationService.currentSelection['location'].customIdName
    //     ) {
    //       this.locationService.updateCurrentSelection(location);
    //     }
    //   }
    //   // console.log('Field', this.formField);
    // });

  }

  private async setMyLocations() {
    let locationsObservable = await this.locationService.getMyLocations();
    this.locations = await locationsObservable.pipe(first()).toPromise();
  }

  public triggerLocationChange() {
    console.log('Update currentSelection to', this.currentSelection)
    // let currentSelection = this.locationService.currentSelection;
    // this.locationService.updateCurrentSelection(currentSelection);
  }

}