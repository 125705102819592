<div class="locationContainer">

    <div class="space-between">
        <h1 *ngIf="location" class="headerContainer">
            {{ location.name }}
        </h1>
        <app-location-room-picker [locationId]="locationId" (selectedRoomEvent)="switchToRoom($event)">
        </app-location-room-picker>
    </div>

    <app-image-slider [selectedRoom]="selectedRoom" [location]="location"></app-image-slider>
    <app-detail-information [selectedRoom]="selectedRoom" [location]="location"></app-detail-information>
</div>