<div>
    <div class="headerContainer">
        <h1>Eventslot</h1>
        <mat-icon (click)="onNoClick()" class="clickable">close</mat-icon>
    </div>

    <form [formGroup]="formGroup" (ngSubmit)="onSubmit()" class="flex-column">
        <mat-form-field>
            <input id="start" matInput [ngxMatDatetimePicker]="dateTimePickerStart" placeholder="Wähle Startzeitpunkt" formControlName="startDate" [min]="minStartDate" [max]="end">
            <mat-datepicker-toggle matSuffix [for]="dateTimePickerStart"></mat-datepicker-toggle>
            <ngx-mat-datetime-picker [enableMeridian]="false" [disableMinute]="false" #dateTimePickerStart>
            </ngx-mat-datetime-picker>
        </mat-form-field>

        <mat-form-field>
            <input id="end" (focus)="dateTimePickerEnd.open()" matInput [ngxMatDatetimePicker]="dateTimePickerEnd" placeholder="Wähle Endzeitpunkt" formControlName="endDate" [min]="today" [max]="end">
            <mat-datepicker-toggle matSuffix [for]="dateTimePickerEnd"></mat-datepicker-toggle>
            <ngx-mat-datetime-picker [enableMeridian]="false" [disableMinute]="false" #dateTimePickerEnd>
            </ngx-mat-datetime-picker>
        </mat-form-field>

        <mat-form-field>
            <mat-label>Buchungskosten</mat-label>
            <input id="bookingFee" formControlName="bookingFee" matInput placeholder="1000€">
        </mat-form-field>

        <div *ngIf="endDateBeforeStartDate()">
            Der Startzeitpunkt muss sich <b>vor</b> dem Endzeitpunkt befinden.
        </div>

        <div *ngIf="wrongDateFormat">
            Bitte wähle das Datum aus, indem du auf das Kalender-Symbol drückst.
        </div>

        <div class="flex-end">
            <button mat-raised-button type="submit" [disabled]="!formGroup.valid || endDateBeforeStartDate()" color="primary">Speichern</button>
        </div>
    </form>
</div>