import { Component, OnInit, Output } from '@angular/core';
import { EventEmitter } from 'events';
import { LocationService } from 'src/app/services/location.service';

@Component({
  selector: 'app-my-locations-switch',
  templateUrl: './my-locations-switch.component.html',
  styleUrls: ['./my-locations-switch.component.scss']
})
export class MyLocationsSwitchComponent implements OnInit {

  myLocations = [];
  public currentLocation;
  @Output() locationUpdate = new EventEmitter();
  currentLocationIndex = 0;

  constructor(private locationService: LocationService) { }

  ngOnInit(): void {
    this.loadMyLocations();
  }

  triggerLocationChange() {
    this.locationUpdate.emit(this.currentLocation);
  }

  private async loadMyLocations() {
    (await this.locationService.getMyLocations())
      .subscribe((myLocations) => {
          this.currentLocation = myLocations[this.currentLocationIndex];
        this.myLocations = myLocations;
        console.log('this.myLocations', this.myLocations);
      });

  }

}
