<div *ngIf="location && location['rooms']" class="imageSliderContainer scrollbar">
    <app-slideshow [images]="location['rooms'][selectedRoom]['images']" #slideshow></app-slideshow>

    <div *ngIf="location['rooms'][selectedRoom]['images']; then imgSrcExists else dummyImgs"></div>


    <ng-template #imgSrcExists>
        <div *ngIf="location['rooms'][selectedRoom]['images'].length; then imagesExists else dummyImgs"></div>
    </ng-template>
    <ng-template #imagesExists>
        <img *ngFor="let imgSrc of location['rooms'][selectedRoom]['images']; let i = index;" (click)="slideshow.open(i)" class="clickable-image sliderImg" src="{{ imgSrc }}" />
    </ng-template>
    <ng-template #dummyImgs>
        <img #dummyImgs *ngFor="let dummyImgSrc of dummyImages" class="sliderImg" src="{{ dummyImgSrc }}" />
    </ng-template>
</div>