import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { ActivatedRoute } from '@angular/router';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-public-profile',
  templateUrl: './public-profile.component.html',
  styleUrls: ['./public-profile.component.scss'],
})
export class PublicProfileComponent implements OnInit {
  user = {};

  constructor(
    private firestore: AngularFirestore,
    private route: ActivatedRoute,
    public userService: UserService
  ) {}

  ngOnInit(): void {
    this.route.paramMap.subscribe((paramMap) => {
      const uid = paramMap.get('uid');
      if (uid) {
        this.subscribeUser(uid);
      } else {
        console.error('error no uid');
      }
    });
  }

  private subscribeUser(uid) {
    this.firestore
      .collection('users')
      .doc(uid)
      .valueChanges({ idField: 'customIdName' })
      .subscribe((userInfo: any) => {
        console.log('Got user from DB', userInfo);
        if (userInfo) {
          this.user = userInfo;
        }
      });
  }
}
