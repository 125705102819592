<div *ngIf="selectedLocation.location" class="selected-location" (click)="toggle()">
    <div class="date">
        {{ selectedLocation.daySlot.startSlot | date:'d.M' }}
    </div>

    <div class="location-name">
        {{ selectedLocation['location']['name'] }}, {{ selectedLocation['room']['name'] }} ({{
        selectedLocation['location']['city'] }})

        <div class="collapsed" [class.expanded]="opened">
            {{ selectedLocation['location']['street'] }} {{
            selectedLocation['location']['houseNumber'] }}, {{
            selectedLocation['location']['zipCode'] }}<br>
            Kontakt<br>
            Name: {{ selectedLocation['location']['responsiblePerson'] }}<br>
            Tel.: {{ selectedLocation['location']['responsiblePersonTelefon'] }}<br>
            eMail: {{ selectedLocation['location']['responsiblePersonEmail'] }}<br>
        </div>
    </div>

    <div class="expand">
        <div class="icons">
            <mat-icon class="pointer" [inline]="true">add</mat-icon>
            <mat-icon class="pointer" [inline]="true" (click)="deleteChoosenLocation()">delete</mat-icon>
        </div>
    </div>
</div>