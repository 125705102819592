import { Component, OnInit, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { first, map } from 'rxjs/operators';
import { LocationService } from 'src/app/services/location.service';
import { stageSize } from 'src/app/types/stageSize.type';
import { defaultSelectedStageSize } from 'src/app/utils/constants/defaultSelectedStageSize';
import {
  distance,
  filterSlotsForDateAndPrice,
  firstNumberFromString,
  isWithinStageSize,
  roomIsWithinStageSize,
} from 'src/app/utils/helpers';
import { defaultPriceRange } from 'src/app/utils/constants/defaultPriceRange';
import { merge } from 'rxjs';
import { TourPlannerFiltersComponent } from '../tour-planner/tour-planner-filters/tour-planner-filters.component';
import { CachingServiceService } from 'src/app/services/caching-service.service';

@Component({
  selector: 'app-location-search',
  templateUrl: './location-search.component.html',
  styleUrls: ['./location-search.component.scss'],
})
export class LocationSearchComponent implements OnInit {
  locations = [];
  selectedCity = '';
  selectedRange = 99999;
  MAX_RESULTS = 200;
  lat = 51.678418;
  lng = 7.809007;
  selectedBookingDate;
  locationSearchQuery = '';
  eventSlots = [];
  @ViewChild('tourPlannerFilters') tourPlannerFilters: TourPlannerFiltersComponent;

  selectedStageSize: stageSize = {
    stageDepth: defaultSelectedStageSize.stageDepth,
    stageBreadth: defaultSelectedStageSize.stageBreadth,
    stageHeight: defaultSelectedStageSize.stageHeight,
  };

  selectedPriceRange = defaultPriceRange;

  constructor(
    private firestore: AngularFirestore,
    private locationService: LocationService,
    private cache: CachingServiceService
  ) { }

  ngOnInit(): void {
    console.log('[LocationSearchComponent] init');
    this.getLocations();
  }




  async setCity(city) {
    const info = await this.locationService.resolveAddressInfo(city);
    this.lat = info.lat;
    this.lng = info.lng;


    // Removed due to Matthias recommendation
    this.selectedCity = city;
    this.getLocations();
  }

  filter(locations) {
    // Filter for result of name search
    let result = locations.filter((l) =>
      l['name'].toLowerCase().includes(this.locationSearchQuery.toLowerCase())
    );

    // TODO Filter if there is AT LEAST one slot in the current price range
    result = result.filter((location) => {
      let slotsForLocation = this.eventSlots.filter(s => {
        return s['locationId'] == location['customIdName'] && firstNumberFromString(s['bookingFee']) < this.selectedPriceRange;
      });
      return slotsForLocation.length > 0;
    });


    return result;
  }

  getCheapestEventSlot(locationId) {
    let slots = sortEventSlots(this.eventSlots, locationId);
    return slots.length > 0 ? slots[0]['bookingFee'] : '0';
  }

  getHighestEventSlot(locationId) {
    let slots = sortEventSlots(this.eventSlots, locationId).reverse();
    return slots.length > 0 ? slots[0]['bookingFee'] : '0';
  }

  setPriceRange(price: number) {
    this.selectedPriceRange = price;
    this.getLocations();
  }

  setRange(range) {
    console.log(range);
    this.selectedRange = range;
    this.getLocations();
  }

  setBookingDate(date: Date) {
    this.selectedBookingDate = date;
    this.getLocations();
  }

  setMinimumStageSize(stageSize: stageSize) {
    this.selectedStageSize.stageBreadth = stageSize.stageBreadth;
    this.selectedStageSize.stageDepth = stageSize.stageDepth;
    this.selectedStageSize.stageHeight = stageSize.stageHeight;
    this.getLocations();
  }

  private getLocations() {
    // , ref => ref.where('uid', '==', user['uid'])
    this.firestore
      .collection('locations', (ref) => this.filterFunction(ref))
      .valueChanges({ idField: 'customIdName' })
      .pipe(first())
      .subscribe((locations) => {
        console.log('LOADED LOCATIONS', locations);

        this.locations = locations
          .filter((l) => {

            return (
              this.getDistance(l) <= this.selectedRange &&
              isWithinStageSize(l, this.selectedStageSize)
            );
          })
          .sort((l1, l2) => this.getDistance(l1) - this.getDistance(l2));

        console.log('LOADED LOCATIONS (Filter)', this.locations);


        this.getEventSlots();
      }, (error) => {
        console.error('error loading locations', error);
      });
  }

  getEventSlots() {
    let locationIds = this.locations.map(l => l['customIdName']);

    let chunks = [];
    for (let i = 0; i < locationIds.length; i += 10) {
      chunks.push(locationIds.slice(i, i + 10));
    }

    console.log('chunks are', chunks);

    let queries = [];

    chunks.forEach((chunk) => {
      queries.push(this.getEventSlotsForLocations(chunk));
    });
    return Promise.all(queries)
      .then((eventSlotsArray) => {
        console.log('results are', eventSlotsArray);

        this.eventSlots = [];

        eventSlotsArray.forEach((slots) => {
          this.eventSlots = [...this.eventSlots, ...slots];
        });
      });

  }

  /**
   * Super direty function, as firebase has an caching error with array filters
   * @param locationIds 
   * @returns 
   */
  async getEventSlotsForLocations(locationIds) {
    let key = JSON.stringify(locationIds);

    if (this.cache.get(key)) {
      return Promise.resolve(this.cache.get(key));
    }

    let promise = this.firestore
      .collection('event-slots', ref => ref.where('locationId', 'in', locationIds))
      .valueChanges()
      .pipe(first())
      .toPromise();
    let result = await promise;
    this.cache.set(key, result);
    return result;
  }

  getFittingRooms(location) {
    let rooms = location['rooms'];
    rooms = rooms.filter(room => this.roomFitsStageSize(room));
    rooms = rooms.filter(room => this.roomHasFittingEventSlot(room));
    return rooms;
  }

  roomHasFittingEventSlot(room) {
    let slots = this.eventSlots.filter(slot => slot['roomUUID'] == room['UUID']);

    slots = filterSlotsForDateAndPrice(slots, this.selectedBookingDate, this.selectedPriceRange);
    return slots.length > 0;
  }

  roomFitsStageSize(room) {
    return roomIsWithinStageSize(room, this.selectedStageSize);
  }

  public getDistance(location): number {
    if (!location['location']) {
      return 0;
    }
    let dst = distance(
      this.lat,
      this.lng,
      location['location'].lat,
      location['location'].lng
    );
    dst = Math.round(dst * 100) / 100;
    return dst;
  }

  private filterFunction(ref: any) {
    ref = ref.where('status', 'not-in', ['Wird überprüft', 'Wird erstellt']);

    // Removed due to Matthias recommendation
    // if (this.selectedCity && this.selectedCity.length > 0) {
    //   ref = ref.where('city', '==', this.selectedCity);
    // }

    return ref.limit(this.MAX_RESULTS);
  }
}


function sortEventSlots(slots, locationId) {
  slots = slots.filter(s => s['locationId'] == locationId)

  return slots.sort(function (a, b) {
    const feea = firstNumberFromString(a.bookingFee);
    const feeb = firstNumberFromString(b.bookingFee);
    return feea - feeb;
  });

}
