import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { LocationService } from 'src/app/services/location.service';
import { MessageService } from 'src/app/services/message.service';
import { locationStatus } from 'src/app/utils/constants/locationStatus';

@Component({
  selector: 'app-release-venue',
  templateUrl: './release-venue.component.html',
  styleUrls: ['./release-venue.component.scss']
})
export class ReleaseVenueComponent implements OnInit {
  location: any;

  constructor(private locationService: LocationService,
    private messageService: MessageService,
    private dialogRef: MatDialogRef<ReleaseVenueComponent>) { }

  ngOnInit(): void {
  }

  release() {
    console.log('Releasing location', this.location);
    this.locationService.updateLocationFields(this.location['customIdName'], {
      'status': locationStatus.pending
    })
      .then(() => {
        this.dialogRef.close();
      }).catch(() => {
        this.messageService.snackbar('Leider ist ein Fehler aufgetreten.');
      });
  }

}
