import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { first } from 'rxjs/operators';
import { ContractService } from 'src/app/services/contract.service';
import { NotificationService } from 'src/app/services/notification.service';
import { TourService } from 'src/app/services/tour.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-sign-contract',
  templateUrl: './sign-contract.component.html',
  styleUrls: ['./sign-contract.component.scss']
})
export class SignContractComponent implements OnInit {
  loading = false;
  contract = '';
  notification = {};

  constructor(public contractService: ContractService,
    private userService: UserService,
    private notificationService: NotificationService,
    private snackbar: MatSnackBar,
    private dialogRef: MatDialogRef<SignContractComponent>,
    private tourService: TourService,
    public firestore: AngularFirestore) { }

  ngOnInit(): void {
  }

  async acceptContract() {
    this.loading = true;
    console.log('Signing');
    let myId = '';
    myId = await this.userService.getMyId().pipe(first()).toPromise();

    let signedContract = {
      signedAt: new Date(),
      user: myId,
      contract: this.contract,
      locationId: this.notification['payload']['locationId'],
      extraInformation: this.notification['payload']
    };

    this.firestore
      .collection('signed-contracts')
      .add(signedContract)
      .then(() => {
        this.close('Der Vertrag wurde unterzeichnet!');
        this.updateNotification();
        this.informLocationOwner();
      }).catch(() => {
        this.close('Ein Fehler ist aufgetreten!');
      });
    console.log('Contract signed with the following information', signedContract);


    this.tourService.updateTourStatus(this.notification['payload']['tourId'], 'Vertrag akzeptiert', this.notification['payload']['slotId']);

    this.updateSlot();
  }

  private async updateSlot() {
    const slotId = this.notification['payload']['slotId'];
    console.log('SLOTID', slotId);

    const slot = await this.firestore.collection('event-slots').doc(slotId).valueChanges().pipe(first()).toPromise();
    slot['status'] = 'Gebucht';

    console.log('Slot', slot);

    console.log('Updating event slot', slot);
    this.firestore
      .collection('event-slots')
      .doc(slot['customIdName'])
      .update(slot)
      .then(() => {
        console.log('Updated Slot', slot);

      })
      .catch((error) => {
        console.error('Error updating slot', error, slot);
      });
  }


  private close(msg) {
    this.loading = false;
    this.dialogRef.close();
    this.snackbar.open(msg, null, { duration: 3000 });
  }

  private informLocationOwner() {
    this.notificationService.informLocationOwnerAboutBooking(this.notification['payload']);
  }

  private updateNotification() {
    this.notification['read'] = null;
    this.notification['action'] = 'CONTRACT_SIGNED';
    this.notification['payload']['signedAt'] = new Date();
    this.notificationService.update(this.notification);
  }

}
