<div class="dialog-container">
    <form (submit)="requestEvent()" ngNativeValidate>
        <mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>

        <div mat-dialog-title *ngIf="bookingStatus != 'success'">
            <h1>Verbindliche Reservierung</h1>
        </div>
        <div mat-dialog-content>
            <div>
                <h2>{{ location['name'] }}</h2>
            </div>
            <div [hidden]="bookingStatus != 'error'">
                <h2>Ein Fehler ist aufgetreten</h2>
                <p>Bitte versuche es später erneut.</p>
            </div>
            <div [hidden]="bookingStatus != 'success'">
                <div class="margin-bottom-32 text-center">
                    <h2>Die Reservierungsanfrage war erfolgreich.</h2>
                </div>
                <div class="flex-center margin-bottom-32">
                    <mat-icon class="checkmark">check</mat-icon>
                </div>
                <div class="margin-bottom-32 text-center">
                    <div class="margin-bottom-32"><b>Wie geht es weiter?</b></div>
                    <p class="text-center">Das Management der gebuchten Verstanstaltung überprüft nun deine Anfrage. Sobald die Anfrage bestätigt oder abgelehnt wurde, wirst du in deinem Postfach informiert.</p>
                </div>
            </div>
            <div [hidden]="bookingStatus != 'booking'">

                <mat-card class="info-card margin-bottom-32" *ngIf="eventSlots.length == 0">
                    <mat-icon>info</mat-icon>
                    Aktuell stehen leider keine offnen Termine mehr an dem gewünschten Datum zur Verfügung.
                </mat-card>


                <div class="timeslot" [class.timeslot-selected]="selectedSlot == i" *ngFor="let slot of eventSlots; let i = index;" (click)="selectedSlot = i;">
                    <div class="width-100">
                        <div class="space-between">
                            <div>{{ slot.startSlot | date:'EEEE' }}, {{ slot.startSlot | date:'short' }} - {{ slot.endSlot | date:'mediumTime' }}</div>
                            <div>{{ slot['status'] }}</div>
                        </div>
                        <div><b>Buchungskosten {{ slot['bookingFee'] ? slot['bookingFee'] +' € (Brutto)' : 'nicht
                                Angegeben' }} </b>
                        </div>
                    </div>
                </div>

                <div>
                    <mat-form-field appearance="fill">
                        <mat-label>Kategorie</mat-label>
                        <mat-select [(ngModel)]="concertType" name="KonzertTyp" required>
                            <mat-option *ngFor="let type of concertTypes" [value]="type">
                                {{type}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <div>
                    <mat-form-field class="width-100">
                        <mat-label>Website der Veranstaltung</mat-label>
                        <input [disabled]="disableForm" type="url" (keyup)="validateURL()" [(ngModel)]="bookingRequest['website']" name="website" matInput required placeholder="Z.B. https://www.deine-veranstaltung.de">
                    </mat-form-field>
                </div>


                <div>
                    <mat-form-field class="width-100">
                        <mat-label>Name Deiner Veranstaltung</mat-label>
                        <textarea [disabled]="disableForm" [(ngModel)]="bookingRequest['description']" name="description" matInput required placeholder="Z.B. Konzert"></textarea>
                    </mat-form-field>
                </div>



                <div>
                    <mat-form-field class="width-100">
                        <mat-label>Nachricht an Venue</mat-label>
                        <textarea [disabled]="disableForm" rows="10" matInput required name="message" [(ngModel)]="bookingRequest['message']" placeholder="Sehr geehrter Herr / Frau ..."></textarea>
                    </mat-form-field>
                </div>

                <mat-card class="info-card">
                    <mat-icon>info</mat-icon>Die Anfrage ist verbindlich, kann aber vom Gegenüber abgelehnt werden.
                </mat-card>
            </div>

        </div>
        <div mat-dialog-actions class="flex-right">
            <button type="submit" mat-button mat-dialog-close *ngIf="bookingStatus != 'success'">Abbrechen</button>
            <button [disabled]="disableForm" mat-raised-button color="primary" *ngIf="bookingStatus != 'success'">Buchen</button>
            <button [disabled]="disableForm" mat-raised-button color="primary" mat-dialog-close *ngIf="bookingStatus == 'success'">Schließen</button>
        </div>
    </form>

</div>