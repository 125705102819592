<div class="dialog-container access-container">

    <h1 mat-dialog-title>Venue veröffentlichen</h1>

    <div mat-dialog-content>
        Wenn du auf "Veröffentlichen" klickst, wird das Venue zunächst vom SADEY-Team überprüft. Nach der Überprüfung können Nutzer deine Räume buchen.
    </div>

    <div mat-dialog-actions class="flex-right">
        <button mat-raised-button (click)="release()" color="primary">Veröffentlichen</button>
    </div>
</div>