import { Component, Input, OnInit } from '@angular/core';
import {
  ROOM_CATEGORIES,
  PLACE_FIELDS,
} from 'src/app/components/edit-location/edit-place/place-fields.data';
import { LOCATION_FIELDS } from 'src/app/components/edit-location/locations-fields.data';
import { capitalizeFirstLetter } from 'src/app/utils/helpers';

@Component({
  selector: 'app-room-info',
  templateUrl: './room-info.component.html',
  styleUrls: ['./room-info.component.scss'],
})
export class RoomInfoComponent implements OnInit {
  @Input() location: any = {};
  @Input() selectedRoom = 0;
  ROOM_CATEGORIES = resolveCategories();
  selected = 0;
  PLACE_FIELDS = PLACE_FIELDS;
  LOCATION_FIELDS = LOCATION_FIELDS;

  constructor() {}

  ngOnInit(): void {
    // setInterval(() => {
    //   console.log('RoomInfoComponent',this.location);
    //   console.log('selectedRoom:', this.selectedRoom);
      
    // }, 5000);
  }

  getFileName(url) {
    let start = url.indexOf('undefined');
    let end = url.indexOf('?alt=');
    return url.substring(start + 22, end);
  }

  getFields() {
    return PLACE_FIELDS.filter((field) => {
      return (
        field['category'].toLowerCase() ==
        this.ROOM_CATEGORIES[this.selected].toLowerCase()
      );
    });
  }
}

function resolveCategories() {
  let categories = [];
  ROOM_CATEGORIES().forEach((c) => {
    if (!['NAME', 'Alte Felder, soll ich die löschen?'].includes(c)) {
      if (c == 'MAßE HAUPTBÜHNE') {
        c = 'Maße Hauptbühne';
      } else {
        c = capitalizeFirstLetter(c.toLowerCase());
      }
      categories.push(c);
    }
  });

  return categories;
}
