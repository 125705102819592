import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthProcessService } from 'ngx-auth-firebaseui';
import { first } from 'rxjs/operators';
import { UserService } from 'src/app/services/user.service';
import { NGX_AUTH_FIREBASEUI_LOGIN } from 'src/app/utils/constants/authFormTranslations'

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  public needsVerification: boolean;

  constructor(
    public dialogRef: MatDialogRef<LoginComponent>,
    private router: Router,
    private dialog: MatDialog,
    public translate: TranslateService,
    private _snackBar: MatSnackBar,
    private user: UserService,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    // this language will be used as a fallback when a translation isn't found in the current language
    translate.setDefaultLang('de');
    // the lang to use, if the lang isn't available, it will use the current loader to get them
    translate.use('de');
    translate.setTranslation('de', NGX_AUTH_FIREBASEUI_LOGIN);

    this.needsVerification = data?.needsVerification ?? false;
  }

  ngOnInit(): void {
  }

  async loginSuccessful(event: any) {
    console.log('Login successful');

    const emailVerified = await this.user.emailVerified();

    if (emailVerified) {
      this.showGreetingsMsg(event.displayName);
      this.dialogRef.close();
      this.user.getMyUser().pipe(first()).toPromise().then(u => {
        if (u['userType'] == 'eventler') {
          this.router.navigate(['profile']);
        } else {
          this.router.navigate(['my-locations']);
        }
      });
    } else {
      // TODO: This should rather be triggered by a click on a button
      this.user.sendVerificationMail();

      this.user.logoutUser();
      this.needsVerification = true;
    }
  }

  resetPassword() {
    //TODO Create Reset Paswword Dialog
  }

  showGreetingsMsg(name: any) {
    this._snackBar.open(`Hallo ${name} !`, 'OK', { duration: 3000 });
  }
}
