import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { first } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TourService {

  constructor(private firestore: AngularFirestore) { }



  public async updateTourStatus(tourId, newStatus, eventSlotId){
    let tour = await this.firestore.collection('tours').doc(tourId).valueChanges().pipe(first()).toPromise();
    console.log('TOUR IS', tour);
    console.log('Trying to find tourslot with id', eventSlotId);
    tour['tourSlots'].find(ts => ts['eventSlotId'] == eventSlotId).status = newStatus;
    return await this.firestore.collection('tours').doc(tourId).update(tour);
  }
}
