export const concertTypes = [
  'Konzert',
  'Musical & Show',
  'Ballet & Tanz',
  'Klassik',
  'Opern / Operette',
  'Theater',
  'Comdey & Kabarett',
  'Festival',
  'Kinder',
];
