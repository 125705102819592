import {
  CalendarEvent,
  CalendarEventAction,
  CalendarMonthViewDay,
  CalendarView,
  CalendarWeekViewBeforeRenderEvent,
} from 'angular-calendar';

// TODO set from central colors
export const colors: any = {
  pink:{
    primary: '#ef1b7c',
    secondary: '##ef1b7c',
  },
  red: {
    primary: '#ad2121',
    secondary: '#FAE3E3',
  },
  blue: {
    primary: '#1e90ff',
    secondary: '#D1E8FF',
  },
  yellow: {
    primary: '#e3bc08',
    secondary: '#FDF1BA',
  },
};
