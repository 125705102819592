<div mat-dialog-content class="DialogContainer">
    <div mat-dialog-actions class="closeIconContainer">
        <button mat-flat-button (click)="onNoClick()">X</button>
    </div>
    <div class="inputContainer">
            <mat-form-field appearance="outline">
        <mat-label>Name</mat-label>
        <input matInput [(ngModel)]="data.name">
    </mat-form-field>
    <mat-form-field appearance="outline">
        <mat-label>E-Mail Adresse</mat-label>
        <input matInput [(ngModel)]="data.eMail">
    </mat-form-field>
    <mat-form-field appearance="outline">
        <mat-label>Nachricht</mat-label>
        <textarea class="messageField" matInput [(ngModel)]="data.message"></textarea>
    </mat-form-field>
    </div>

    <div mat-dialog-actions class="sendMessageContainer">
        <button mat-flat-button [mat-dialog-close]="data" cdkFocusInitial>
            <img class="sendMessageIcon" src="assets/img/papierflieger.svg">
        </button>
    </div>
</div>