import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject, Observable } from 'rxjs';
import { LocationService } from 'src/app/services/location.service';
import { AddLocationComponent } from '../dialogs/add-location/add-location.component';
import { DeleteLocationComponent } from '../dialogs/delete-location/delete-location.component';
import { isApproved, hasImgs, hasEventSlots } from 'src/app/utils/helpers';

@Component({
  selector: 'app-my-locations',
  templateUrl: './my-locations.component.html',
  styleUrls: ['./my-locations.component.scss'],
})
export class MyLocationsComponent implements OnInit {
  locations: Observable<any[]> = new BehaviorSubject([]);

  constructor(
    private locationService: LocationService,
    public dialog: MatDialog
  ) {}

  async ngOnInit() {
    this.locations = await this.locationService.getMyLocations();
    console.log('My locations are', this.locations);
    /**
    this.authService.user.pipe(first()).toPromise()
      .then((user) => {
        console.log('[MyLocationsComponent]', user.uid);

        // TODO: Filter for on "MY" locations in backend as well
        this.locations = this.firestore.collection('locations').valueChanges({ idField: 'customIdName' })
          .pipe(map((locations) => {
            console.log('[MyLocationsComponent] locations', locations);
            return locations.filter((location) => {
              return location['users'].find(u => u['uid'] == user.uid);
            });
          }));
      });

      */
  }

  checkNonConformance(l: any) {
    return !isApproved(l) || !hasImgs(l) || !hasEventSlots(l);
  }

  deleteLocation(location: any, $event) {
    $event.stopPropagation();
    console.log('DELETE');
    let c = this.dialog.open(DeleteLocationComponent).componentInstance;
    c.location = location;
  }

  addLocation() {
    this.dialog.open(AddLocationComponent);
  }
}
