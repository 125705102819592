import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class EventSlotService {
  constructor() {}

  public slotDescription: string = undefined;
  public slotMsgToVendor: string = undefined;
}
