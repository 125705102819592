import { HttpClient } from '@angular/common/http';
import { Injectable, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class LocationService {
  public currentSelection = { location: {}, room: 0 };

  constructor(
    private authService: AngularFireAuth,
    private firestore: AngularFirestore,
    private http: HttpClient
  ) {
    this.loadLocations();
  }

  public async loadLocations() {
    (await this.getMyLocations()).pipe(first()).subscribe((locations) => {
      console.log('[LocationService] Got Locations', locations);
      this.currentSelection = { location: locations[0], room: 0 };
      this.checkLocationRoomUUIDs(locations);
      this.validateLocations(locations);
    });
  }


  public validateLocations(locations: Array<any>){
    locations.forEach((location) => {
      if(!location['rooms']) {
        location['rooms'] = [];
        this.updateLocation(location);
      }
    });
  }

  /**
   * Some old locations might not have a room UUID. This function fixes it.
   * @param locations Location array
   */
  public checkLocationRoomUUIDs(locations: Array<any>) {

    locations.forEach((location) => {
      if (location['rooms']) {
        location['rooms'].forEach(room => {
          if (Object.keys(room).length > 0 && !room['UUID']) {
            room['UUID'] = new Date().getTime();
            console.warn('Found room without UUID. It will be created!', location, room);
            this.updateLocation(location);
          }
        });
      }
    });

  }

  public currentLocationName() {
    return this.currentSelection['location']['name'];
  }

  public currentLocation() {
    return this.currentSelection['location'];
  }

  public currentLocationRoom() {
    try {
      let room = this.currentSelection['room'];
      if (!room) {
        room = 0;
      }
      return this.currentSelection['location']['rooms'][room];
    } catch (e) {
      console.info('Could not find location room', this.currentSelection);
      return {};
    }
  }

  public currentLocationRoomName() {
    try {
      let room = this.currentSelection['room'];
      return this.currentSelection['location']['rooms'][room]['name'];
    } catch (e) {
      return '';
    }

  }

  public updateCurrentSelection(currentSelection: any): void {
    this.currentSelection = currentSelection;
    // this.currentLocationBehaviorSubject.next(this.currentSelection);
  }

  public getRoomIndex(room: {}): number {
    if(!this.currentSelection['location']['rooms']) {
      this.currentSelection['location']['rooms'] = [];
      this.updateLocation(this.currentSelection['location']);
    }
    return this.currentSelection['location']['rooms'].findIndex((r) => {
      return r['UUID'] === room['UUID'];
    });
  }

  public getSlot(location, placeIndex, slotIndex) {
    return location['places'][placeIndex]['bookableEventSlots'][slotIndex];
  }

  public getLocation(id): Observable<any> {
    return this.firestore.collection('locations').doc(id).valueChanges();
  }

  public getLocationOwner(id) {
    return this.firestore
      .collection('locations')
      .doc(id)
      .valueChanges()
      .pipe(first())
      .pipe(
        map((location) => {
          return location['users'].find((u) => u['role'] == 'Owner');
        })
      );
  }

  public getRequest(location, placeIndex, slotIndex, requestIndex) {
    return location['places'][placeIndex]['bookableEventSlots'][slotIndex][
      'requests'
    ][requestIndex];
  }

  public updateLocation(location): Promise<any> {
    console.log('Updating location', location);
    return this.firestore
      .collection('locations')
      .doc(location['customIdName'])
      .set(location);
  }

  public updateLocationFields(id, fields: any): Promise<any> {
    console.log('Updating location', id, fields);
    return this.firestore
      .collection('locations')
      .doc(id)
      .update(fields);
  }

  /**
   *
   * @param place {lat: ..., lng: ...}
   */
  public async resolveLatLng(place: any) {
    const address = `${place['street']} ${place['houseNumber']}, ${place['zipCode']} ${place['city']}`;
    const url =
      'https://maps.googleapis.com/maps/api/geocode/json?address=' +
      address +
      '&key=' +
      environment.googleMapsGeocodeApi;

    const result = await this.http.get(url).toPromise();
    return result['results'][0]['geometry']['location'];
  }

  public async resolveAddressInfo(address) {
    const url =
      'https://maps.googleapis.com/maps/api/geocode/json?address=' +
      address +
      '&key=' +
      environment.googleMapsGeocodeApi;
    const result = await this.http.get(url).toPromise();
    if (result['results'].length == 0) {
      throw new Error('The address could not be found.');
    }
    return result['results'][0]['geometry']['location'];
  }

  public async getLocationRequests(statusFilter?: string) {
    return (await this.getMyLocations()).pipe(
      map((myLocations: []) => {
        let requests = [];
        myLocations.forEach((location) => {
          const places: [] = location['places'] || [];
          places.forEach((place) => {
            const bookableEventSlots: [] = place['bookableEventSlots'] || [];
            bookableEventSlots.forEach((slot) => {
              let reqs: [] = slot['requests'];
              if (reqs) {
                reqs.forEach((r) => {
                  r['location'] = location['customIdName'];
                  r['status'] = slot['status'];
                });
                if (!statusFilter || statusFilter == slot['status']) {
                  requests = requests.concat(slot['requests']);
                }
              }
            });
          });
        });
        return requests;
      })
    );
  }

  public async getMyLocationsOnce() {
    return await (await this.getMyLocations()).pipe(first()).toPromise();
  }

  public async getMyLocations(): Promise<Observable<any>> {
    let user = await this.authService.user.pipe(first()).toPromise();
    if (!user) {
      return new Promise((resolve) => new BehaviorSubject([]));
    }
    console.log('[MyLocationsComponent]', user.uid);
    // TODO: Filter for on "MY" locations in backend as well
    return this.firestore
      .collection('locations')
      .valueChanges({ idField: 'customIdName' })
      .pipe(
        map((locations) => {
          console.log('[MyLocationsComponent] locations', locations);
          return locations.filter((location) => {
            if (!location['users']) {
              console.error('This location has no Users at all!', location);
              return false;
            }
            return location['users'].find((u) => u['uid'] == user.uid);
          });
        })
      );
  }
}
