import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import {
  CalendarEvent,
  CalendarEventAction,
  CalendarMonthViewDay,
  CalendarView,
  CalendarWeekViewBeforeRenderEvent,
} from 'angular-calendar';
import { Subject } from 'rxjs';
import { LocationService } from 'src/app/services/location.service';
import {
  endOfDay,
  isEqual,
  isSameDay,
  isSameMonth,
  startOfDay,
} from 'date-fns';
import { MatDialog } from '@angular/material/dialog';
import { AddEventComponent } from '../dialogs/add-event/add-event.component';
import { AngularFirestore } from '@angular/fire/firestore';
import { WeekViewHourColumn } from 'calendar-utils';
import {
  getJsDate,
  getLocationWithRoom,
  isRoomCreated,
  slotsToJsDate,
} from 'src/app/utils/helpers';
import { operation } from 'src/app/utils/constants/operation';
import { MatSnackBar } from '@angular/material/snack-bar';
import { colors } from 'src/app/utils/constants/calendarSettings';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
})
export class CalendarComponent implements OnInit {
  // Calendar
  @ViewChild('modalContent', { static: true })
  public modalContent: TemplateRef<any>;
  public events: CalendarEvent[] = [];
  public refresh: Subject<any> = new Subject();
  roomNotFinished = false;
  /* 
      SETTINGS
  */
  // Selected Time in Calendar
  public selectedMonthViewDay: CalendarMonthViewDay = undefined;
  public selectedDayViewDate: Date = undefined;
  public hourColumns: WeekViewHourColumn[];
  public selectedDay: CalendarMonthViewDay = undefined;

  // Calendar Event Actions
  public actions: CalendarEventAction[] = [
    {
      label: '<div class="material-icons mat-icon">edit</div>',
      a11yLabel: 'Edit',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.handleEvent('Edited', event);
      },
    },
    {
      label: '<div class="material-icons mat-icon">delete</div>',
      a11yLabel: 'Delete',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.events = this.events.filter((iEvent) => iEvent !== event);
        this.handleEvent('Deleted', event);
      },
    },
  ];
  // Calendar Settings
  public view: CalendarView = CalendarView.Month;
  public CalendarView = CalendarView;
  public viewDate: Date = new Date();
  public weekStartsOn: 1 = 1;
  public activeDayIsOpen: boolean = false;

  // Event Settings
  eventSettings = {
    title: 'Buchbarer Slot',
    color: colors.pink,
    actions: this.actions,
    draggable: true,
    resizeable: {
      beforeStart: true,
      afterEnd: true,
    },
  };

  private locations: {}[];
  private currentRoom = {};
  private eventSlots = [];

  constructor(
    public locationService: LocationService,
    public dialog: MatDialog,
    private firestore: AngularFirestore,
    private snackBar: MatSnackBar
  ) { }

  public async ngOnInit() {
    (await this.locationService.getMyLocations()).subscribe((locations) => {
      this.locations = locations;
    });
  }

  public ngAfterViewInit(): void { }

  public loadEventSlots(room) {
    // console.log('room:', room);
    this.roomNotFinished = !isRoomCreated(room);
    if (!isRoomCreated(room)) {
      console.log('Not created Room is choosen', room);
      return;
    }
    console.log('getLocationWithRoom:', this.locations, room);


    let location = getLocationWithRoom(this.locations, room);
    this.locationService.currentSelection.location = location;
    let roomIndex = this.locationService.getRoomIndex(room);
    let currentSelection = { location: location, room: roomIndex };
    this.locationService.updateCurrentSelection(currentSelection);
    this.currentRoom = room;

    this.emptyPreviousEventSlots();
    this.loadSlotsCurrentRoom();
  }

  public handleEvent(action: string, event: CalendarEvent): void {
    const slot = this.eventSlots.find((s) => {
      let start = getJsDate(s['startSlot']);
      return isEqual(start, event.start);
    });
    slot['startSlot'] = getJsDate(slot['startSlot']);
    slot['endSlot'] = getJsDate(slot['endSlot']);
    switch (action) {
      case 'Edited':
        this.openEventDialog(operation.edit, slot);
        break;
      case 'Deleted':
        this.deleteEvent(slot);
        break;
      default:
        break;
    }
  }

  public handleCreate() {
    let slot = this.getCreateSlot();
    if (!this.selectedMonthViewDay && !this.selectedDayViewDate) {
      slot['startSlot'] = startOfDay(new Date());
      slot['endSlot'] = endOfDay(new Date());
      this.openEventDialog(operation.create, slot);
      return;
    }
    if (this.selectedMonthViewDay) {
      slot['startSlot'] = this.selectedMonthViewDay.date;
      slot['endSlot'] = this.selectedMonthViewDay.date;
      this.openEventDialog(operation.create, slot);
      return;
    }
    if (this.selectedDayViewDate) {
      slot['startSlot'] = startOfDay(this.selectedDayViewDate.getTime());
      slot['endSlot'] = endOfDay(this.selectedDayViewDate.getTime());
      this.openEventDialog(operation.create, slot);
      return;
    }
  }

  public dayClicked(day: CalendarMonthViewDay): void {
    if (!isSameMonth(day.date, this.viewDate)) {
      return;
    }
    this.highlightCurrentDay(day);
    this.handleEventsDisplayer(day);
  }

  public closeOpenMonthViewDay(): void {
    this.activeDayIsOpen = false;
  }

  public setView(view: CalendarView) {
    this.view = view;
  }

  public beforeMonthViewRender({
    body,
  }: {
    body: CalendarMonthViewDay[];
  }): void {
    body.forEach((day) => {
      if (
        this.selectedMonthViewDay &&
        isSameDay(this.selectedMonthViewDay.date, day.date)
      ) {
        day.cssClass = 'cal-day-selected';
      }
    });
  }

  public openEventDialog(operation: operation, slot): void {
    const dialogRef = this.dialog.open(AddEventComponent, {
      width: '40vw',
      data: {
        operation: operation,
        slot: slot,
        room: this.currentRoom,
        eventSlots: slotsToJsDate(this.eventSlots),
      },
    });

    // dialogRef.afterClosed().subscribe((result) => {
    //   //console.log(`Dialog close result: ${result}`);
    // });
  }

  public hourSegmentClicked(date: Date) {
    this.selectedDayViewDate = date;
    this.addSelectedDayViewClass();
  }

  public beforeWeekOrDayViewRender(event: CalendarWeekViewBeforeRenderEvent) {
    this.hourColumns = event.hourColumns;
    this.addSelectedDayViewClass();
  }

  private getCreateSlot(): {} {
    return {
      locationId: this.locationService.currentSelection.location[
        'customIdName'
      ],
      roomUUID: this.currentRoom['UUID'],
      startSlot: undefined,
      endSlot: undefined,
    };
  }

  private emptyPreviousEventSlots() {
    this.eventSlots = [];
    this.events = [];
  }

  private deleteEvent(slot): void {
    //this.deleteEventFromLocation(event);
    this.firestore
      .collection('event-slots')
      .doc(slot['customIdName'])
      .delete()
      .then(() => {
        this.snackBar.open('Event Slot wurde gelöscht', null, {
          duration: 3000,
        });
      });
  }

  private addSelectedDayViewClass() {
    this.hourColumns.forEach((column) => {
      column.hours.forEach((hourSegment) => {
        hourSegment.segments.forEach((segment) => {
          delete segment.cssClass;
          if (
            this.selectedDayViewDate &&
            segment.date.getTime() === this.selectedDayViewDate.getTime()
          ) {
            segment.cssClass = 'cal-day-selected';
          }
        });
      });
    });
  }

  private handleEventsDisplayer(day: CalendarMonthViewDay) {
    if (
      (isSameDay(this.viewDate, day.date) && this.activeDayIsOpen === true) ||
      day.events.length === 0
    ) {
      this.activeDayIsOpen = false;
    } else {
      this.activeDayIsOpen = true;
      this.viewDate = day.date;
    }
  }

  private highlightCurrentDay(day: CalendarMonthViewDay) {
    if (!this.selectedMonthViewDay) {
      this.selectedMonthViewDay = day;
      this.selectedMonthViewDay.cssClass = 'cal-day-selected';
      return;
    }
    delete this.selectedMonthViewDay.cssClass;
    this.selectedMonthViewDay = day;
    this.selectedMonthViewDay.cssClass = 'cal-day-selected';
  }

  private loadSlotsCurrentRoom() {
    this.firestore
      .collection('event-slots', (ref) =>
        ref.where(
          'locationId',
          '==',
          this.locationService.currentSelection.location['customIdName']
        )
      )
      .valueChanges()
      .subscribe((eventSlots) => {
        console.log('subscribe eventSlots', eventSlots);
        console.log('current room UUID', this.currentRoom['UUID']);
        let filteredSlots = eventSlots.filter((slot) => {
          return slot['roomUUID'] === this.currentRoom['UUID'];
        });
        this.processEventSlots(filteredSlots);
        this.refresh.next();
      });
  }

  private processEventSlots(filteredSlots: any[]) {
    if (filteredSlots.length === 0) {
      return;
    }
    this.eventSlots = filteredSlots;
    // console.log('eventSlots', this.eventSlots);
    let transformedSlots: any[] = this.transformEventSlots(filteredSlots); //getSlotsInJsDate(filteredSlots);
    let calendarEvents: CalendarEvent[] = this.combineEventSlots(
      transformedSlots
    );
    this.events = calendarEvents;
  }

  private combineEventSlots(transformedSlots: any[]): CalendarEvent[] {
    return transformedSlots.map((slot) => {
      let tslot = {
        ...slot,
        ...this.eventSettings,
      };

      tslot.title = this.createTitle(tslot);

      console.log('Created calender entry', slot, tslot);
      return tslot;
    });
  }

  createTitle(calenderSlot) {
    let title = 'Buchbarer Slot';
    if(calenderSlot.status == 'Gebucht') {
      title = 'Bereits gebuchter Slot';
    } else if(calenderSlot.end.getTime() < new Date().getTime()) {
      title = 'Ungebuchter Slot (Abgelaufen)';

    }


    return title + ' ' + calenderSlot.bookingFee + '€';
  }

  // todo put in helpers
  private transformEventSlots(filteredSlots: any[]): any[] {
    return filteredSlots.map((slot) => {
      return {
        bookingFee: slot.bookingFee,
        status: slot.status,
        start: getJsDate(slot.startSlot),
        end: getJsDate(slot.endSlot),
      };
    });
  }
}
