import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MessageDialogComponent } from '../components/dialogs/message-dialog/message-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class MessageService {
  constructor(private _snackBar: MatSnackBar, private dialogService: MatDialog) {}

  public snackbar(msg) {
    this._snackBar.open(msg, null, {
      duration: 3000,
    });
  }


  public snackbarCenter(msg) {
    this._snackBar.open(msg, null, {
      verticalPosition: 'top',
      duration: 6000,
    });

    setTimeout(() => {
      let snackbars = Array.from(document.getElementsByClassName('cdk-overlay-pane'));

      snackbars.forEach((snackbar: HTMLElement) => {
        snackbar.style.marginTop = ((window.innerHeight / 2) - 48) + 'px';
      });
    }, 10);
  }

  public dialog(msg){
    this.dialogService.open(MessageDialogComponent).componentInstance.msg = msg;
  }
}
