<div class="card-round page-card">
    <div class="space-between">

        <h1>
            Vertragsmuster hinzufügen
        </h1>

        <app-my-locations-switch #locationSwitch></app-my-locations-switch>
    </div>
   

    <input hidden #input mat-raised-button color="primary" type="file" name="file" accept=".pdf" (change)="handleDocInput($event.target.files)" onclick="this.value = null">

    <div class="margin-top-32 image-row" *ngIf="locationSwitch.currentLocation">
        <div *ngFor="let url of locationSwitch.currentLocation?.contractTemplateSrcs;" class="doc-container">

            <span class="filename" title="{{ contractService.getFileName(url) }}">{{ contractService.getFileName(url) }}</span>
            <mat-icon class="download" (click)="downloadFile(url)" matTooltipPosition="above" matTooltip="Datei herunterladen">add</mat-icon>
            <mat-icon class="close" (click)="removeFile(url)" matTooltipPosition="above" matTooltip="Datei löschen">close
            </mat-icon>
            <!-- <mat-icon class="search">search</mat-icon> -->
            <!-- <mat-icon class="edit">edit</mat-icon> -->
            <iframe *ngIf="url" [src]="getUrl(url)" frameBorder="0" scrolling="no"></iframe>
        </div>
    </div>
    <button matTooltipPosition="above" matTooltip="Neue Vertragsvorlage hochladen" class="fab-button" *ngIf="location?.contractTemplateSrcs.length < MAX_DOC_UPLOAD" (click)="input.click()" mat-fab color="primary">
        <mat-icon>add</mat-icon>
      </button>
</div>