<table>
    <tr *ngFor="let field of getFields()">
        <td class="width-180">{{ field['label'] }}</td>
        <td class="pl-12">
            <div class="td-content">
                {{ dataJSON[field['field']] }}
            </div>

        </td>
    </tr>
</table>