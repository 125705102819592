import { Component, Input, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { LocationService } from 'src/app/services/location.service';
import { tourSlotInfo } from 'src/app/types/tourSlotInfo.type';
import { eventSlotStatus } from 'src/app/utils/constants/eventSlotStatus';

@Component({
  selector: 'app-tour-slot',
  templateUrl: './tour-slot.component.html',
  styleUrls: ['./tour-slot.component.scss'],
})
export class TourSlotComponent implements OnInit {
  @Input() public tourSlot = {};
  opened: boolean = false;
  eventSlot;
  location = {};
  status;
  constructor(private firestore: AngularFirestore, private locationService: LocationService) { }

  ngOnInit(): void {
    console.log('tourSlot: ', this.tourSlot);
    this.loadEventSlot();
    this.loadLocation();
  }

  toggle() {
    this.opened = !this.opened;
  }

  private loadEventSlot() {
    this.firestore
      .collection('event-slots')
      .doc(this.tourSlot['eventSlotId'])
      .valueChanges()
      .subscribe((eventSlot: any) => {
        console.log('Loaded event slot', eventSlot);
        this.eventSlot = eventSlot;
        this.status = eventSlot.requests?.[0]?.['status'] === eventSlotStatus.declined ? eventSlotStatus.declined : this.tourSlot['status']
      });
  }

  private loadLocation() {
    this.locationService.getLocation(this.tourSlot['locationId'])
      .subscribe((location) => {
        this.location = location;
      });
  }
}
