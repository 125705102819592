<form class="card-round margin-top-32" (ngSubmit)="createLocationPlace()" [formGroup]="newLocationForm">
    <h2 *ngIf="!getRoomName()">Raum {{ placeIndex + 1 }}</h2>
    <h2 *ngIf="getRoomName()">{{ getRoomName() }}</h2>

    <button class="delete-button" type="button" matTooltip="Standort löschen" (click)="deletePlace()" mat-icon-button>
    <mat-icon>delete</mat-icon>
  </button>


    <div *ngFor="let category of getCategories()">


        <div class="space-between" *ngIf="getFields(category).length > 0">
            <h3 class="margin-top-32 flex-center-v" (click)="toggle(category)">
                <mat-icon [class.rotate-90]="!openedCategories.includes(category)">
                    keyboard_arrow_right</mat-icon>
                {{ category || 'Weiteres' }}
            </h3>

            <button class="description-button" tabindex="-1" mat-button matSuffix mat-icon-button matTooltip="Informationen" type="button" matTooltipPosition="above" (click)="showCategoryInfo(category)">
            <mat-icon>info</mat-icon>
          </button>

        </div>
        <div class="place-field" [class.hidden]="openedCategories.includes(category)">
            <div *ngFor="let field of getFields(category)">
                <mat-form-field class="width-100">
                    <mat-label>{{ field.label }}</mat-label>
                    <input [formControlName]="field.field" maxlength="120" [id]="field.field" matInput [placeholder]="field.placeholder" [min]="field.type == 'number' ? '0' : null" [type]="field.type ? field.type : 'text'">

                    <!-- <button tabindex="-1" mat-button matSuffix mat-icon-button matTooltip="Informationen zu diesem Feld" type="button" matTooltipPosition="above" (click)="showFieldInfo(field)">
                        <mat-icon>info</mat-icon>
                      </button> -->
                </mat-form-field>
            </div>
        </div>
    </div>


    <div class="flex-right">
        <span class="flex-center-v margin-right-16" *ngIf="successMessage">
            <mat-icon>check</mat-icon>
            Änderungen gespeichert!
        </span>
        
        <button mat-raised-button color="primary" type="submit">Speichern</button>
    </div>
</form>