import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MessageService } from 'src/app/services/message.service';
import { validateForm } from 'src/app/utils/helpers';
import { GET_DESCRIPTION, PLACE_FIELDS, ROOM_CATEGORIES } from './place-fields.data';

@Component({
  selector: 'app-edit-place',
  templateUrl: './edit-place.component.html',
  styleUrls: ['./edit-place.component.scss']
})
export class EditPlaceComponent implements OnInit {
  fields = PLACE_FIELDS;
  newLocationForm = new FormGroup({});
  @Input() placeIndex = 0;
  @Input() public placeInfo = {};
  @Output() placeInfoUpdate = new EventEmitter();
  @Output() delete = new EventEmitter();
  openedCategories = ROOM_CATEGORIES();

  @Input() successMessage = false;
  
  constructor(private message: MessageService) { }

  ngOnInit(): void {
    this.initFormGroups();
  }

  deletePlace() {
    if (confirm('Wollen sie diesen Standort wirklich löschen?')) {
      this.delete.emit(true);
    }
  }

  toggle(category) {
    if (this.openedCategories.includes(category)) {
      this.openedCategories = this.openedCategories.filter(c => c != category);
    } else {
      this.openedCategories.push(category);
    }
  }

  getCategories() {
    return [...ROOM_CATEGORIES(), null];
  }

  getFields(category?: string) {
    if (category) {
      return this.fields.filter(f => f['category'] == category);
    } else {
      return this.fields.filter(f => !f['category']);
    }
  }

  createLocationPlace() {
    console.log('createLocationPlace() called');
    if (validateForm(this.newLocationForm)) {
      this.newLocationForm.disable();

      this.fields.forEach(field => {
        this.updatePlaceInfo(field.field);
      });


      if (!this.placeInfo['UUID']) {
        this.placeInfo['UUID'] = new Date().getTime();
      }

      console.log('Updating room', this.placeInfo);

      this.placeInfoUpdate.emit([this.placeInfo, this.newLocationForm]);
    } else {
      this.message.snackbar('Bitte fülle alle Felder richtig aus');
    }
  }

  getRoomName(){
    return this.newLocationForm.controls['name'].value;
  }


  showCategoryInfo(category) {
    this.message.dialog(GET_DESCRIPTION(category));
  }

  showFieldInfo(field) {
    if (field['placeholder'] && field['placeholder'].length > 0) {
      this.message.dialog(field['placeholder']);
    } else {
      this.message.dialog(field['label']);
    }
  }

  public getCurrentFields(){
    let fields = {};
    this.fields.forEach(field => {
      fields[field.field] = this.newLocationForm.controls[field.field].value;
    });
    return fields;
  }

  private updatePlaceInfo(field) {
    this.placeInfo[field] = this.newLocationForm.controls[field].value;
  }

  private initFormGroups() {
    this.newLocationForm = new FormGroup({});
    this.fields.forEach(field => {
      this.newLocationForm.addControl(field.field, new FormControl(this.placeInfo[field.field], field.optional ? null : Validators.required));
    });

  }
}
