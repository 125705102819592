import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-divi-btn',
  templateUrl: './divi-btn.component.html',
  styleUrls: ['./divi-btn.component.scss']
})
export class DiviBtnComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
