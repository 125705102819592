import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LocationService } from 'src/app/services/location.service';
import { RoomEventSlotsService } from 'src/app/services/room-event-slots.service';

@Component({
  selector: 'app-location',
  templateUrl: './location.component.html',
  styleUrls: ['./location.component.scss'],
})
export class LocationComponent implements OnInit {
  location: {} = undefined;
  locationId: string = undefined;
  selectedRoom = 0;

  constructor(
    private route: ActivatedRoute,
    private locationService: LocationService,
    private roomEventSlotsService: RoomEventSlotsService
  ) {
    // setTimeout(() => {
    //   this.selectedRoom = 1;
    // }, 15000);
  }

  public ngOnInit(): void {
    this.route.paramMap.subscribe((paramMap) => {
      const locationId = paramMap.get('id');
      if (locationId) {
        this.locationId = locationId;
        this.loadLocation(locationId);
        //this.getLocation(locationId);
      } else {
        console.error('Error no locationId');
      }
    });
  }

  public switchToRoom(room) {
    // console.log('bluu', room);
    // update Current Selection
    // console.log('LocationService:', this.locationService.currentSelection);

    let roomIndex = this.locationService.getRoomIndex(room);
    // console.log('roomIndex:', roomIndex);

    let currentSelection = { location: this.location, room: roomIndex };
    // console.log('currentSelection:', currentSelection);

    this.locationService.updateCurrentSelection(currentSelection);
    this.selectedRoom = roomIndex;
    this.roomEventSlotsService.updateCurrentRoom();
  }

  private async loadLocation(locationId) {
    // (await this.locationService.getMyLocations()).subscribe(
    //   (locations: {}[]) => {
    //     this.locations = locations;
    //     this.location = locations.find((l) => {
    //       return l['customIdName'] === locationId;
    //     });
    //     let room = this.location['rooms'][0];

    //   }
    // );
    let location$ = this.locationService.getLocation(locationId);
    location$.subscribe((l: {}) => {
      this.location = l;
      let currentSelection = { location: this.location, room: 0 };
      this.locationService.updateCurrentSelection(currentSelection);
    });
  }
}
