import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { first } from 'rxjs/operators';
import { ContractService } from 'src/app/services/contract.service';
import { LocationService } from 'src/app/services/location.service';
import { NotificationService } from 'src/app/services/notification.service';
import { notification } from 'src/app/types/notification.type';
import { eventSlotStatus } from 'src/app/utils/constants/eventSlotStatus';
import { requestSlotTypes } from 'src/app/utils/constants/requestSlotTypes';
import {
  getEventlerTxtAcceptance,
  getJsDate,
  getLocationOwners,
  getLocationOwnerTxtAccept,
} from 'src/app/utils/helpers';
import { notificationTypes } from 'src/app/utils/constants/notificationTypes';
import { MatDialogRef } from '@angular/material/dialog';
import { TourService } from 'src/app/services/tour.service';

@Component({
  selector: 'app-accept-slot-request',
  templateUrl: './accept-slot-request.component.html',
  styleUrls: ['./accept-slot-request.component.scss'],
})
export class AcceptSlotRequestComponent implements OnInit {
  public notification: notification;
  public loading: boolean = false;
  public loaded: boolean = false;
  public location: {};
  public eventSlot: {};
  selectedContract = 0;
  request;

  constructor(
    public contractService: ContractService,
    private firestore: AngularFirestore,
    private locationService: LocationService,
    private notificationService: NotificationService,
    private datePipe: DatePipe,
    private tourService: TourService,
    private dialogRef: MatDialogRef<AcceptSlotRequestComponent>
  ) { }

  public async ngOnInit() {
    this.location = await this.locationService
      .getLocation(this.notification['payload']['locationId'])
      .pipe(first())
      .toPromise();
    this.loadEventSlot();
  }

  public acceptRequest() {
    // this.updateSlotBooked();
    this.sendAcceptNotificationToLocation();
    this.sendAcceptNotificationToEventler();
    this.updateTour();
    this.notification['action'] = notificationTypes.accepted;
    this.notificationService.update(this.notification);
    this.dialogRef.close();
  }

  private async updateTour(){
    const tourId = this.notification['payload']['tourId'];
    if(tourId) {
      console.log('FOUND tourID', tourId);
      this.tourService.updateTourStatus(tourId, 'Vertrag zugesendet', this.eventSlot['customIdName']);
    }
  }

  private loadEventSlot() {
    this.eventSlot = this.firestore
      .collection('event-slots')
      .doc(this.notification['payload']['slotId'])
      .valueChanges()
      .subscribe((eventSlot: {}) => {
        this.eventSlot = eventSlot;
        this.request = this.eventSlot['requests'].find(r => r['UUID'] == this.notification['payload']['requestUUID']);
        console.log('request is', this.request);
        this.loaded = true;
      });
  }

  private updateSlotBooked() {
    this.setBookingRequestBooked();
    this.firestore
      .collection('event-slots')
      .doc(this.notification['payload']['slotId'])
      .update(this.eventSlot)
      .then(() => {
        console.info('eventSlot updated');
      });
  }

  private setBookingRequestBooked() {
    for (let i = 0; i < this.eventSlot['requests'].length; i++) {
      const r = this.eventSlot['requests'][i];
      if (r['user'] === this.notification['uid']) {
        r['status'] = eventSlotStatus.booked;
      }
    }
    this.eventSlot['status'] = eventSlotStatus.booked;
  }

  private sendAcceptNotificationToLocation() {
    const payload = this.getPayload();
    const date = this.datePipe.transform(
      getJsDate(this.eventSlot['startSlot']),
      'short'
    );
    // this.sendAcceptEventler(payload, date);
    this.sendAcceptLocOwner(payload, date);
  }

  sendAcceptNotificationToEventler() {
    const date = this.datePipe.transform(
      getJsDate(this.eventSlot['startSlot']),
      'short'
    );



    const msg = getEventlerTxtAcceptance(this.location['name'], date);

    console.log('Sending notification to eventler', msg);
    this.notification['payload']['contract'] = this.location['contractTemplateSrcs'][this.selectedContract];
    this.notificationService.sendNotification(
      this.request['user'],
      msg,
      notificationTypes.signContract,
      this.notification['payload']
    );

  }

  private getPayload() {
    return {
      locationId: this.location['customIdName'],
      roomUUID: this.eventSlot['roomUUID'],
      slotId: this.eventSlot['customIdName'],
      type: requestSlotTypes.singleSlot,
    };
  }

  private sendAcceptEventler(payload, date) {
    const msg = getEventlerTxtAcceptance(this.location['name'], date);
    this.notificationService.sendNotification(
      this.notification['uid'],
      msg,
      notificationTypes.signContract,
      payload
    );
  }

  private sendAcceptLocOwner(payload, date) {
    const msg = getLocationOwnerTxtAccept(this.location['name'], date);
    let locationOwners = getLocationOwners(this.location);

    console.log('Sending notification to location owners', locationOwners, msg);

    for (let i = 0; i < locationOwners.length; i++) {
      const owner = locationOwners[i];
      this.notificationService.sendNotification(
        owner['uid'],
        msg,
        notificationTypes.bookingFinished,
        payload
      );
    }
  }
}
