import { Component, Input, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit {
  @Input() uid = '';
  @Input() show;
  user = {};
  error = false;
  constructor(private firestore: AngularFirestore) { }

  ngOnInit(): void {
    this.subscribeUser();
  }

  subscribeUser() {
    this.error = !this.uid || this.uid.trim().length == 0;
    if (!this.error) {
      this.firestore
        .collection('users')
        .doc(this.uid)
        .valueChanges({idField: 'customIdName'})
        .subscribe((user) => {
          this.user = user;
        });
    }
  }

}
