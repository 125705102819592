<div class="card-round">
    <h1>Tourpläne</h1>
    <div class="ToursContainer">
        <!-- <div class="LabelContainer">
            <div class="labelLine"></div>
            <div class="LabelText">Tourpläne</div>
        </div> -->
        <div class="ToursContentContainer">
            <ng-container>
                <div *ngIf="tours.length === 0">Es sind keine Touren vorhanden</div>

                <ng-container *ngIf="tours.length !== 0">
                    <div *ngFor="let tour of tours">
                        <app-tour [tour]="tour"></app-tour>
                    </div>
                </ng-container>
            </ng-container>
            <!-- <ng-template *ngIf="loaded"> -->
            <!-- </ng-template> -->
        </div>
    </div>
</div>