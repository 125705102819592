<div class="dialog-container">
    <mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>

    <div mat-dialog-title>
        <h1>Venue löschen</h1>
    </div>
    <div mat-dialog-content>
        Möchtest du das Venue <b>{{ location['name'] }}</b> wirklich unwideruflich löschen?
    </div>
    <div mat-dialog-actions class="flex-right">
        <button mat-button mat-dialog-close [disabled]="loading">Abbrechen</button>
        <button mat-raised-button color="warn" (click)="delete()" [disabled]="loading">Löschen</button>
    </div>

</div>