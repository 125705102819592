<form class="dialog-container" (submit)="acceptContract()" ngNativeValidate>
    <mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>

    <h1 mat-dialog-title>Vertrag annehmen</h1>
    <div mat-dialog-content>
        <p class="margin-bottom-32">Bitte akzeptiere den nachfolgenden Vertrag (Öffnen durch Mausklick auf Vertragname).</p>
        <div class="margin-bottom-32">
            <a [href]="contract" matTooltip="Vertrag öffnen" matTooltipPosition="above" target="_blank">{{ contractService.getFileName(contract) }}</a>
        </div>
        <div>
            <mat-checkbox [disabled]="loading" required>
                <div class="hide-mobile">
                    Ich stimme den <a [href]="contract" target="_blank">Vertragsbedingungen</a> zu.
                </div>
                <div class="hide-desktop">
                    Ich stimme den <br>
                    <a [href]="contract" target="_blank">Vertragsbedingungen</a> zu.
                </div>
            </mat-checkbox>
        </div>
    </div>
    <div mat-dialog-actions class="flex-right">
        <button [disabled]="loading" mat-button mat-dialog-close cdkFocusInitial>Abbrechen</button>
        <button [disabled]="loading" type="submit" mat-button>Vertrag annehmen</button>
    </div>
</form>