<form class="card-round page-card" (submit)="saveUser()" ngNativeValidate>
    <div class="space-between">
        <h2>Persönliche Daten</h2>
        <button class="hidden" mat-icon-button routerLink="/profile/{{ userInfo['customIdName'] }}"><mat-icon matTooltip="Öffentliches Profil ansehen" matTooltipPosition="above">launch</mat-icon></button>
    </div>

    <mat-radio-group [disabled]="loading" required [(ngModel)]="userInfo.gender" name="gender" class="margin-bottom-32">
        <mat-radio-button class="margin-right-16" value="Herr">Herr</mat-radio-button>
        <mat-radio-button class="margin-right-16" value="Frau">Frau</mat-radio-button>
        <mat-radio-button value="Divers">Divers</mat-radio-button>
    </mat-radio-group>

    <mat-form-field>
        <mat-label>Vorname</mat-label>
        <input [disabled]="loading" [(ngModel)]="userInfo.firstName" name="firstName" matInput required placeholder="Z.B. Max">
    </mat-form-field>


    <mat-form-field>
        <mat-label>Nachname</mat-label>
        <input [disabled]="loading" [(ngModel)]="userInfo.lastName" name="lastName" matInput required placeholder="Z.B. Mustermann">
    </mat-form-field>

    <mat-form-field>
        <mat-label>Firma</mat-label>
        <input [disabled]="loading" [(ngModel)]="userInfo.company" name="company" matInput required placeholder="Z.B. Muster Firma">
    </mat-form-field>


    <mat-form-field>
        <mat-label>Position</mat-label>
        <input [disabled]="loading" [(ngModel)]="userInfo.position" name="position" matInput required placeholder="Z.B. Management">
    </mat-form-field>

    <mat-form-field>
        <mat-label>Telefon</mat-label>
        <input [disabled]="loading" [(ngModel)]="userInfo.phone" name="phone" required matInput placeholder="Z.B. +49 (0) 30 12345678">
    </mat-form-field>

    <mat-form-field>
        <mat-label>Fax</mat-label>
        <input [disabled]="loading" [(ngModel)]="userInfo.fax" name="fax" matInput placeholder="Z.B. +49 (0) 30 12345678">
    </mat-form-field>

    <mat-form-field>
        <mat-label>E-Mail</mat-label>
        <input disabled matInput [value]="user['email']">
    </mat-form-field>

    <mat-form-field>
        <mat-label>UID</mat-label>
        <input disabled matInput [value]="user['uid']">
    </mat-form-field>

    <div class="flex-right">
        <span class="flex-center-v margin-right-16" *ngIf="showSaveMessage">
            <mat-icon>check</mat-icon>
            Änderungen gespeichert!
        </span>
        <button mat-raised-button [disabled]="loading" color="primary">Speichern</button>
    </div>
</form>