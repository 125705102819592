import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-slideshow',
  templateUrl: './slideshow.component.html',
  styleUrls: ['./slideshow.component.scss']
})
export class SlideshowComponent implements OnInit, AfterViewInit {
  @Input() images = [];
  @Input() index = 0;
  opened = false;
  drawer;
  @ViewChild('img') img;

  constructor() { }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.drawer = document.getElementsByTagName('mat-drawer')[0];
  }

  public open(index) {
    this.drawer.style.zIndex = -2;
    this.toggleAnimation();
    console.log('Opening image', index);
    this.opened = true;
    this.index = index;
  }

  public close() {
    this.opened = false;
    this.drawer.style.zIndex = 2;
    this.resetAnimation();
  }

  private resetAnimation() {
    this.img.nativeElement.style.opacity = '0';
    this.img.nativeElement.style.transform = 'translateY(40px)';
  }


  private toggleAnimation() {
    console.log('this.img', this.img);
    setTimeout(() => {
      this.img.nativeElement.style.opacity = '1';
      this.img.nativeElement.style.transform = 'translateY(0)';
    }, 100);
  }

}
