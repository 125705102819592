<div class="card-round page-card">
    <div class="space-between flex-center-v margin-bottom-32">
        <h2>Deine Venues</h2>

        <button mat-raised-button routerLink="/location-search" color="secondary">
            Venues suchen
            <mat-icon>search</mat-icon>
        </button>

    </div>

    <div *ngIf="(locations | async)?.length == 0" class="margin-bottom-32">
        Du hast noch keines Venue angelegt.
    </div>

    <div>
        <button mat-raised-button (click)="addLocation()" color="primary">Neu hinzufügen</button>
    </div>

    <div class="flex-center-v table-row table-row-header" *ngIf="(locations | async)?.length > 0">
        <div class="width-40-or-50">Name</div>
        <div class="width-20 hide-mobile">
            <!-- Standorte-->
        </div>
        <div class="width-20-or-30">Status</div>
        <div class="width-20">Aktionen</div>

    </div>
    <div class="flex-center-v table-row table-row-selectable" routerLink="/location/edit/{{location?.customIdName}}" [class.no-border]="i == (locations | async)?.length - 1" *ngFor="let location of locations | async; let i = index">
        <div class="width-40-or-50 flex-center-v">
            <mat-icon class="hide-mobile">house</mat-icon> {{ location.name }}
        </div>
        <div class="width-20 flex-center-v hide-mobile">
            <!-- {{ location?.places?.length || '0' }} -->
            <span *ngIf="location && location.places && location.places.length > 0">{{ location?.places[0]?.city
                }}</span>
        </div>
        <div class="width-20-or-30 flex-center-v">
            <div class="statusContainer">
                <div class="status">{{ location.status || 'Wird erstellt'}}</div>
                <div *ngIf="checkNonConformance(location)">
                    <mat-icon #tooltip="matTooltip" matTooltip="Venue ist noch nicht veröffentlicht!&#13;
                        Es muss mindestens 1 Bild hochgeladen sein.&#13;
                        Es muss mindestens 1 Vertrag hochgeladen sein.&#13;
                        Es muss mindestens 1 Buchbares Event geben.&#13;
                        Es muss vom sadey Team geprüft worden sein." [matTooltipClass]="'my-tooltip'">error</mat-icon>
                </div>
            </div>
        </div>
        <div class="width-20 flex-center-v hide-mobile">
            <button mat-icon-button class="flex-center-v flex-center" routerLink="/location/edit/{{location?.customIdName}}" matTooltip="Bearbeiten">
                <mat-icon class="margin-right-0">edit</mat-icon>
            </button>

            <button mat-icon-button class="flex-center-v flex-center" routerLink="/location/{{location.customIdName}}" matTooltip="Vorschau ansehen">
                <mat-icon class="margin-right-0">arrow_right_alt</mat-icon>
            </button>


            <button mat-icon-button class="flex-center-v flex-center" [ngStyle]="{ 'color':'var(--warm-color)'}" (click)="deleteLocation(location, $event)" matTooltip="Venue löschen">
                <mat-icon class="margin-right-0">delete</mat-icon>
            </button>
        </div>

        <!-- Mobile button -->
        <div class="width-20 flex-center-v hide-desktop">
            <button mat-icon-button [matMenuTriggerFor]="menu" (click)="$event.stopPropagation()">
                <mat-icon style="margin-right: 0;">more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
                <button mat-menu-item routerLink="/location/edit/{{location?.customIdName}}">
                    <mat-icon class="margin-right-0">edit</mat-icon> Bearbeiten
                </button>
                <button mat-menu-item routerLink="/location/{{location.customIdName}}">
                    <mat-icon class="margin-right-0">arrow_right_alt</mat-icon> Vorschau
                </button>
                <button mat-menu-item (click)="deleteLocation(location, $event)">
                    <mat-icon class="margin-right-0">delete</mat-icon> Löschen
                </button>
            </mat-menu>
        </div>
    </div>
</div>