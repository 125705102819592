<form class="dialog-container" (submit)="saveInfo()" ngNativeValidate>
    <div mat-dialog-title class="flex-space-between">
        <h1>Nur noch ein Schritt</h1>
    </div>

    <div mat-dialog-content>
        <p>Wir benötigen noch einige kurze Infos.</p>


        <mat-form-field appearance="fill" class="width-100">
            <mat-label>Bitte Typ auswählen</mat-label>
            <mat-select [(ngModel)]="tourType" name="tourType">
                <mat-option value="Konzert">Konzert</mat-option>
                <mat-option value="Theater">Theater</mat-option>
                <mat-option value="Ballet">Ballet</mat-option>
                <mat-option value="Musical">Musical</mat-option>
                <mat-option value="Anderes">Anderes</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field class="width-100">
            <mat-label>Name der Tour</mat-label>
            <input type="text" [(ngModel)]="tourName" name="tourName" required matInput minlength="3" placeholder="Hier den Namen deiner Tour eingeben.">
        </mat-form-field>

        
    </div>


    <div mat-dialog-actions class="flex-right">
        <button mat-button mat-dialog-close>Abbrechen</button>
        <button mat-raised-button color="primary" type="submit">Tour buchen</button>
    </div>

</form>