import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatCalendar } from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import { BookLocationComponent } from 'src/app/components/dialogs/book-location/book-location.component';
import { MessageService } from 'src/app/services/message.service';
import { RoomEventSlotsService } from 'src/app/services/room-event-slots.service';
import { UserService } from 'src/app/services/user.service';
import {
  isNotPastDay,
  isInEventSlots,
  getFilteredEventSlots,
  getBookableSlots,
} from 'src/app/utils/helpers';


//  https://stackoverflow.com/questions/48583243/use-mat-datepicker-directly-without-input
@Component({
  selector: 'app-location-calendar',
  templateUrl: './location-calendar.component.html',
  styleUrls: ['./location-calendar.component.scss'],
})
export class LocationCalendarComponent implements OnInit {
  selectedDate: Date = new Date();
  @ViewChild(MatCalendar) datePicker: MatCalendar<Date>;
  @Input() location: any = {};
  @Input() selectedRoom = 0;

  public caldenarLoadingComplete: boolean = false;
  private eventSlots: {}[];

  constructor(
    private dialog: MatDialog,
    private roomEventSlotsService: RoomEventSlotsService,
    private messageService: MessageService,
    private userService: UserService
  ) {}

  ngOnInit(): void {
    this.subscribeEventSlots();
  }

  public async dateInquiryInput() {
    console.log('Is eventler?', (await this.userService.isEventler()));
    if(!(await this.userService.isEventler())) {
      this.messageService.dialog('Eine Buchung ist nur möglich, wenn Sie als Eventler eingeloggt sind.');
      return;
    }

    let filteredSlots = getFilteredEventSlots(
      this.selectedDate,
      this.eventSlots
    );
    let c = this.dialog.open(BookLocationComponent, { minWidth: '80vw' })
      .componentInstance;
    c.location = this.location;
    c.selectedRoom = this.selectedRoom;
    c.selectedDate = this.selectedDate;
    c.eventSlots = filteredSlots;
    // console.log('dateInquiryInput', this.selectedRoom);
  }

  private subscribeEventSlots() {
    this.roomEventSlotsService.eventSlots$.subscribe((slots: {}[]) => {
      this.eventSlots = getBookableSlots(slots);
      // console.log('subscribe:', slots);
      this.setDatePickerFilter();
      setTimeout(() => {
        this.datePicker.stateChanges.next();
        this.datePicker.updateTodaysDate();
      }, 500);

      setTimeout(() => {
        this.datePicker.updateTodaysDate();
        this.caldenarLoadingComplete = true;
      }, 1000);
    });
  }

  private setDatePickerFilter() {
    this.datePicker.dateFilter = (d: Date) => {
      return isNotPastDay(d) && isInEventSlots(d, this.eventSlots);
    };
  }
}
