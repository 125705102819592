import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { filter, first, switchMap } from 'rxjs/operators';
import { notificationTypes } from '../utils/constants/notificationTypes';
import { getLocationOwners } from '../utils/helpers';
import { EmailService } from './email.service';
import { LocationService } from './location.service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  constructor(
    private authService: AngularFireAuth,
    private datePipe: DatePipe,
    private userService: UserService,
    private emailService: EmailService,
    private locationService: LocationService,
    private firestore: AngularFirestore
  ) { }

  public getMyNotifications() {
    return this.authService.user.pipe(
      filter((user) => {
        return !!user;
      }),
      switchMap((user) => {
        return this.firestore
          .collection('notifications', (ref) =>
            ref.where('uid', '==', user['uid'])
          )
          .valueChanges({ idField: 'customIdName' });
      })
    );
  }

  public async informLocationOwnerAboutBooking(payload) {
    let location = await this.locationService.getLocation(payload['locationId']).pipe(first()).toPromise();
    let user = await this.userService.getMyUser().pipe(first()).toPromise();
    let slot = await this.firestore.collection('event-slots').doc(payload['slotId']).valueChanges().pipe(first()).toPromise();

    let startDate = this.datePipe.transform(slot['startSlot'].toDate(), 'short');
    let msg = `Deine Buchungsanfrage wurde von ${user['firstName']} ${user['lastName']
      } zu deinen Vertragsbedingungen angenommen. 
    Venue: ${location['name']}, Startzeitpunkt: ${startDate}, Kosten: ${slot['bookingFee']} € (brutto). Die Buchung und vetragliche Informationen sind im Archiv einzusehen.
    `;

    location['users'].forEach((user) => {
      this.sendNotification(user['uid'], msg, 'CONTRACT_SIGNED_SUCCESS', {});
    });
  }

  public update(n) {
    return this.firestore
      .collection('notifications')
      .doc(n['customIdName'])
      .update(n);
  }

  public markAllMyNotificationsAsRead() {
    this.getMyNotifications()
      .pipe(first())
      .subscribe((notifications) => {
        const unreadNotifications = notifications.filter((n) => !n['read']);
        unreadNotifications.forEach((n) => {
          n['read'] = new Date();
          this.updateNotification(n);
        });
      });
  }

  public updateNotification(notification) {
    return this.firestore
      .collection('notifications')
      .doc(notification['customIdName'])
      .update(notification);
  }

  public async sendNotification(uid, msg, action, payload) {
    // this.emailService.sendMail(uid, msg, 'Nachricht von SADEY');
    const notification = {
      uid: uid,
      msg: msg,
      createdAt: new Date(),
      action: action,
      payload: payload,
    };

    // console.log('Creating notification', notification);
    return await this.firestore.collection('notifications').add(notification);
  }

  public async createNotification(msg, action, payload) {
    let user = await this.authService.user.pipe(first()).toPromise();
    return await this.sendNotification(user['uid'], msg, action, payload);
  }

  public sendLocationOwnersNotification(
    location: {},
    payload: {},
    notificationType: notificationTypes,
    msg: string
  ) {
    let locationOwners = getLocationOwners(location);
    for (let i = 0; i < locationOwners.length; i++) {
      const owner = locationOwners[i];
      this.sendNotification(owner['uid'], msg, notificationType, payload);
    }
  }
}
