<!-- <div class="locationDetailInformationContainer">
    <div class="contactDetailContainer">
        <div class="contactSize">
            <app-contact-information [location]="location"></app-contact-information>
        </div>
        <div class="detailSize">
            <app-room-info [selectedRoom]="selectedRoom" [location]="location"></app-room-info>
            <!-- <app-details [selectedRoom]="selectedRoom" [location]="location"></app-details> -->

<!--
        </div>
    </div>

    <div class="calendarSize">
        <app-location-calendar [selectedRoom]="selectedRoom" [location]="location"></app-location-calendar>
    </div>
</div> -->




<div class="location-detail-section">
    <app-contact-information [location]="location"></app-contact-information>

    <app-room-info [selectedRoom]="selectedRoom" [location]="location"></app-room-info>



    <app-location-calendar [selectedRoom]="selectedRoom" [location]="location"></app-location-calendar>
</div>