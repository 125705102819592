<h1>Venue suchen</h1>
<div>{{ filter(locations).length }} Ergebnisse gefunden</div>


<div class="margin-top-16">
    <app-tour-planner-filters (selectedCity)="setCity($event)" (selectedStageSize)="setMinimumStageSize($event)"
        (selectedRange)="setRange($event)" (selectedDate)="setBookingDate($event)"
        (selectedPriceRange)="setPriceRange($event)">
    </app-tour-planner-filters>
</div>
<mat-form-field class="width-100">
    <mat-label>Name filtern</mat-label>
    <input matInput type="text" [(ngModel)]="locationSearchQuery">

    <button mat-button *ngIf="locationSearchQuery" matSuffix mat-icon-button aria-label="Clear"
        (click)="locationSearchQuery=''">
        <mat-icon>close</mat-icon>
    </button>
</mat-form-field>

<div class="card-round location-card" *ngFor="let location of filter(locations)"
    routerLink="/location/{{location['customIdName']}}">
    <img *ngIf="location['rooms'][0].images && location['rooms'][0].images.length > 0; else no_image"
        class="location-card-image" [src]="location['rooms'][0].images[0]">
    <ng-template #no_image>
        <div class="location-card-image graybox">
            Kein Bild vorhanden
        </div>
    </ng-template>

    <div class="location-card-info">
        <h2>{{ location['name'] }}</h2>
        <div *ngIf="location['places']" class="flex-center-v">
            <mat-icon class="color-gray">location_on</mat-icon>
            <span class="color-gray">{{ location['street'] }} {{ location['houseNumber'] }},
                {{ location['zipCode'] }} {{ location['city'] }} ({{ getDistance(location) }}
                km)</span>
        </div>

        <div>Angebote von {{ getCheapestEventSlot(location['customIdName']) }} - {{
            getHighestEventSlot(location['customIdName']) }} €</div>

        <div class="margin-top-16">
            <div *ngIf="getFittingRooms(location).length != 1">
                <b>{{ getFittingRooms(location).length }}</b> passende Räume gefunden.
            </div>

            <div *ngIf="getFittingRooms(location).length == 1">
                <b>{{ getFittingRooms(location).length }}</b> passender Raum gefunden.
            </div>

            <ul>
                <li *ngFor="let room of getFittingRooms(location)">
                    <a [routerLink]="['/location/'+location['customIdName']]"  [queryParams]="{room: room['UUID']}">
                        {{ room['name'] }}
                    </a>
                </li>
            </ul>
        </div>
    </div>
</div>