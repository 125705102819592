import { Component, Input, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

@Component({
  selector: 'app-event-slot-request',
  templateUrl: './event-slot-request.component.html',
  styleUrls: ['./event-slot-request.component.scss']
})
export class EventSlotRequestComponent implements OnInit {

  @Input() slot = '';
  @Input() requestUUID = '';
  currentSlot;
  currentRequest;
  constructor(private firestore: AngularFirestore) { }

  ngOnInit(): void {
    this.resolveSlot();
  }


  private resolveSlot() {
    this.firestore
      .collection('event-slots')
      .doc(this.slot)
      .valueChanges()
      .subscribe((slot) => {
        if (!slot) {
          console.info('Could not load slot', this.slot, 'It might have been deleted.');
        } else {
          this.currentSlot = slot;
          this.currentRequest = slot['requests'].find(r => r['UUID'] == this.requestUUID);
          console.log('currentRequest', this.currentRequest);
        }
      });
  }

}
