import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';
import { NgxAuthFirebaseUIModule } from 'ngx-auth-firebaseui';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatRippleModule } from '@angular/material/core';
import { LandingComponent } from './components/landing/landing.component';
import { ProfileComponent } from './components/profile/profile.component';
import { MyLocationsComponent } from './components/my-locations/my-locations.component';
import { MediaComponent } from './components/media/media.component';
import { CalendarComponent } from './components/calendar/calendar.component';
import { MailboxComponent } from './components/mailbox/mailbox.component';
import { ArchiveComponent } from './components/archive/archive.component';
import { ContractsComponent } from './components/contracts/contracts.component';
import { ImprintComponent } from './components/imprint/imprint.component';
import { MatInputModule } from '@angular/material/input';
import { AngularFireModule } from '@angular/fire';
import { environment } from '../environments/environment';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AddLocationComponent } from './components/dialogs/add-location/add-location.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EditLocationComponent } from './components/edit-location/edit-location.component';
import { TrusticonsComponent } from './components/landing/trusticons/trusticons.component';
import { LocationComponent } from './components/location/location.component';
import { ImageSliderComponent } from './components/location/image-slider/image-slider.component';
import { ChangeTextComponent } from './components/landing/change-text/change-text.component';
import { MatPasswordStrengthModule } from '@angular-material-extensions/password-strength';
import { EditPlaceComponent } from './components/edit-location/edit-place/edit-place.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { RegistrationComponent } from './components/dialogs/registration/registration.component';
import { LoginComponent } from './components/dialogs/login/login.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { LocationPickerComponent } from './components/my-locations/location-picker/location-picker.component';
import { MatSelectModule } from '@angular/material/select';
import { MatBadgeModule } from '@angular/material/badge';
import { DetailInformationComponent } from './components/location/detail-information/detail-information.component';
import { ContactInformationComponent } from './components/location/detail-information/contact-information/contact-information.component';
import { DetailsComponent } from './components/location/detail-information/details/details.component';
import { LocationCalendarComponent } from './components/location/detail-information/location-calendar/location-calendar.component';
import { MatTabsModule } from '@angular/material/tabs';
import { MatDividerModule } from '@angular/material/divider';
import { MatListModule } from '@angular/material/list';
import { LocationStatusInfoComponent } from './components/my-locations/location-status-info/location-status-info.component';
import { GenerateMapsUrlPipe } from 'src/app/components/location/detail-information/details/generate-maps-url.pipe';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { TourPlannerComponent } from './components/tour-planner/tour-planner.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatSliderModule } from '@angular/material/slider';
import { SelectedLocationsComponent } from './components/tour-planner/selected-locations/selected-locations.component';
import { TourPlannerSearchResultComponent } from './components/tour-planner/tour-planner-search-result/tour-planner-search-result.component';
import { AgmCoreModule } from '@agm/core';
import { LocationMessageComponent } from './components/dialogs/location-message/location-message.component';
import { MatMenuModule } from '@angular/material/menu';
import { MatExpansionModule } from '@angular/material/expansion';
import { TourPlannerFiltersComponent } from './components/tour-planner/tour-planner-filters/tour-planner-filters.component';
import { DatePipe, registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import { LocationMiniInfoComponent } from './components/tour-planner/location-mini-info/location-mini-info.component';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { ExamineLocationComponent } from './components/examine-location/examine-location.component';
import { LocationCardComponent } from './components/examine-location/location-card/location-card.component';
import { MatRadioModule } from '@angular/material/radio';
import { AddEventComponent } from './components/dialogs/add-event/add-event.component';
import {
  NgxMatDatetimePickerModule,
  NgxMatNativeDateModule,
  NgxMatTimepickerModule,
} from '@angular-material-components/datetime-picker';
import { LocationAccessComponent } from './components/dialogs/location-access/location-access.component';
import { BookLocationComponent } from './components/dialogs/book-location/book-location.component';
import { MatCardModule } from '@angular/material/card';
import { DeleteLocationComponent } from './components/dialogs/delete-location/delete-location.component';
import { ShowRequestsComponent } from './components/mailbox/show-requests/show-requests.component';
import { UserComponent } from './components/utils/user/user.component';
import { CancelRequestComponent } from './components/mailbox/show-requests/cancel-request/cancel-request.component';
import { AcceptRequestComponent } from './components/mailbox/show-requests/accept-request/accept-request.component';
import { MatChipsModule } from '@angular/material/chips';
import { PublicProfileComponent } from './components/profile/public-profile/public-profile.component';
import { SlideshowComponent } from './components/utils/slideshow/slideshow.component';
import { GuidelineComponent } from './components/guideline/guideline.component';
import { ChooseEventSlotComponent } from './components/dialogs/choose-event-slot/choose-event-slot.component';
import { SignContractComponent } from './components/mailbox/show-requests/sign-contract/sign-contract.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ErrorComponent } from './components/dialogs/error/error.component';
import { TranslateModule } from '@ngx-translate/core';
import { LocationSearchComponent } from './components/location-search/location-search.component';
import { DiviTutorialComponent } from './components/landing/divi-tutorial/divi-tutorial.component';
import { DiviBtnComponent } from './components/landing/divi-tutorial/divi-btn/divi-btn.component';
import { TourInfoComponent } from './components/tour-info/tour-info.component';
import { TourComponent } from './components/tour-info/tour/tour.component';
import { TourSlotComponent } from './components/tour-info/tour/tour-slot/tour-slot.component';
import { AotMobileComponent } from './components/landing/aot-mobile/aot-mobile.component';
import { AgbComponent } from './components/legal/agb/agb.component';
import { WiderrufComponent } from './components/legal/widerruf/widerruf.component';
import { DataProtectionComponent } from './components/legal/data-protection/data-protection.component';
import { WelcomeMessageComponent } from './components/dialogs/welcome-message/welcome-message.component';
import { ReleaseVenueComponent } from './components/dialogs/release-venue/release-venue.component';
import { RoomPickerComponent } from './components/my-locations/room-picker/room-picker.component';
import { RoomInfoComponent } from './components/location/detail-information/room-info/room-info.component';
import { RoomInfoFieldsComponent } from './components/location/detail-information/room-info-fields/room-info-fields.component';
import { MessageDialogComponent } from './components/dialogs/message-dialog/message-dialog.component';
import { LocationRoomPickerComponent } from './components/utils/location-room-picker/location-room-picker.component';
import { AcceptSlotRequestComponent } from './components/mailbox/show-requests/accept-slot-request/accept-slot-request.component';
import { EventSlotRequestComponent } from './components/mailbox/event-slot-request/event-slot-request.component';
import { MyLocationsSwitchComponent } from './components/my-locations/my-locations-switch/my-locations-switch.component';
import { LocationNameComponent } from './components/utils/location-name/location-name.component';
import { AotNewComponent } from './components/landing/aot-new/aot-new.component';
import { EventSlotSelectorComponent } from './components/dialogs/choose-event-slot/event-slot-selector/event-slot-selector.component';
import { EnterTourDataComponent } from './components/dialogs/enter-tour-data/enter-tour-data.component';

registerLocaleData(localeDe);

@NgModule({
  declarations: [
    AppComponent,
    LandingComponent,
    ProfileComponent,
    MyLocationsComponent,
    MediaComponent,
    CalendarComponent,
    MailboxComponent,
    ArchiveComponent,
    ContractsComponent,
    ImprintComponent,
    DataProtectionComponent,
    AddLocationComponent,
    EditLocationComponent,
    TrusticonsComponent,
    LocationComponent,
    ImageSliderComponent,
    ChangeTextComponent,
    EditPlaceComponent,
    RegistrationComponent,
    LoginComponent,
    LocationPickerComponent,
    DetailInformationComponent,
    ContactInformationComponent,
    DetailsComponent,
    LocationCalendarComponent,
    LocationStatusInfoComponent,
    GenerateMapsUrlPipe,
    TourPlannerComponent,
    SelectedLocationsComponent,
    TourPlannerSearchResultComponent,
    LocationMessageComponent,
    TourPlannerFiltersComponent,
    LocationMiniInfoComponent,
    ExamineLocationComponent,
    LocationCardComponent,
    AddEventComponent,
    LocationAccessComponent,
    BookLocationComponent,
    DeleteLocationComponent,
    ShowRequestsComponent,
    UserComponent,
    CancelRequestComponent,
    AcceptRequestComponent,
    PublicProfileComponent,
    SlideshowComponent,
    GuidelineComponent,
    ChooseEventSlotComponent,
    SignContractComponent,
    ErrorComponent,
    LocationSearchComponent,
    DiviTutorialComponent,
    DiviBtnComponent,
    TourInfoComponent,
    TourComponent,
    TourSlotComponent,
    AotMobileComponent,
    AgbComponent,
    WiderrufComponent,
    WelcomeMessageComponent,
    ReleaseVenueComponent,
    RoomPickerComponent,
    RoomInfoComponent,
    RoomInfoFieldsComponent,
    MessageDialogComponent,
    LocationRoomPickerComponent,
    AcceptSlotRequestComponent,
    EventSlotRequestComponent,
    MyLocationsSwitchComponent,
    LocationNameComponent,
    AotNewComponent,
    EventSlotSelectorComponent,
    EnterTourDataComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatCardModule,
    MatToolbarModule,
    MatIconModule,
    MatCheckboxModule,
    FormsModule,
    MatButtonModule,
    MatSidenavModule,
    MatRippleModule,
    MatExpansionModule,
    MatChipsModule,
    MatInputModule,
    TranslateModule.forRoot({
      defaultLanguage: 'de',
    }),
    NgxAuthFirebaseUIModule.forRoot(
      environment.firebaseConfig,
      () => 'SADEY-DEMO',
      environment.ngxAuthfirebaseUIConfig
    ),
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFirestoreModule,
    AngularFireStorageModule,
    MatDialogModule,
    MatProgressBarModule,
    ReactiveFormsModule,
    MatPasswordStrengthModule,
    MatSnackBarModule,
    MatTooltipModule,
    MatButtonToggleModule,
    MatSelectModule,
    MatBadgeModule,
    MatListModule,
    MatTabsModule,
    MatDividerModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatAutocompleteModule,
    MatSliderModule,
    MatMenuModule,
    AgmCoreModule.forRoot({
      apiKey: environment.googleMapsApiKey,
    }),
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
    MatRadioModule,
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    NgxMatNativeDateModule,
  ],
  providers: [{ provide: LOCALE_ID, useValue: 'de' }, DatePipe],
  bootstrap: [AppComponent],
})
export class AppModule {}
