import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-image-slider',
  templateUrl: './image-slider.component.html',
  styleUrls: ['./image-slider.component.scss'],
})
export class ImageSliderComponent implements OnInit {
  dummyImages: string[] = [];
  @Input() selectedRoom = 0;
  @Input() location;

  constructor() {
    this.createDummyImages();
  }


  ngOnInit(): void { }


  private createDummyImages() {
    this.dummyImages.push(`assets/img/locationDummyImgs/dummy_0.JPG`);
    this.dummyImages.push(`assets/img/locationDummyImgs/dummy_1.JPG`);
    this.dummyImages.push(`assets/img/locationDummyImgs/dummy_2.JPG`);
    this.dummyImages.push(`assets/img/locationDummyImgs/dummy_3.JPG`);
  }
}
