import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class KeyValueService {

  map = {};

  constructor() { }

  set(key, value) {
    this.map[key] = value;

    console.log('Updated map', this.map);
  }

  get(key) {
    return this.map[key] || '';
  }
}
