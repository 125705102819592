import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { LoginComponent } from '../login/login.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AngularFirestore } from '@angular/fire/firestore';
import { TranslateService } from '@ngx-translate/core';
import { NGX_AUTH_FIREBASEUI_REG } from 'src/app/utils/constants/authFormTranslations'
import { Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss']
})
export class RegistrationComponent implements OnInit {

  userType: string;
  user = { firstName: '', lastName: '', company: '', position: '', phone: '', userType: '', email: '', profileComplete: false };
  newUSer: any;

  constructor(
    public dialogRef: MatDialogRef<RegistrationComponent>,
    private dialog: MatDialog,
    private firestore: AngularFirestore,
    public translate: TranslateService,
    private router: Router,
    private userService: UserService
  ) {
    // this language will be used as a fallback when a translation isn't found in the current language
    translate.setDefaultLang('de');

    // the lang to use, if the lang isn't available, it will use the current loader to get them
    translate.use('de');

    translate.setTranslation('de', NGX_AUTH_FIREBASEUI_REG);
  }

  ngOnInit(): void {
  }

  regSuccessful(authUser: any) {
    console.log(authUser);
    this.user.userType = this.userType;
    this.user.email = authUser.email.toLowerCase();
    this.splitName(authUser.displayName);
    this.firestore
      .collection('users')
      .doc(authUser.uid)
      .set(this.user)
      .then(() => {
        // this.router.navigate(['profile']);
        this.dialogRef.close();
        this.userService.logoutUser()
        this.userService.sendVerificationMail();
        this.openLoginDialog(true);
      });
  }

  openLoginDialog(needsVerification: boolean = false) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      needsVerification
    };
    this.dialog.open(LoginComponent, dialogConfig);
    this.dialogRef.close();
  }

  splitName(name: string) {
    name.trim();
    if (name) {
      let spacePos = name.search(" ");
      if (spacePos == -1)
        this.user.firstName = name;
      else {
        this.user.firstName = name.slice(0, spacePos);
        this.user.lastName = name.slice(spacePos + 1);
      }
    }
  }
}
