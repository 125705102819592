import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-welcome-message',
  templateUrl: './welcome-message.component.html',
  styleUrls: ['./welcome-message.component.scss']
})
export class WelcomeMessageComponent implements OnInit {

  user = {};


  constructor(private ref: MatDialogRef<WelcomeMessageComponent>, private userService: UserService) { }

  ngOnInit(): void {
    this.subscribeMyUser();
  }


  markAsRead() {
    this.user['lastMessageAccepted'] = new Date().getTime();
    this.userService.updateUser(this.user);
    this.ref.close();
  }

  private subscribeMyUser() {
    this.userService.getMyUser()
      .subscribe((user) => {
        this.user = user;
      });
  }

}
