import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { DomSanitizer } from '@angular/platform-browser';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { Subject } from 'rxjs';
import { first } from 'rxjs/operators';
import { ContractService } from 'src/app/services/contract.service';
import { FileUploadService } from 'src/app/services/file-upload.service';
import { LocationService } from 'src/app/services/location.service';
import { UserService } from 'src/app/services/user.service';
import { MyLocationsSwitchComponent } from '../my-locations/my-locations-switch/my-locations-switch.component';

@Component({
  selector: 'app-contracts',
  templateUrl: './contracts.component.html',
  styleUrls: ['./contracts.component.scss']
})
export class ContractsComponent implements OnInit {

  MAX_DOC_UPLOAD = 8;
  MAX_DOC_SIZE = 5000000; //BYTES
  DOC_TYPES = /\.(pdf)$/;
  // userId: string;
  // user = { contractTemplateSrcs: [], accountUnlocked: false };
  locationId: string = '';
  pathToFile: string;
  location: any;
  url = new Subject<string>();
  url$ = this.url.asObservable()
  @ViewChild('locationSwitch') locationSwitch: MyLocationsSwitchComponent;
  // location = new Subject<any>();
  // location$ = this.location.asObservable()

  constructor(
    public contractService: ContractService,
    private firestore: AngularFirestore,
    private storage: AngularFireStorage,
    private sanitizer: DomSanitizer,
    private fileUpload: FileUploadService,
    private userService: UserService,
    public locationService: LocationService) {
  }

  ngOnInit(): void {
    this.fileUpload.maxSize = this.MAX_DOC_SIZE;
    this.fileUpload.fileAcceptedTypes = this.DOC_TYPES;
    this.fileUpload.url = this.url;

    this.url.subscribe(async url => {
      await this.updateUserContractTemplateSrcs(url);
    });
  }

  getUrl(url: any) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }



  downloadFile(url: string) {
    window.open(url, '_blank');
  }

  async updateUserContractTemplateSrcs(url: string) {
    await this.firestore
      .collection('locations')
      .doc(this.locationSwitch.currentLocation.customIdName)
      .update({ contractTemplateSrcs: firebase.firestore.FieldValue.arrayUnion(url) })
  }

  async removeFile(url: string) {
    this.storage.refFromURL(url).delete();
    await this.firestore
      .collection('locations')
      .doc(this.locationSwitch.currentLocation.customIdName)
      .update({ contractTemplateSrcs: firebase.firestore.FieldValue.arrayRemove(url) });
  }

  async handleDocInput(files: FileList) {
    const userId = await this.userService.getMyId().pipe(first()).toPromise()
    this.fileUpload.fileToUpload.next({file: files[0], userId });
  }
}
