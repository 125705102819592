<div class="flex-center aot-logo flex-column flex-center-v">
    <img class="top-image" src="assets/img/sadey_logo_opener_desktop.png">

    <!-- <img src="assets/img/sadey_loves_events_schriftzug.svg"> -->
</div>

<!-- <div class="flex-center margin-top-32 text-center welcome-text">
    We optimize your ROI.<br> Die Plattform für die Profis der Konzert- und Veranstaltungswirtschaft.<br> Veranstaltungsplanung & -organisation bietet das größte Potenzial für die Optimierung durch Digitalisierung.
</div>


<div class="margin-top-32 benefits">
    <p class="text-center">
        Live buchen aka Instant Booking <span>|</span> vollautomatisierter RFP <span>|</span> 100%ige Vorqualifizierung
    </p>



    <p class="text-center">
        agieren statt reagieren & optionieren <span>|</span> intelligente Tools <span>|</span> Routing Optimizer
    </p>

    <p class="text-center">
        volle Transparenz zu Verfügbarkeit, Preis & Technik <span>|</span> dynamic pricing<span>|</span> unique Datapool
    </p>

    <p class="text-center">
        Tournee Buchungen smart & simpel <span>|</span> digitale Verträge & Archivierung
    </p>
</div>



<div class="flex-center margin-top-32 margin-bottom-32 see-more-section">
    <img (click)="scroll('tutorial')" [class.transparent]="arrowHidden" class="margin-top-32" src="assets/img/sadey_pfeil_scroll_unten.svg">
</div> -->