import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';

@Pipe({
  name: 'generateMapsUrl',
})
export class GenerateMapsUrlPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}
  transform(
    location: unknown,
    street: string,
    houseNumber: string,
    city: string
  ): SafeUrl {
    let string =
      `https://www.google.com/maps/embed/v1/place` +
      `?key=` +
      `${environment.firebaseConfig.apiKey}` +
      `&q=${street}+${houseNumber},${city}
    `;
    return this.sanitizer.bypassSecurityTrustResourceUrl(string);
  }
}
