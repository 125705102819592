<div class="dialog-container access-container">
    <mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>

    <h1 mat-dialog-title>Nutzer verwalten</h1>

    <div mat-dialog-content>

        <mat-form-field class="example-full-width">
            <input type="text" placeholder="E-Mail suchen..." matInput [formControl]="searchControl" [matAutocomplete]="auto">
            <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                <mat-option (onSelectionChange)="selectUser($event)" *ngFor="let option of filteredUsers; let i = index" [value]="option">
                    <div class="search-results">
                        {{ option.firstName }} {{ option.lastName }} <br>
                        <span class="font-email">{{ option.email }}</span>
                    </div>
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>

        <div class="flex-center-v table-row table-row-header">
            <div class="width-20"></div>
            <div class="width-40">Name</div>
            <div class="width-20">Rolle</div>
            <div class="width-20">Löschen</div>

        </div>

        <div class="flex-center-v table-row" *ngFor="let u of location['users']; let i = index">
            <!-- TODO [class.no-border]="i == (locations | async)?.length - 1"  -->
            <div class="width-20 flex-center-v">
                <img class="profile-picture" src="assets/img/profile.png">
            </div>

            <div class="width-40 flex-center-v">
                {{ u['displayName'] }}
            </div>

            <div class="width-20 flex-center-v">
                {{ u['role'] }}
            </div>

            <div class="width-20 flex-center-v">
                <button [disabled]="u['role'] == 'Owner'" (click)="deleteUser(i)" mat-icon-button color="warn" matTooltip="Nutzer entfernen" matTooltipPosition="above">
          <mat-icon class="no-margin">delete</mat-icon>
        </button>
            </div>

        </div>
    </div>

    <div mat-dialog-actions class="flex-right">
        <button mat-raised-button mat-dialog-close color="primary">Schließen</button>
    </div>
</div>