<!-- <app-show-requests [showArchive]="true"></app-show-requests> -->

<div class="card-round">
    <h1>Archiv</h1>
    <div *ngFor="let contract of contracts" class="contract">
        <div class="date">{{contract['signedAt'].toDate() | date:'shortDate'}}</div>
        <div>
            <app-location-name [id]="contract['extraInformation']['locationId']"></app-location-name>
            <app-event-slot-request [slot]="contract['extraInformation']['slotId']" [requestUUID]="contract['extraInformation']['requestUUID']"></app-event-slot-request>
            <div>
                <a [href]="contract['extraInformation']['contract']" target="_blank">Vertrag anzeigen</a>
            </div>
        </div>

    </div>

    <div *ngIf="contracts.length == 0">
        Hier werden deine unterschriebenen Verträge angezeigt.
    </div>
</div>