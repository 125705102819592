import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { KeyValueService } from 'src/app/services/key-value.service';

@Component({
  selector: 'app-enter-tour-data',
  templateUrl: './enter-tour-data.component.html',
  styleUrls: ['./enter-tour-data.component.scss']
})
export class EnterTourDataComponent implements OnInit {
  public tourType = 'Konzert';
  public tourName = '';

  constructor(private dialogRef: MatDialogRef<EnterTourDataComponent>,
    private store: KeyValueService) { }

  ngOnInit(): void {
    this.tourName = this.store.get('tourDescription');
  }


  public saveInfo() {
    this.dialogRef.close({
      tourType: this.tourType,
      tourName: this.tourName
    });
  }

}
