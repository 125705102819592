import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { first } from 'rxjs/operators';
import { LocationService } from 'src/app/services/location.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-archive',
  templateUrl: './archive.component.html',
  styleUrls: ['./archive.component.scss']
})
export class ArchiveComponent implements OnInit {
  contracts = [];

  constructor(private locationService: LocationService, 
    private userService: UserService,
    private firestore: AngularFirestore) { }

  ngOnInit(): void {
    this.getContracts();
  }


  private async getContracts() {
    let myLocations = (await this.locationService.getMyLocationsOnce()).map(l => l['customIdName']);
    console.log('myLocations', myLocations);

    if (myLocations.length > 0) {
      this.firestore
        .collection('signed-contracts', ref => ref.where('locationId', 'in', myLocations))
        .valueChanges()
        .subscribe((contracts) => {
          console.log('contracts are', contracts);
          this.contracts = contracts.sort((c1, c2) => {
            return c2['signedAt'].toDate().getTime() - c1['signedAt'].toDate().getTime();
          });
        });

    } else {
      const myId = await this.userService.getMyId().pipe(first()).toPromise();
      this.firestore
      .collection('signed-contracts', ref => ref.where('user', '==', myId))
      .valueChanges()
      .subscribe((contracts) => {
        console.log('contracts are', contracts);
        this.contracts = contracts.sort((c1, c2) => {
          return c2['signedAt'].toDate().getTime() - c1['signedAt'].toDate().getTime();
        });
      });
    }
  }

}
