<div *ngIf="loaded && selectedBookingDate">
  <b><a matTooltip="Alle Informationen anzeigen" href="/location/{{location['customIdName']}}" target="_blank">{{
      location['name'] }}</a></b><br>

  <div>
    <p *ngFor="let bookableSlot of bookableSlots">

      Raum: <span *ngIf="bookableSlot['room']">{{ bookableSlot['room']['name'] }}</span>
      <span *ngIf="!bookableSlot['room']">Nicht vorhanden </span>
      
      <br>
      
      {{ bookableSlot['daySlot']['startSlot'] | date: 'shortDate'}}, {{ bookableSlot['daySlot']['startSlot'] | date:
      'H:mm'}}h - {{ bookableSlot['daySlot']['endSlot'] | date: 'H:mm' }}h
      <br>
      {{ bookableSlot['daySlot']['bookingFee'] }}€
    </p>
  </div>

  <button (click)="emit()" mat-raised-button color="primary">Hinzufügen</button>
</div>
<!-- Preisspanne: {{ minEventPrice }} bis {{ maxEventPrice }}€ / Nacht <br> -->