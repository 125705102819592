import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-detail-information',
  templateUrl: './detail-information.component.html',
  styleUrls: ['./detail-information.component.scss'],
})
export class DetailInformationComponent implements OnInit {
  @Input() selectedRoom = 0;
  @Input() location: any;

  constructor() {}


  ngOnInit(): void {}
}
