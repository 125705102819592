import { ConvertKeysToLowerCase } from "src/app/utils/helpers";

export const LOCATION_FIELDS = [
  { field: 'name', label: 'Name', placeholder: 'Z.B. Kulturfabrik' },

  {
    field: 'street',
    label: 'Straße',
    placeholder: 'Z.B. Musterstr.',
    category: 'KONTAKT',
  },
  {
    field: 'houseNumber',
    label: 'Hausnummer',
    placeholder: 'Z.B. 1',
    category: 'KONTAKT',
  },
  {
    field: 'zipCode',
    label: 'Postleitzahl',
    placeholder: 'Z.B.  81669',
    category: 'KONTAKT',
  },
  {
    field: 'city',
    label: 'Stadt',
    placeholder: 'Z.B. München',
    category: 'KONTAKT',
  },

  {
    field: 'website',
    label: 'Webseite',
    placeholder: 'Z.B. https://www.sadey.de',
    optional: true,
    category: 'KONTAKT',
  },

  {
    field: 'ansprechperson',
    label: 'Name Ansprechperson',
    placeholder: 'Name Ansprechperson',
    category: 'KONTAKT',
  },

  {
    field: 'phone',
    label: 'Telefonnummer',
    placeholder: 'Z.B. 089 123 4567',
    category: 'KONTAKT',
  },
  {
    field: 'mail',
    label: 'E-Mail',
    placeholder: 'Z.B. 089 123 4567',
    category: 'KONTAKT',
  },

  {
    field: 'responsiblePerson',
    label: 'Name',
    placeholder: '',
    type: '',
    category: 'KONTAKT BÜHNENMEISTER',
    optional: false,
  },
  {
    field: 'responsiblePersonTelefon',
    label: 'Handynummer',
    placeholder: '+49 123456789',
    type: 'text',
    category: 'KONTAKT BÜHNENMEISTER',
    optional: false,
  },
  {
    field: 'responsiblePersonEmail',
    label: 'Mail',
    placeholder: 'buehnenmeister@deinelocation.de',
    type: 'email',
    category: 'KONTAKT BÜHNENMEISTER',
    optional: false,
  },

  {
    field: 'parkplatzInfo',
    label: 'Parkplätze Produktion',
    placeholder: 'Wo sind diese? Am Hinterbühneneingang? Wie Viele?',
    type: '',
    category: 'LAGE & ANFAHRT',
    optional: false,
  },
  {
    field: 'anfahrt',
    label: 'Anfahrt Crew & Cast',
    placeholder:
      'Wo ist derHinterbühneneingang? Ist er ausgeschildert? Ist die Adresse abweichend von Hauptadresse (siehe Adresse im Venue)?',
    type: 'text',
    category: 'LAGE & ANFAHRT',
    optional: false,
  },
  {
    field: 'lage',
    label: 'Lage',
    placeholder:
      'gibt es in Laufnähe des Venues Geschäfte des alltägl. Lebens? Supermarkt, Drogerie, Apotheke, Bank? Wie weit ist die Innenstadt?',
    type: 'email',
    category: 'LAGE & ANFAHRT',
    optional: false,
  },
  {
    field: 'hotel',
    label: 'Hotel',
    placeholder:
      'gibt es ein direkt angeschlossenes Hotel? oder Empfehlungen? Gibt es Partner Konditionen?',
    type: 'email',
    category: 'LAGE & ANFAHRT',
    optional: false,
  },
  {
    field: 'restaurant',
    label: 'Restaurant',
    placeholder: 'Verfügt das Venue über ein eigenes Restaurant?',
    type: 'email',
    category: 'LAGE & ANFAHRT',
    optional: false,
  },

  {
    field: 'hauptpersonal',
    label: 'Hauspersonal allg.',
    placeholder:
      'wieviel Hauspersonal gibt es? ist das Personal während der techn. Einrichtung vor Ort und/oder erreichbar?',
    type: 'text',
    category: 'PERSONAL',
    optional: false,
  },
  {
    field: 'garderobenpersonal',
    label: 'Garderobenpersonal',
    placeholder:
      'In welcher Höhe wird die Garderobe in der Regel besetzt, in der Hallenmiete inklusive? ',
    type: 'text',
    category: 'PERSONAL',
    optional: false,
  },
  {
    field: 'ordnerpersonal',
    label: 'Ordnerpersonal/ Sicherheitspersonal',
    placeholder:
      'In welcher Höhe wird in der Regel besetzt, in der Hallenmiete inklusive?',
    type: 'text',
    category: 'PERSONAL',
    optional: false,
  },
  {
    field: 'einlasspersonal',
    label: 'Einlasspersonal',
    placeholder:
      'In welcher Höhe wird in der Regel besetzt, in der Hallenmiete inklusive?',
    type: 'text',
    category: 'PERSONAL',
    optional: false,
  },
  {
    field: 'abendkasse',
    label: 'Abendkasse',
    placeholder:
      'Wer stellt in der Regel die Abendkasse? Mit welchem System arbeitet ihr? Besonderheiten?',
    type: 'text',
    category: 'PERSONAL',
    optional: false,
  },
  {
    field: 'sonstiges',
    label: 'sonstiges',
    placeholder:
      'zB.: Freiwillige Feuerwehr, Falls Personal exklusive: gibt es hier Standardpakete, die in der Regel dazu gebucht werden?',
    type: 'text',
    category: 'PERSONAL',
    optional: false,
  },

  {
    field: 'cloakroom',
    label: 'Garderoben in Anzahl',
    placeholder: 'Wie viele Garderoben gibt es insgesamt?',
    type: 'number',
    category: 'GARDEROBEN',
    optional: false,
  },
  {
    field: 'cloakroomText',
    label: 'Garderoben in Text',
    placeholder:
      'Welche Art von Garderoben gibt es? Künstlergarderoben? Mehrzweckräume? Wo liegen diese in Entfernung zur Bühne?',
    type: 'text',
    category: 'GARDEROBEN',
    optional: false,
  },
  {
    field: 'kitchen',
    label: 'Küche',
    placeholder:
      'Gibt es eine eingerichtete Küche für Crewcatering? Gibt es einen weiteren Raum ohne Geräte für Crew/Castküche?',
    type: 'text',
    category: 'GARDEROBEN',
    optional: false,
  },
  {
    field: 'washingMachineDryer',
    label: 'Waschmaschine & Trockner',
    placeholder:
      'Verfügt das Venue über Waschmaschine und Trockner zur freien Nutzung der Produktion? Wenn ja, wo?',
    type: 'text',
    category: 'GARDEROBEN',
    optional: false,
  },
  {
    field: 'waterconnection',
    label: 'Wasseranschlüsse',
    placeholder: 'Wo sind die Wasseranschlüsse? Wieviele gibt es?',
    type: 'text',
    category: 'GARDEROBEN',
    optional: false,
  },

  {
    field: 'nightlinerAnfahrt',
    label: 'Anfahrt',
    placeholder:
      'Gibt es für den Nightliner eine gesonderte Anfahrt? Adresse abweichend von Hauptadresse (siehe Adresse im Venue)?',
    type: 'text',
    category: 'INFOS FÜR NIGHTLINER',
    optional: false,
  },
  {
    field: 'nightlinerParkplatz',
    label: 'Parkplatz',
    placeholder:
      'Gibt es hier einen klar zugewiesenen Bereich? Ist dieser auch Nachts zu finden? Ist dieser im Hinterbühnenbereich?',
    type: 'text',
    category: 'INFOS FÜR NIGHTLINER',
    optional: false,
  },
  {
    field: 'anschluesseNightliner',
    label: 'Anschlüsse für Nightliner',
    placeholder:
      'Wo findet der Nightliner Nachts seine Stromanschlüsse außen an der Halle?',
    type: 'text',
    category: 'INFOS FÜR NIGHTLINER',
    optional: false,
  },
  {
    field: 'venueNightliner',
    label: 'Venue',
    placeholder:
      'Wann wird das Venue geöffnet? Ab wann können Küche/ Dusche und Co benutzt werden? Gibt es auch WC´s, die Nachts nutzbar sind?',
    type: 'text',
    category: 'INFOS FÜR NIGHTLINER',
    optional: false,
  },
  {
    field: 'kitchenNightliner',
    label: 'Küche',
    placeholder:
      'Gibt es eine Crew Küche? Mit oder ohne Geräte? Ab wann und wo betretbar?',
    type: 'text',
    category: 'INFOS FÜR NIGHTLINER',
    optional: false,
  },
  {
    field: 'showers',
    label: 'Duschen',
    placeholder:
      'Gibt es ausreichend Umkleiden und Duschen? Ab wann und wo zu betreten?',
    type: 'text',
    category: 'INFOS FÜR NIGHTLINER',
    optional: false,
  },
  {
    field: 'extraWissen',
    label: 'Extra- Wissen',
    placeholder:
      'Was muss sonst noch mitgeteilt werden? Was läuft immer schief in der Kommunikation? GIbt es einen anderen Kontakt als Bühnenmeister?',
    type: 'text',
    category: 'INFOS FÜR NIGHTLINER',
    optional: false,
  },
];

let CATEGORY_DESCRIPTIONS = {
"KONTAKT":"Bitte die Kontaktdetails für das ausgewählte Venue eintragen",
"GARDEROBEN": "Bitte die Fragen zur Garderobensituation allgemein gültig beantworten. Die folgenden Felder werden in jedem angelegten Raum gespiegelt.",
"PERSONAL": "Bitte die Fragen zur Personalsituation allgemein gültig beantworten. Die folgenden Felder werden in jedem angelegten Raum gespiegelt.",
"KONTAKT BÜHNENMEISTER": "",
"LAGE & ANFAHRT": "ACHTUNG in den zu befüllenden Zeilen stehen Fragen die möglichst konkret beantwortet werden sollten",
"INFOS FÜR NIGHTLINER": "INFOS FÜR NIGHTLINER: Produktionen mit Nightlinern haben andere Interessen und Bedürfnisse, als die ohne. Somit, ACHTUNG in den zu befüllenden Zeilen stehen Fragen die möglichst konkret beantwortet werden sollten"
};

export function GET_DESCRIPTION(category) {
  return ConvertKeysToLowerCase(CATEGORY_DESCRIPTIONS)[category.toLowerCase()] || category; 
}

export function LOCATION_CATEGORIES() {
  let categories = [];
  for (let field of LOCATION_FIELDS) {
    if (field['category'] && !categories.includes(field['category'])) {
      categories.push(field['category']);
    }
  }
  return categories;
}
