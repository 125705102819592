<div class=registration-container>
    <button (click)="dialogRef.close()" mat-button>
        <mat-icon>close</mat-icon>
    </button>
    <ngx-auth-firebaseui-register *ngIf="userType" logoUrl='./assets/img/favicon.png' appearance="legacy"
        (onLoginRequested)="openLoginDialog()" (onSuccess)="regSuccessful($event)"
        [nameText]="'nameText' | translate"
        [nameErrorRequiredText]="'nameErrorRequiredText' | translate"
        [emailText]="'emailText' | translate"
        [emailErrorRequiredText]="'emailErrorRequiredText' | translate"
        [emailErrorPatternText]="'emailErrorPatternText' | translate"
        [passwordText]="'passwordText' | translate"
        [passwordErrorRequiredText]="'passwordErrorRequiredText' | translate"
        [passwordConfirmationText]="'passwordConfirmationText' | translate"
        [passwordConfirmationErrorRequiredText]="'passwordConfirmationErrorRequiredText' | translate"
        [passwordErrorMatchText]="'passwordErrorMatchText' | translate"
        [titleText]="'titleText' | translate"
        [createAccountButtonText]="'createAccountButtonText' | translate"
        [alreadyHaveAccountText]="'alreadyHaveAccountText' | translate"
        [loginButtonText]="'loginButtonText' | translate"
        [passwordErrorMinLengthText]="'passwordErrorMinLengthText' | translate"
        [passwordErrorMaxLengthText]="'passwordErrorMaxLengthText' | translate">
    </ngx-auth-firebaseui-register>
</div>