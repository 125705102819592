<div class="contactInformationContainer">
    <!-- <div class="contactLabelContainer">
        <div class="labelLine"></div>
        <div class="LabelText">Kontakt</div>
        <img (click)="openSendMessage()" class="messagePaper" src="assets/img/papierflieger.svg">
    </div> -->


    <div class="contactInformation margin-top-32">
        <div *ngIf="location">
            <div>
                <span>{{ location['name'] }}</span><br>
                <span class="adresse">{{ location['street'] || 'Adresse nicht angegeben' }}
                    {{ location['houseNumber'] }}</span><br>
                <span>{{ location['zipCode'] }} {{ location['city'] }}</span>
            </div>


            <div class="margin-top-16">
                <span>Ansprechperson:</span><br>
                <span>
                    <app-user [uid]="locationOwner()"></app-user>
                </span><br>
                <span>Telefon: <app-user [uid]="locationOwner()" show="phone"></app-user></span><br>
                <span>Fax: <app-user [uid]="locationOwner()" show="fax"></app-user></span>
            </div>
            <div class="margin-top-16">
                <span>Telefon: {{ location['phone'] }}</span><br>
                <span>Web: <span *ngIf="!location['website'] || location['website'].trim().length == 0">Nicht
                        vorhanden</span><br>
                    <a class="maxwidth"
                        href="{{ location['website'].startsWith('http') ? location['website'] : ('http://' + location['website']) }}"
                        target="_blank">{{ location['website']}}</a><br>
                </span>
                <span class="maxwidth">eMail:
                    <a *ngIf="location['mail']" href="mailto:{{ location['mail'] }}">{{ location['mail'] }}</a>
                    <span *ngIf="!location['mail']">Nicht vorhanden</span>
                </span>
            </div>
        </div>
    </div>
</div>