import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserService } from 'src/app/services/user.service';
import { chooseEventData } from 'src/app/types/chooseEventData.type';
import { eventJSDate } from 'src/app/types/eventJSDate.type';
import { eventSlotRequest } from 'src/app/types/eventSlotRequest.type';
import { clearTimeEventSlots } from 'src/app/utils/helpers';
import { first } from 'rxjs/operators';
import { selectedEventSlot } from 'src/app/types/selectedEventSlot.type';
import { EventSlotService } from 'src/app/services/event-slot.service';
import { eventSlotStatus } from 'src/app/utils/constants/eventSlotStatus';
import { bookingRequest } from 'src/app/types/bookingRequest.type';
import { KeyValueService } from 'src/app/services/key-value.service';

const UUID = new Date().getTime();

@Component({
  selector: 'app-choose-event-slot',
  templateUrl: './choose-event-slot.component.html',
  styleUrls: ['./choose-event-slot.component.scss'],
})
export class ChooseEventSlotComponent implements OnInit {
  public bookableSlots: {}[] = undefined;
  public location: {};
  public choosenDate: Date = undefined;
  public selectedSlot: number = 0;
  public selectedPlace: number = 0;
  public bookingRequest: bookingRequest = {
    UUID: undefined,
    message: '',
    description: '',
    category: '',
    status: undefined,
    user: undefined,
    website: localStorage.getItem('lastTypedWebsite') || '',
  };
  public loading: boolean = true;

  private currentUser;

  constructor(
    public eventSlotService: EventSlotService,
    public dialogRef: MatDialogRef<ChooseEventSlotComponent>,
    @Inject(MAT_DIALOG_DATA) public data: chooseEventData,
    private userService: UserService,
    private store: KeyValueService
  ) {
    console.log('data:', this.data);
    this.location = this.data.chooseableSlots.location;
    this.bookableSlots = this.data.chooseableSlots.bookableSlots;
    this.choosenDate = this.data.choosenDate;
    this.loading = !this.loading;
  }

  async ngOnInit() {
    this.currentUser = await this.userService
      .getMyUser()
      .pipe(first())
      .toPromise();
    this.initBookingRequest();
  }

  public validateURL() {
    if (this.bookingRequest['website'].startsWith('www.')) {
      this.bookingRequest['website'] = 'https://' + this.bookingRequest['website'];
    }
  }

  onNoClick() {
    this.dialogRef.close(undefined);
  }

  async submitSelectedEvent() {
    let bookableSlot = this.getChoosenSlot();
    this.getBookingRequest();
    localStorage.setItem('lastTypedWebsite', this.bookingRequest['website']);
    bookableSlot['daySlot']['requests'].push(this.bookingRequest);
    let selectedEventSlot: selectedEventSlot = {
      daySlot: bookableSlot['daySlot'],
      room: bookableSlot['room'],
      location: this.location,
      bookingRequestUUID: this.bookingRequest['UUID'],
    };

    this.store.set('tourDescription', this.bookingRequest['description']);


    this.dialogRef.close(selectedEventSlot);
    setTimeout(() => {
      this.setEventServiceText();
    }, 200);
  }

  private getChoosenSlot() {
    let slot = this.bookableSlots[this.selectedSlot];
    if (!slot['requests']) {
      slot['requests'] = [];
    }
    slot['status'] = 'Reserviert';
    return slot;
  }

  private getBookingRequest() {
    this.bookingRequest['user'] = this.currentUser['customIdName'];
    this.bookingRequest['category'] = this.data.tourType;
    this.bookingRequest['UUID'] = UUID;
    this.bookingRequest['status'] = eventSlotStatus.reserved;
  }

  private setEventServiceText() {
    this.eventSlotService.slotDescription = this.bookingRequest['description'];
    this.eventSlotService.slotMsgToVendor = this.bookingRequest['message'];
  }

  private initBookingRequest() {
    this.bookingRequest['message'] = `
Sehr geehrte Damen und Herren,

mit dieser Anfrage möchte ich gerne ihren Veranstaltungsort für den angegebenen Zeitpunkt mieten.
Ich freue mich über eine positive Rückmeldung auf meine Anfrage.


Viele Grüße

${this.currentUser['firstName']} ${this.currentUser['lastName']}
`;

    this.bookingRequest['description'] = this.store.get('tourDescription');
  }

  public setEventSlotText() {
    this.bookingRequest['description'] = this.eventSlotService.slotDescription;
    this.bookingRequest['message'] = this.eventSlotService.slotMsgToVendor;
  }
}
