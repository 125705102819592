import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { LocationService } from 'src/app/services/location.service';
import { NotificationService } from 'src/app/services/notification.service';
import { UserService } from 'src/app/services/user.service';
import { notification } from 'src/app/types/notification.type';
import { AcceptRequestComponent } from './accept-request/accept-request.component';
import { AcceptSlotRequestComponent } from './accept-slot-request/accept-slot-request.component';
import { CancelRequestComponent } from './cancel-request/cancel-request.component';
import { SignContractComponent } from './sign-contract/sign-contract.component';

@Component({
  selector: 'app-show-requests',
  templateUrl: './show-requests.component.html',
  styleUrls: ['./show-requests.component.scss'],
})
export class ShowRequestsComponent implements OnInit, AfterViewInit {
  public myLocations = [];
  @Input() public showArchive = false;
  @Input() public showNotifications = false;
  public notifications = [];

  constructor(
    private locationService: LocationService,
    private dialog: MatDialog,
    private notificationService: NotificationService,
    public userService: UserService
  ) {}

  ngOnInit(): void {
    this.subscribeMyLocations();
    this.subscribeNotifications();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.notificationService.markAllMyNotificationsAsRead();
    }, 5 * 1000);
  }

  async subscribeMyLocations() {
    (await this.locationService.getMyLocations()).subscribe((myLocations) => {
      // console.log('myLocations', myLocations);
      this.myLocations = myLocations;
    });
  }

  showSlot(slot) {
    if (this.showArchive) {
      return slot['status'] != 'Reserviert';
    } else {
      return slot['status'] == 'Reserviert';
    }
  }

  cancelRequest(notification) {
    const payload = notification['payload'];
    let c = this.dialog.open(CancelRequestComponent).componentInstance;
    c.locationId = payload['locationId'];
    c.roomUUID = payload['roomUUID'];
    c.slotId = payload['slotId'];
    c.requestUUID = payload['requestUUID'];
    c.notifications = this.notifications;
    c.currentNotification = notification;
  }

  /*   public cancelRequest(n: {}) {
    let c = this.dialog.open(CancelRequestComponent).componentInstance;
    c.location = this.myLocations[li];
    c.placeIndex = pi;
    c.slotIndex = si;
    c.requestIndex = ri;
    c.notifications = this.notifications;
  } */

  // acceptRequest(li, pi, si, ri) {
  //   let c = this.dialog.open(AcceptRequestComponent).componentInstance;
  //   c.location = this.myLocations[li];
  //   c.placeIndex = pi;
  //   c.slotIndex = si;
  //   c.requestIndex = ri;
  //   c.notifications = this.notifications
  // }

  acceptSlotRequest(n: notification) {
    let c = this.dialog.open(AcceptSlotRequestComponent).componentInstance;
    c.notification = n;
  }

  signContract(notification: any) {
    // console.log('Open sign contract form', notification);
    let c = this.dialog.open(SignContractComponent).componentInstance;
    c.contract = notification.payload['contract'];
    c.notification = notification;
  }

  private subscribeNotifications() {
    this.notificationService.getMyNotifications().subscribe((notifications) => {
      this.notifications = notifications.sort((n1, n2) => {
        return (
          n2['createdAt'].toDate().getTime() -
          n1['createdAt'].toDate().getTime()
        );
      });
    });
  }
}
