import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { isEqual } from 'date-fns';
import { ContractService } from 'src/app/services/contract.service';
import { LocationService } from 'src/app/services/location.service';
import { NotificationService } from 'src/app/services/notification.service';
import { firebaseTS } from 'src/app/types/firebaseTS.type';
import { tourSlot } from 'src/app/types/tourSlot.type';
import {
  getJsDateFromFireTs,
  getTourNotification,
} from 'src/app/utils/helpers';
import { eventSlotStatus } from 'src/app/utils/constants/eventSlotStatus';
import { notificationTypes } from 'src/app/utils/constants/notificationTypes';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-accept-request',
  templateUrl: './accept-request.component.html',
  styleUrls: ['./accept-request.component.scss'],
})
export class AcceptRequestComponent implements OnInit {
  location = {};
  placeIndex = 0;
  slotIndex = 0;
  requestIndex = 0;
  loading = false;
  selectedContract = 0;
  notifications = [];
  notification = undefined;

  constructor(
    public contractService: ContractService,
    private notificationService: NotificationService,
    private datePipe: DatePipe,
    private snackbar: MatSnackBar,
    private dialogRef: MatDialogRef<AcceptRequestComponent>,
    private locationService: LocationService,
    private firestore: AngularFirestore
  ) {}

  ngOnInit(): void {}

  ngAfterViewInit() {
    this.initNotification();
  }

  initNotification() {
    let startDateFb: firebaseTS = this.location['places'][this.placeIndex][
      'bookableEventSlots'
    ][this.slotIndex]['startSlot'];
    let startDate = getJsDateFromFireTs(
      startDateFb.seconds,
      startDateFb.nanoseconds
    );
    this.notification = getTourNotification(this.notifications, startDate);
  }

  acceptRequest() {
    if (
      this.notification &&
      'payload' in this.notification[0] &&
      'tourId' in this.notification[0]['payload']
    ) {
      this.updateTourSlot();
    }
    this.sendAcceptRequest();
  }

  updateTourSlot() {
    this.firestore
      .collection('tours')
      .doc(this.notification[0]['payload']['tourId'])
      .valueChanges()
      .pipe(first())
      .subscribe((tour: any) => {
        this.updateTour(tour);
      });
  }

  updateTour(tour) {
    for (let i = 0; i < tour.eventSlots.length; i++) {
      const tourSlot = tour.eventSlots[i];
      const startFire: firebaseTS = tourSlot.eventSlotStartDate;
      let start = getJsDateFromFireTs(startFire.seconds, startFire.nanoseconds);
      let nStartFire: firebaseTS = this.notification[0].payload.startSlot;
      let nStart = getJsDateFromFireTs(
        nStartFire.seconds,
        nStartFire.nanoseconds
      );
      if (isEqual(start, nStart)) {
        tour['eventSlots'][i]['eventSlotRequest']['status'] =
          eventSlotStatus.booked;
        this.firestore
          .collection('tours')
          .doc(this.notification[0]['payload']['tourId'])
          .set(tour);
        return;
      }
    }
  }

  sendAcceptRequest() {
    this.loading = true;
    let slot = this.locationService.getSlot(
      this.location,
      this.placeIndex,
      this.slotIndex
    );
    let request = slot['requests'][this.requestIndex];
    slot['status'] = eventSlotStatus.booked;
    slot['contract'] = this.location['contractTemplateSrcs'][
      this.selectedContract
    ];

    const msg = `Deine Buchungsanfrage bei "${
      this.location['name']
    }" wurde akzeptiert.
    
    Der gebuchte Zeitraum beginnt am ${this.getTimespan(slot)}.

    Bitte akzeptiere noch die Vertragsbedingungen.`;
    const payload = {
      contract: slot['contract'],
      location: this.location['customIdName'],
      placeIndex: this.placeIndex,
      slotIndex: this.slotIndex,
      requestIndex: this.requestIndex,
    };

    const n = this.notificationService.sendNotification(
      request['uid'],
      msg,
      notificationTypes.signContract,
      payload
    );
    // console.log('Notification', n);
    this.locationService
      .updateLocation(this.location)
      .then(() => {
        this.showLoadingResult('Die Änderungen wurden gespeichert');
        this.dialogRef.close();
      })
      .catch((err) => {
        console.error('Error saving location', err);
        this.showLoadingResult(
          'Fehler! Die Änderungen konnten nicht gespeichert Werden!'
        );
      });
  }

  private showLoadingResult(msg) {
    this.loading = false;
    this.snackbar.open(msg, null, { duration: 3000 });
  }

  private getTimespan(slot: any) {
    const startSlot = slot['startSlot'].toDate();
    // const endSlot = request['endSlot'].toDate();

    return this.datePipe.transform(startSlot, 'short');
  }
}
