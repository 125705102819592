<div [class.timeslot-selected]="selected" mat-ripple class="timeslot" [class.deactivated]="deactivated" [matRippleDisabled]="deactivated">
    <div>Raum: {{ slot['room'] ? slot['room']['name'] : 'Nicht mehr vorhanden' }}

        <br> {{ slot['daySlot']['startSlot'] | date:'EEEE' }}, {{ slot['daySlot']['startSlot'] | date:'short' }} - {{
        slot['daySlot']['endSlot'] | date:'mediumTime' }}
    </div>
    <div><b>Buchungskosten {{ slot['daySlot']['bookingFee'] ? slot['daySlot']['bookingFee'] +' € (Brutto)' :
            'nicht
            Angegeben' }} </b>
        </div>
</div>