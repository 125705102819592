import { Component, Inject, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  addYears,
  endOfDay,
  isBefore,
  isEqual,
  startOfDay,
  startOfHour,
} from 'date-fns';
import { addEventDialogData } from 'src/app/interfaces/addEventDialogData.interface';
import { LocationService } from 'src/app/services/location.service';
import { eventSlotStatus } from 'src/app/utils/constants/eventSlotStatus';
import { operation } from 'src/app/utils/constants/operation';
import {
  setDateToEndOfHour,
  setDatetoStartOfHour,
  isOverlappingSlotPresent,
} from 'src/app/utils/helpers';

@Component({
  selector: 'app-add-event',
  templateUrl: './add-event.component.html',
  styleUrls: ['./add-event.component.scss'],
})
export class AddEventComponent implements OnInit {
  public formGroup: FormGroup = new FormGroup({
    startDate: new FormControl('', [Validators.required]),
    endDate: new FormControl('', [Validators.required]),
    bookingFee: new FormControl('', [
      Validators.required,
      Validators.pattern('^[0-9]*$'),
    ]),
  });
  wrongDateFormat = false;
  public today: Date = startOfDay(new Date());
  public minStartDate: Date = startOfDay(new Date());
  public end: Date = addYears(new Date(), 2);

  private location = this.locationService.currentSelection.location;

  constructor(
    public dialogRef: MatDialogRef<AddEventComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: addEventDialogData,
    public firestore: AngularFirestore,
    public locationService: LocationService,
    private snackBar: MatSnackBar
  ) { }

  public ngOnInit(): void {
    this.initalizeDateTimePicker();

    // this.formGroup.get('endDate')
    //   .valueChanges
    //   .subscribe((endDate) => {
    //     this.minStartDate = endDate;
    //   });


    // if(this.dialogData.bookingFee) {
    //   this.formGroup.get('bookingFee').setValue(this.dialogData.bookingFee);
    // }
  }

  public endDateBeforeStartDate() {
    try {
      this.wrongDateFormat = false;
      return this.formGroup.get('startDate').value.getTime() > this.formGroup.get('endDate').value.getTime();
    } catch (e) {
      this.wrongDateFormat = true;
    }
    return false;
  }

  public onNoClick(): void {
    this.dialogRef.close();
  }

  public onSubmit() {
    if (
      !this.isGeneralValidationValid(
        this.getFormStartDate(),
        this.getFormEndDate()
      )
    ) {
      this.snackBar.open('Invalide Eingabe', null, {
        duration: 3000,
      });
      return;
    }
    switch (this.dialogData.operation) {
      case operation.create:
        this.handleCreate();
        break;
      case operation.edit:
        this.handleEdit();
        break;
    }
  }

  private initalizeDateTimePicker() {
    switch (this.dialogData.operation) {
      case operation.create:
        this.initalizeCreate();
        break;
      case operation.edit:
        this.initalizeEdit();
        break;
      default:
        console.error('error not create or edit');
        break;
    }
  }

  private initalizeCreate() {
    this.setStartField(startOfHour(this.dialogData.slot['startSlot']));
    this.setEndField(endOfDay(this.dialogData.slot['endSlot']));
  }

  private initalizeEdit() {
    this.setStartField(startOfHour(this.dialogData.slot['startSlot']));
    this.setEndField(endOfDay(this.dialogData.slot['endSlot']));
    this.setBookingField(this.dialogData.slot['bookingFee']);
  }

  private setEndField(end: Date) {
    this.formGroup.get('endDate').setValue(end);
  }

  private setStartField(start: Date) {
    this.formGroup.get('startDate').setValue(start);
  }

  private setBookingField(bookingFee: number) {
    this.formGroup.get('bookingFee').setValue(bookingFee);
    // console.log(this.formGroup.get('bookingFee').value);
  }

  private handleCreate() {
    let newSlot = this.getNewEventSlot();
    if (isOverlappingSlotPresent(this.dialogData.eventSlots, newSlot)) {
      this.snackBar.open('Überschneidender Timeslot', null, {
        duration: 3000,
      });
      return;
    }
    this.saveNewSlot(newSlot);
  }

  private handleEdit() {
    let changedSlot = this.getChangedEventSlot();
    let filteredSlots = this.dialogData.eventSlots.filter((s) => {
      return s['customIdName'] !== changedSlot['customIdName'];
    });
    if (isOverlappingSlotPresent(filteredSlots, changedSlot)) {
      this.snackBar.open('Überschneidender Timeslot', null, {
        duration: 3000,
      });
      return;
    }
    this.updateSlot(changedSlot);
  }

  private getChangedEventSlot(): any {
    let slot = {
      ...this.dialogData.slot,
    };
    slot['startSlot'] = this.getFormStartDate();
    slot['endSlot'] = this.getFormEndDate();
    slot['bookingFee'] = this.getBookingFee();
    return slot;
  }

  private getFormStartDate(): Date {
    return this.formGroup.get('startDate').value;
  }

  private getFormEndDate(): Date {
    return this.formGroup.get('endDate').value;
  }

  private getBookingFee() {
    return this.formGroup.get('bookingFee').value;
  }

  private isGeneralValidationValid(start: Date, end: Date) {
    return (
      !isEqual(start, end) ||
      !isBefore(end, start) ||
      !isBefore(start, startOfDay(new Date()))
    );
  }

  private getNewEventSlot(): any {
    let bookableEventSlot: any = {
      startSlot: setDatetoStartOfHour(this.getFormStartDate()),
      endSlot: setDateToEndOfHour(this.getFormEndDate()),
      bookingFee: this.getBookingFee(),
      contract: '',
      requests: [],
      status: eventSlotStatus.open,
      customIdName: '',
    };
    return bookableEventSlot;
  }

  private saveNewSlot(eventSlot) {
    let id = this.firestore.collection('event-slots').doc().ref.id;
    eventSlot['customIdName'] = id;
    eventSlot['locationId'] = this.location['customIdName'];
    eventSlot['roomUUID'] = this.dialogData.room['UUID'];
    this.firestore
      .collection('event-slots')
      .doc(id)
      .set(eventSlot)
      .then(() => {
        this.snackBar.open('Event Slot wurde hinzugefügt', null, {
          duration: 3000,
        });
        this.onNoClick();
      });
  }

  private updateSlot(changedSlot) {
    this.firestore
      .collection('event-slots')
      .doc(changedSlot['customIdName'])
      .update(changedSlot)
      .then(() => {
        this.snackBar.open('Event Slot wurde geändert', null, {
          duration: 3000,
        });
        this.onNoClick();
      });
  }
}
