<div class="dialog-container">
    <mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>

    <h1 mat-dialog-title>Venue hinzufügen</h1>
    <div mat-dialog-content>
        <p>Wie soll die Venue heißen?
            <p>
                <mat-form-field>
                    <mat-label>Name</mat-label>
                    <input matInput [formControl]="name" [pattern]="noSpacesRegex" required>
                    <mat-error *ngIf="name.invalid">{{getErrorMessage()}}</mat-error>
                </mat-form-field>
    </div>
    <div mat-dialog-actions>
        <button mat-button (click)="onNoClick()">Abbrechen</button>
        <button [disabled]="name.invalid" mat-button (click)="addLocation()" cdkFocusInitial>Hinzufügen</button>
    </div>
</div>