<div class="filter-row">


    <!-- City Filter -->
    <div class="filter-item" [matMenuTriggerFor]="cityMenu" matRipple>
        <span class="filter-headline">Ort</span>
        <span class="filter-description">{{ (selectedCity | async) ? cityControl.value : 'Auswählen...' }}</span>

        <mat-menu #cityMenu="matMenu">
            <div (keydown.tab)="stopPropagationOnTab($event)" (click)="stopPropagation($event)">
                <form (submit)="selectCity()" class="padding-32">
                    <div class="filter-menu-headline">Stadt auswählen</div>

                    <mat-form-field>
                        <input required type="text" placeholder="Stadt" aria-label="Number" matInput [formControl]="cityControl" [matAutocomplete]="auto">
                        <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                            <mat-option *ngFor="let option of filteredCities" [value]="option">
                                {{option}}
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                    <div class="flex-right">
                        <button type="submit" mat-raised-button color="primary">Übernehmen</button>
                    </div>
                </form>
            </div>

        </mat-menu>
    </div>
    <div class="seperator"></div>


    <!-- Range Filter -->
    <div class="filter-item" [matMenuTriggerFor]="rangeMenu" matRipple>
        <span class="filter-headline">Umkreis</span>
        <span class="filter-description">{{ range }} km</span>

        <mat-menu #rangeMenu="matMenu">
            <div (click)="stopPropagation($event)" class="padding-32">
                <div class="filter-menu-headline">Umkreis auswählen (in km)</div>
                <mat-slider min="1" max="1000" thumbLabel displayWith="newRange" [displayWith]="formatSliderLabel" (change)="upateRange($event)" class="width-100">
                </mat-slider>

                <div class="flex-right">
                    <button type="submit" (click)="selectRange()" mat-raised-button color="primary">Übernehmen</button>
                </div>
            </div>
        </mat-menu>
    </div>
    <div class="seperator"></div>

    <!--  stage size Filter -->
    <div class="filter-item" [matMenuTriggerFor]="stageMenu" matRipple>
        <span class="filter-headline">Bühnenmaße</span>
        <span class="filter-description">T ({{ stageSize.newStageDepth }}m) x B ({{ stageSize.newStageBreadth }}m) x H ({{
      stageSize.newStageHeight }}m)</span>

        <mat-menu #stageMenu="matMenu" class="padding-32">
            <div (keydown.tab)="stopPropagationOnTab($event)" (click)="stopPropagation($event)">
                <form (ngSubmit)="selectStageSize()" tabindex="0">

                    <div class="filter-menu-headline">Min. Bühnenbreite (in m)</div>
                    <mat-slider min="1" max="50" thumbLabel displayWith="stageSize.newStageBreadth" [displayWith]="formatSliderLabelStageSize" (change)="stageSize.newStageBreadth = $event.value" class="width-100" tabindex="1">
                    </mat-slider>

                    <div class="filter-menu-headline">Min. Bühnenlänge (in m)</div>
                    <mat-slider min="1" max="50" thumbLabel displayWith="stageSize.newStageDepth" [displayWith]="formatSliderLabelStageSize" (change)="stageSize.newStageDepth = $event.value" class="width-100" tabindex="2">
                    </mat-slider>

                    <div class="filter-menu-headline">Min. Bühnenhöhe (in m)</div>
                    <mat-slider min="1" max="50" thumbLabel displayWith="stageSize.newStageHeight" [displayWith]="formatSliderLabelStageSize" (change)="stageSize.newStageHeight = $event.value" class="width-100" tabindex="3">
                    </mat-slider>

                    <div class="flex-right">
                        <button tabindex="4" type="submit" mat-raised-button color="primary">Übernehmen</button>
                    </div>
                </form>

            </div>
        </mat-menu>

    </div>
    <div class="seperator"></div>


    <!--  date filter  -->
    <div class="filter-item" [matMenuTriggerFor]="dateMenu" matRipple>
        <span class="filter-headline">Datum</span>
        <span class="filter-description" *ngIf="this.dateHasBeenSet">{{ choosenDate | date:'shortDate' }}</span>
        <span class="filter-description" *ngIf="!this.dateHasBeenSet">Auswählen...</span>

        <mat-menu #dateMenu="matMenu" class="padding-32">
            <div (click)="stopPropagation($event)">
                <mat-calendar #datepicker [selected]="choosenDate" (selectedChange)="choosenDate = $event"></mat-calendar>
                <div class="flex-right">
                    <button type="submit" mat-raised-button color="primary" (click)="selectDate()">Übernehmen</button>
                </div>
            </div>
        </mat-menu>
    </div>



    <div class="seperator"></div>



    <!-- Price filter -->
    <div class="filter-item" [matMenuTriggerFor]="priceMenu" matRipple>
        <span class="filter-headline">Preis</span>
        <span class="filter-description">0€ - {{ priceRange }}€</span>

        <mat-menu #priceMenu="matMenu">
            <div (click)="stopPropagation($event)" class="padding-32">
                <div class="filter-menu-headline">Max. Preis (Tag) auswählen (in €)</div>
                <mat-slider min="1" max="100000" thumbLabel [displayWith]="formatSliderLabelPrice" (change)="newPriceRange = $event.value" class="width-100">
                </mat-slider>

                <div class="flex-right">
                    <button type="submit" (click)="selectPriceRange()" mat-raised-button color="primary">Übernehmen</button>
                </div>
            </div>
        </mat-menu>

    </div>


</div>