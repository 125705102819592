import { Component, Input, OnDestroy, OnInit } from '@angular/core';

@Component({
  selector: 'app-change-text',
  templateUrl: './change-text.component.html',
  styleUrls: ['./change-text.component.scss']
})
export class ChangeTextComponent implements OnInit, OnDestroy {
  @Input() text = [];
  interval;
  currentText = 0;
  constructor() { }

  ngOnInit(): void {
    console.log(this.text);

    this.interval = setInterval(() => {
      this.currentText = (this.currentText + 1) % this.text.length;
    }, 3500);
  }

  ngOnDestroy() {
    clearInterval(this.interval);
  }

}
