<div class="card-round margin-bottom-32 page-card" *ngIf="showArchive">
    <h2 class="margin-bottom-32">{{ showArchive ? 'Archivierte Anfragen' : 'Neue Anfragen' }}</h2>

    <div *ngFor="let l of myLocations; let li = index;">
        <div *ngFor="let p of l.places; let pi = index;">
            <div *ngFor="let slot of p.bookableEventSlots; let si = index;">
                <div *ngFor="let request of slot.requests; let ri = index;">
                    <div class="request" *ngIf="showSlot(slot)">
                        <div class="request-row">
                            <div class="request-row-left">
                                <img class="profile-picture" src="assets/img/profile.png">
                                <div class="request-user-info">
                                    <b>
                                        <app-user [uid]="request['user']"></app-user>
                                    </b>
                                    <span class="color-gray">
                                        <app-user [uid]="request['user']" show="description"></app-user>
                                    </span>
                                    <span class="color-gray">Buchungsanfrage für <b>{{ l['name'] }}</b> ({{
                                        slot['status'] }}) </span>
                                </div>
                            </div>
                            <div *ngIf="slot['status'] != 'Reserviert'">
                                <mat-chip-list>
                                    <mat-chip color="warn" *ngIf="slot['status'] == 'Abgelehnt'" selected>{{
                                        slot['status'] }}</mat-chip>
                                    <mat-chip *ngIf="slot['status'] != 'Abgelehnt'" color="primary" selected>{{
                                        slot['status'] }}
                                    </mat-chip>
                                </mat-chip-list>
                            </div>
                            <div class="hide-mobile" *ngIf="slot['status'] == 'Reserviert'">
                                <button mat-button class="margin-right-16"
                                    (click)="cancelRequest(li, pi, si, ri)">Ablehnen</button>
                                <button mat-raised-button color="primary"
                                    (click)="acceptRequest(li, pi, si, ri)">Annehmen</button>

                            </div>
                        </div>
                        <div class="request-details">
                            <b>Angefragter Zeitraum:</b>
                            <p>{{ slot.startSlot.toDate() | date:'short'}} - {{ slot.startSlot.toDate() | date:'short'}}
                            </p>
                            <b>Über die Veranstaltung:</b>
                            <p> {{ request['description'] }}</p>
                            <b>Persönliche Nachricht:</b>
                            <p> {{ request['message'] }}</p>

                            <div class="hide-desktop" *ngIf="slot['status'] == 'Reserviert'">
                                <button mat-raised-button class="margin-right-16"
                                    (click)="cancelRequest(li, pi, si, ri)">Ablehnen</button>
                                <button mat-raised-button color="primary"
                                    (click)="acceptRequest(li, pi, si, ri)">Annehmen</button>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>


<div class="card-round" *ngIf="showNotifications">
    <h2>Benachrichtigungen</h2>
    <div class="request" [class.request-row-last]="i == (notifications.length - 1)"
        *ngFor="let n of notifications; let i = index;">
        <div class="request-row">

            <span class="notification-date">{{ n['createdAt'].toDate() | date:'short' }}</span>

            <div class="request-row-left margin-bottom-32">
                <div class="request-user-info">
                    <b *ngIf="n['action'] === 'REQUEST_SLOT_DONE'">Abgelehnt:</b>

                    <b *ngIf="n['action'] === 'BOOKING_ACCEPTED'">Angenommen:</b> {{ n['msg'] }}
                </div>

                <app-event-slot-request class="margin-top-16" [slot]="n['payload']['slotId']"
                    [requestUUID]="n['payload']['requestUUID']"
                    *ngIf="n['action'] === 'REQUEST_SLOT' || n['action'] === 'REQUEST_SLOT_DONE' || n['action'] === 'BOOKING_ACCEPTED'">
                </app-event-slot-request>
            </div>

            <div class="button-container">
                <div *ngIf="n['action'] === 'REQUEST_SLOT'">
                    <div>
                        <button mat-button class="margin-right-16" (click)="cancelRequest(n)">
                            Ablehnen</button>

                        <button mat-raised-button color="primary" class="margin-right-16"
                            (click)="acceptSlotRequest(n)">
                            Annehmen</button>

                    </div>
                </div>

                <div class="hide-mobile align-self-start">
                    <!-- Actions -->
                    <div *ngIf="n['action'] === 'SIGN_CONTRACT'">
                        <button mat-raised-button color="primary" (click)="signContract(n)">Vertrag
                            unterzeichnen</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="hide-desktop ">
            <div *ngIf="n[ 'action']=='SIGN_CONTRACT'">
                <button mat-raised-button color="primary" (click)="signContract(n)">Vertrag unterzeichnen</button>
            </div>
        </div>
    </div>

</div>