<div class="dialog-container">
    <mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>

    <h1 mat-dialog-title>Anfrage Ablehnen?</h1>
    <div mat-dialog-content>
        <p>Warum willst du die Abfrage ablehnen?</p>
        <mat-radio-group class="radio-group" [(ngModel)]="refuseReason">
            <mat-radio-button class="radio-button" *ngFor="let r of refuseReasonList; let i = index;" [value]="r">
                {{ r }}
            </mat-radio-button>
            <mat-radio-button class="radio-button" value="Sonstiges...">
                <mat-form-field class="example-full-width">
                    <mat-label>Sonstiges...</mat-label>
                    <input [disabled]="refuseReason != 'Sonstiges...'" matInput placeholder="Bitte beschreiben" [(ngModel)]="customRefuseReason" name="customRefuseReason">
                </mat-form-field>
            </mat-radio-button>
        </mat-radio-group>
    </div>
    <div mat-dialog-actions class="flex-right">
        <button [disabled]="loading" mat-button mat-dialog-close cdkFocusInitial>Abbrechen</button>
        <button [disabled]="loading" mat-button color="warn" (click)="refuseRequest()">Ablehnen</button>
    </div>
</div>