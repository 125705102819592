import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { first } from 'rxjs/operators';
import { eventFbDate } from 'src/app/types/eventFbDate.type';
import { tour } from 'src/app/types/tour.type';
import { tourInfo } from 'src/app/types/tourInfo.type';
import {
  getJsDateSlotByFbDate,
  getTourSlotsAmount,
} from 'src/app/utils/helpers';

@Component({
  selector: 'app-tour-info',
  templateUrl: './tour-info.component.html',
  styleUrls: ['./tour-info.component.scss'],
})
export class TourInfoComponent implements OnInit {
  public currentUser: any;
  public tours = [];

  
  constructor(
    private authService: AngularFireAuth,
    private firestore: AngularFirestore
  ) { }

  public async ngOnInit() {
    this.currentUser = await this.authService.user.pipe(first()).toPromise();
    this.initTourData();
  }

  private initTourData() {
    this.firestore
      .collection('tours', (ref) =>
        ref.where('uid', '==', this.currentUser.uid)
      )
      .valueChanges()
      .subscribe((tours: tour[]) => {
        this.tours = tours;
      });
  }
}
