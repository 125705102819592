import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable, of } from 'rxjs';
import { first, map, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(
    private firestore: AngularFirestore,
    private authService: AngularFireAuth
  ) { }

  public getMyId(): Observable<string> {
    return this.authService.user.pipe(map((u) => u && u['uid']));
  }

  public getMyUser(): Observable<any> {
    return this.getMyId().pipe(
      switchMap((uid) => {
        if (!uid) {
          return of({});
        } else {
          return this.getUser(uid);
        }
      })
    );
  }

  public async isEventler() {
    try {
      let userInfo = await this.getMyUser().pipe(first()).toPromise()
      return userInfo && userInfo['userType'] == 'eventler';
    } catch (e) {
      return false;
    }
  }

  public updateUser(user) {
    console.log('Updating user', user);
    return this.firestore.collection('users').doc(user['customIdName']).update(user);
  }

  public getUser(uid: string): Observable<any> {
    return this.firestore.collection('users').doc(uid).valueChanges({ idField: 'customIdName' });
  }

  public async emailVerified(): Promise<boolean> {
    const user = await this.authService.currentUser;
    return user.emailVerified;
  }

  public async logoutUser() {
    await this.authService.signOut();
  }

  public sendVerificationMail() {
    return this.authService.currentUser.then((user) => {
      user.sendEmailVerification();
    });
  }

  public async resetPassword(email: string) {
    this.authService.sendPasswordResetEmail(email);
  }
}
