import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ContractService {
  constructor() {}

  public getFileName(url: string) {
    if (!url) {
      return 'Vertrag nicht vorhanden!';
    }
    const seperatorA = 'undefined';
    const seperatorB = '?alt=media';
    return url.split(seperatorA)[1].split(seperatorB)[0].substring(13);
  }
}
