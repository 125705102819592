import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { LocationMessageComponent } from 'src/app/components/dialogs/location-message/location-message.component';

@Component({
  selector: 'app-contact-information',
  templateUrl: './contact-information.component.html',
  styleUrls: ['./contact-information.component.scss'],
})
export class ContactInformationComponent implements OnInit {
  constructor(public dialog: MatDialog) {}

  @Input() location;
  ngOnInit(): void {}

  openSendMessage() {
    const dialogRef = this.dialog.open(LocationMessageComponent, {
      width: '80vw',
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log(`Dialog close result: ${result}`);
    });
  }

  locationOwner(){
    const owner = this.location['users'].find(user => user.role == 'Owner');
    return owner ? owner['uid'] : '';
  }
}
