<div *ngIf="location.customIdName" class="cardContainer">
    <div class="flex-row-wrap spaceBetween word-break-words">
        <div class="flex-center-v">
            <mat-icon class="creatorIcon">home</mat-icon>
            <span class="fontSizeLarger">{{ location['name'] }}, {{ location['city']
                }}</span>
        </div>
        <a target="_blank" matTooltip="In neuem Tab öffnen" matTooltipPosition="above" class="margin-16" mat-raised-button color="primary" routerLink="/location/{{location['customIdName']}}">
            Zum Venue</a>
    </div>
    <div class="flex-row-wrap spaceBetween">
        <mat-list class="word-break-words">
            <mat-list-item>Venue Ersteller:</mat-list-item>
            <mat-list-item *ngIf="owner['firstName']">
                <mat-icon class="creatorIcon">person</mat-icon>{{ owner['firstName'] }} {{ owner['lastName'] }}
            </mat-list-item>
            <mat-list-item *ngIf="owner['phone']">
                <a matTooltip="Anrufen" matTooltipPosition="above" href="tel:{{ owner['phone'] }}" class="flex-center-v">
                    <mat-icon class="creatorIcon">phone</mat-icon>{{ owner['phone'] }}</a>
            </mat-list-item>
            <mat-list-item *ngIf="owner['eMail']">
                <mat-icon class="creatorIcon">email</mat-icon>{{ owner['eMail'] }}
            </mat-list-item>
        </mat-list>
        <div class="examineContainer margin-16">
            <mat-radio-group class="examineBtns" aria-label="Select an option">
                <mat-radio-button #correct class="margin-16" value="correct">Freigeben</mat-radio-button>
                <mat-radio-button #wrong class="margin-16" value="wrong">Ablehnen</mat-radio-button>
            </mat-radio-group>
            <div class="margin-16">
                <button color="primary" name="submit" mat-raised-button (click)="examine()">Bestätigen</button>
            </div>
        </div>
    </div>
</div>