import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { MatDialog } from '@angular/material/dialog';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { first, map, take } from 'rxjs/operators';
import { ErrorComponent } from '../components/dialogs/error/error.component';
import { LocationService } from '../services/location.service';
import { UserService } from '../services/user.service';

@Injectable({
  providedIn: 'root'
})
export class RegistrationCompleteGuard implements CanActivate {
  userIsEventler = false;
  constructor(private auth: AngularFireAuth,
    private dialog: MatDialog,
    private firestore: AngularFirestore,
    private user: UserService,
    private location: LocationService,
    private router: Router) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    let path = route.routeConfig.path;
    console.log("Router to path: ", path);

    return this.isEventler().then(isEventler => {
      if (isEventler) {
        return true;
      } else {
        return this.hasCompletePreviousStep(path);
      }
    });

    //return this.isEventler2() || this.hasCompletePreviousStep(path);
  }

  hasCompletePreviousStep(path: string) {
    switch (path) {
      case 'my-locations':
        return this.hasProfileComplete();
      case 'media':
        return this.hasLocations() && this.hasRooms();
      case 'contracts': case 'calendar': case 'mailbox': case 'archive':
        return this.hasMinImages();
      // case 'calendar': case 'mailbox': case 'archive':
      //   return this.hasMinContracts();
      default:
        return true;
    }
  }

  async hasMinContracts(): Promise<boolean> {
    let locationsObservable = await this.location.getMyLocations();
    let locations = await locationsObservable.pipe(first()).toPromise();
    let wrongLocationName = '';

    return this.hasMinImages().then(hasMinImages =>{
        if(hasMinImages){
            let hasNoContracts = locations.find(location => {
            wrongLocationName = location['name'];
            if (!location.contractTemplateSrcs) {
              console.warn('[CAUTION] The location ' + location['name'] + ' is missing the field contractTemplateSrcs');
              return true;
            }
            return location.contractTemplateSrcs.length < 1;
          });
          if (hasNoContracts) {
            this.openDialog('Bitte lade zuerst mindestens einen Vertrag für "' + wrongLocationName + '" hoch');
            this.router.navigate(['contracts']);
          }
          return !hasNoContracts;
        }else{
          return false;
        }
    });
  }

  async hasMinImages(): Promise<boolean> {
    let locationsObservable = await this.location.getMyLocations();
    let locations = await locationsObservable.pipe(first()).toPromise();
    if (locations.length > 0) {
      console.log("Checking Locations for imgs");
      let wrongLocationName;
      let hasToLessImgs = locations.find(location => {
        wrongLocationName = location['name'];
        if (!location.imgSrcs) {
          console.warn('[CAUTION] The location ' + wrongLocationName + ' is missing the field imgSrcs');
          return true;
        }
        return location.imgSrcs.length < 3;
      });
      console.log(hasToLessImgs);
      if (hasToLessImgs) {
        this.openDialog('Bitte lade zuerst mindestens 3 Bilder für ' + wrongLocationName + ' hoch');
        this.router.navigate(['media']);
      }
      return !hasToLessImgs;
    } else {
      return this.hasLocations();
    }
  }

  async hasLocations(): Promise<boolean> {
    let locationsObservable = await this.location.getMyLocations();
    let locations = await locationsObservable.pipe(first()).toPromise();
    if (locations.length > 0) {
      return true;
    } else {
      return this.hasProfileComplete().then(hasProfileComplete => {
        if (hasProfileComplete) {
          this.openDialog('Bitte füge wenigstens ein Venue hinzu.');
          this.router.navigate(['my-locations']);
        }
        return false;
      });
    }
  }
  async hasRooms(): Promise<boolean> {
    let locationsObservable = await this.location.getMyLocations();
    let locations = await locationsObservable.pipe(first()).toPromise();
    let wrongLocation = locations.find( location => !location['rooms'] || location['rooms'].length == 0)
    if(wrongLocation){
      this.openDialog(`Bitte füge für Location '${wrongLocation.name}' wenigstens eine Raum hinzu.`);
      this.router.navigate(['my-locations']);
      return false;
    }else{
      return true;
    }
  }

  async hasProfileComplete(): Promise<boolean> {
    let user = await this.user.getMyUser().pipe(first()).toPromise();
    if (!user['profileComplete']) {
      this.openDialog('Bitte fülle dein Profil zu 100% aus.');
      this.router.navigate(['profile']);
    }
    return user['profileComplete'];
  }

  async isEventler(): Promise<boolean> {
    let userIsEventler = await this.user.getMyUser().pipe(first()).pipe(map(u => u['userType'] == 'eventler')).toPromise();
    console.log("User is Eventler: ", userIsEventler);
    return userIsEventler;
  }

  openDialog(msg: string) {
    if (!localStorage.getItem('noAlerts')) {
      if (this.dialog.openDialogs.length == 0) {
        this.dialog.open(ErrorComponent).componentInstance.msg = msg;
      }
    }
  }
}