<div class="card-round">
    <div class="profile-header">
        <img src="assets/img/profile.png" class="profile-picture">
    </div>
    <div class="profile-content">

        <div class="space-between">
            <h2>{{ user['firstName'] }} {{ user['lastName'] }}</h2>
            <button *ngIf="(userService.getMyId() | async) == user['customIdName']" routerLink="/profile" mat-icon-button matTooltip="Profil bearbeiten" matTooltipPosition="above">
        <mat-icon>edit</mat-icon>
      </button>
        </div>

        <div class="profile-description">{{ user['position']}} bei {{ user['company'] }}</div>
    </div>
</div>

<div class="card-round page-card margin-top-16">
    <div class="profile-contact-section">
        <h2>Person kontaktieren</h2>

        <div class="flex-center-v">
            <mat-icon>phone</mat-icon> <a class="flex-center-v" href="tel:{{ user['phone'] }}">
        {{ user['phone'] }}</a>
        </div>

        <div class="margin-top-16 flex-center-v">
            <mat-icon>mail</mat-icon> <a class="flex-center-v" href="mailto:{{ user['email'] }}">
        {{ user['email'] }}</a>
            <span *ngIf="!user['email'] || user['email'].length == 0">Nicht vorhanden</span>
        </div>


        <div class="margin-top-16 flex-center-v">
            <mat-icon>print</mat-icon>
            <span>{{ user['fax'] }}</span>
        </div>
    </div>
</div>