import { Injectable } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable, Subject } from 'rxjs';
import { finalize } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class FileUploadService {
  onInvalidMsg: string | boolean;
  maxSize: number;
  fileAcceptedTypes: RegExp;
  pathToFile: string;
  url: Subject<string>;
  uploadPercent: Observable<number>;

  fileToUpload = new Subject<{file: File, userId: String}>();
  fileToUpload$ = this.fileToUpload.asObservable();

  subscriptionFile = this.fileToUpload.subscribe(({file, userId}) => {
    localStorage.setItem('noAlerts', 'true');
    this.onInvalidMsg = this.validateFile(file);
    if (this.onInvalidMsg) {
      this._snackBar.open(this.onInvalidMsg, 'OK', { duration: 2000 });
    } else {
      this.upload(file, userId);
    }
  });
  constructor(
    private _snackBar: MatSnackBar,
    private _storage: AngularFireStorage
  ) {}

  checkFile(file: File) {
    return {
      sizeOk: file && file.size < this.maxSize ? true : false,
      typeOk:
        file && file.name.toLowerCase().match(this.fileAcceptedTypes)
          ? true
          : false,
    };
  }

  validateFile(file: File) {
    let fileCheck = this.checkFile(file);
    if (!fileCheck.sizeOk) {
      return 'Datei zu groß!';
    }
    if (!fileCheck.typeOk) {
      return 'Dateityp nicht zulässig!';
    }
    return false; // file is valid
  }

  async upload(file: File, userId: String) {
    let filename = file.name.substring(0, file.name.length); // Before: .lastIndexOf('.')
    console.log(filename);
    filename = new Date().getTime() + filename;
    // const filePath = this.pathToFile + '' + filename;
    const filePath = `/user/${userId}/undefined` + filename;
    const ref = this._storage.ref(filePath);
    const task = ref.put(file);

    // observe percentage changes
    this.uploadPercent = task.percentageChanges();
    // get notified when the download URL is available
    task
      .snapshotChanges()
      .pipe(
        finalize(() =>
          ref.getDownloadURL().subscribe((url) => {
            setTimeout(() => {
              localStorage.removeItem('noAlerts');
            }, 2000);
            this.url.next(url);
          })
        )
      )
      .subscribe();
    // const URL = ref.getDownloadURL();
    // URL.subscribe(url => {
    //   this.url.next(url);
    // });
  }
}
