export let guideLines = {
    "/profile": "Füge hier bitte deine Firmendaten ein. Diese Daten werden nicht veröffentlicht.",
    "/my-locations": `Deine Venue - Übersicht und Navigation. Einzelne Räumlichkeiten (egal wie viele) legst du innerhalb der Venue Maske an. SADEY promotet jede Räumlichkeit individuell und rückt Dich so ins beste Licht! Wichtig: Danach bitte, unter dem Menü-Punkt "Medien" musst Du noch passendes Bildmaterial hochladen, bevor die Venue von uns überprüft und veröffentlicht wird.`,
    "/media": `Bitte wähle eine Räumlichkeit aus und lade Bilder, sowie Pläne, oder Grundrisse hoch.
    Wir empfehlen min. 3 -5 Bilder, im Querformat, farbig und hochauflösend. Im Anschluss wird dein
    Profil von uns überprüft und veröffentlicht.`,

    "/calendar": `Das Booking Tool ist das Herzstück von SADEY. Alle Daten werden mit Deinem öffentlichen Profil
    in Echtzeit synchronisiert.
    Hier hinterlegst Du alle freien Slots & dazu passende Buchungspreise (pro Räumlichkeit).
    Sinnvoll: Wir empfehlen Dir ein flexibles pricing. Slots, die in der Regel schlechter nachgefragt
    werden, sollten den besten Preis haben.`,
    "/mailbox": `Deine Echtzeit - Übersicht zu allen getätigten Reservierungen und festen Buchungen. Hier kannst Du die Verträge für Deine Buchungen einsehen und verbindlich unterzeichnen. Danach werden Sie im Archiv abgelegt.`,

    "/archive": "Alle bestätigten Verträge werden hier für Dich gespeichert. Unsere bestätigten Verträge sind rechtsverbindlich.",

    "/contracts": `Lade hier bitte alle Vertragsmuster hoch. Wähle vorab die passende Venue. Je eindeutiger du das Dokument benennst, desto leichter kannst du es der entsprechenden Räumlichkeit und Nutzung zuordnen! Wichtig: Ohne Vertragsvorlagen kannst Du keine Buchungen annehmen.`,

    "/tour-planner": "Der Filter ist einfach zu bedienen und auf die wichtigsten Daten angepasst.",
    "/examine-location": "Hier kannst du deine Venues freigeben."
};