<mat-drawer-container>
    <mat-drawer #drawer [mode]="sideNavMode" [opened]="menuOpened">

        <div class="logo-container" routerLink="/">
            <img src="./assets/img/sadey_loves_events_schriftzug_white.svg">
        </div>

        <div class="menu-entry" routerLink="profile" matRipple [routerLinkActive]="['mainColor']">
            Profil
        </div>


        <!--       Add Class.mainColor for this link         -->
        <div *ngIf="isEventler()" class="menu-entry" routerLink="my-tours" matRipple routerLinkActive="mainColor">
            Tourpläne
        </div>

        <div *ngIf="!isEventler()" class="menu-entry" routerLink="my-locations" matRipple routerLinkActive="mainColor">
            Meine Venues
        </div>

        <div *ngIf="!isEventler()" class="menu-entry" routerLink="media" matRipple routerLinkActive="mainColor">
            Medien
        </div>

        <div *ngIf="!isEventler()" class="menu-entry" routerLink="calendar" matRipple routerLinkActive="mainColor">
            Kalender
        </div>

        <div class="menu-entry" routerLink="mailbox" matRipple routerLinkActive="mainColor">
            <span *ngIf="newMessages > 0" [matBadge]="newMessages" matBadgeSize="small"
                matBadgeOverlap=false>Postfach</span>
            <span *ngIf="newMessages == 0">Postfach</span>
        </div>


        <div class="menu-entry" routerLink="archive" matRipple routerLinkActive="mainColor">
            Archiv
        </div>

        <div *ngIf="!isEventler()" class="menu-entry" routerLink="contracts" matRipple routerLinkActive="mainColor">
            Verträge
        </div>

        <div *ngIf="isEventler()" class="menu-entry" routerLink="location-search" matRipple
            routerLinkActive="mainColor">
            Venue suchen
        </div>

        <div *ngIf="isEventler()" class="menu-entry" routerLink="tour-planner" matRipple routerLinkActive="mainColor">
            Tour buchen
        </div>

        <div *ngIf="isAdmin()" class="menu-entry" routerLink="examine-location" matRipple routerLinkActive="mainColor">
            Administration - Prüfe Locations
        </div>

        <div class="menu-selector" [style.top.px]="menuSelectorLocation">

        </div>

    </mat-drawer>
    <mat-drawer-content class="scrollbar">
        <mat-toolbar color="primary" [class.bg-landing]="currentRoute == '/'"
            [class.toolbar-fixed]="currentRoute == '/'">
            <button *ngIf="currentRoute != '/' && !isLegalRoute()" mat-icon-button (click)="drawer.toggle()">
                <mat-icon>menu</mat-icon>
            </button>
            <img *ngIf="showSadeyLogoInToolbar()" class="legal-logo" routerLink="/"
                src="assets/img/sadey_loves_events_schriftzug.svg">

            <span class="spacer"></span>
            <div *ngIf="user" class="flex-center-v">
                <span class="margin-right-16 user-info">
                    <span *ngIf="userInfo['firstName'] && userInfo['lastName']">{{ userInfo['firstName'] }} {{
                        userInfo['lastName'] }}</span><br>
                    <span class="company-info">{{ userInfo['company'] }}</span>
                </span>
                <button routerLink="/profile" mat-icon-button matTooltip="Mein Profil öffnen">
                    <img class="profile-picture" src="assets/img/profile.png">
                </button>
                <button (click)="logOut()" mat-icon-button matTooltip="Abmelden">
                    <mat-icon>logout</mat-icon>
                </button>
            </div>
            <div *ngIf="currentRoute == '/' && !user">
                <button class="register" mat-button mat-flat-button color="primary"
                    [matMenuTriggerFor]="register">Join</button>
                <mat-menu #register="matMenu">
                    <button mat-menu-item (click)="openRegistrationDialog('eventler')"
                        [matTooltip]="DESCRIPTION_EVENTLER" matTooltipPosition="left">Als Eventler</button>
                    <button mat-menu-item (click)="openRegistrationDialog('locations')"
                        [matTooltip]="DESCRIPTION_LOCATION" matTooltipPosition="left">Als
                        Venue-Anbieter</button>
                </mat-menu>
                <button mat-flat-button color="primary" (click)="openLoginDialog()">Enter</button>
            </div>
        </mat-toolbar>

        <div class="container" [class.container-internal]="currentRoute != '/'"
            [class.bg-landing]="currentRoute == '/'">
            <app-guideline *ngIf="currentRoute != '/'" [route]="currentRoute"></app-guideline>
            <router-outlet></router-outlet>
        </div>

        <div class="legal-links" [class.legal-links-landing]="currentRoute == '/'"
            [class.bg-landing]="currentRoute == '/'">
            <span class="hidden"><a routerLink="agb">AGB</a> |</span>
            <a routerLink="imprint">Impressum</a> |
            <a routerLink="data-protection">Datenschutz</a>
        </div>

    </mat-drawer-content>
</mat-drawer-container>