import { AfterViewInit, Component, ElementRef, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { DESCRIPTION_EVENTLER, DESCRIPTION_LOCATION } from 'src/app/types/description.type';

@Component({
  selector: 'app-divi-tutorial',
  templateUrl: './divi-tutorial.component.html',
  styleUrls: ['./divi-tutorial.component.scss']
})
export class DiviTutorialComponent implements OnInit, AfterViewInit {
  ROOT_FOLDER = './assets/videos/tutorials/';
  @ViewChildren('videoelements') videoelements: QueryList<ElementRef>;
  DESCRIPTION_EVENTLER = DESCRIPTION_EVENTLER;
  DESCRIPTION_LOCATION = DESCRIPTION_LOCATION;

  videos = [
    '02.mp4',
    '03.mp4',
    '04.mp4',
    '05.mp4',
    '06.mp4',
    '07.mp4'
  ];

  currentVideo = 1;
  constructor() { }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.videoelements.forEach(element => {
      element.nativeElement.muted = "muted";
    });
   }


   setVideo(id){
     console.log('Current Video is', id);
    this.currentVideo = id;
   }

  getVideoSrc() {
    return this.ROOT_FOLDER + this.videos[this.currentVideo];
  }

}
