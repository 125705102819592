<!-- <div class="categories margin-top-32">
    <span *ngFor="let category of ROOM_CATEGORIES; let last = last; let i = index;">
    <a [class.selected]="i == selected" (click)="selected = i"> {{ category }}</a>
    <span *ngIf="!last"> | </span>
    </span>
</div>
<table class="margin-top-32">
    <tr *ngFor="let field of getFields()">
        <td>{{ field['label'] }}</td>
        <td class="pl-12" *ngIf="location">{{ location['rooms'][selectedRoom][field['field']] }}</td>
    </tr>
</table> -->

<div class="roomInformationContainer">
    <div class="categories margin-top-32 align-self-start">
        <a [class.selected]="selected == 0" (click)="selected = 0">KAPAZITÄTEN</a>
        <span> | </span>
        <a [class.selected]="selected == 1" (click)="selected = 1">BÜHNE</a>
        <span> | </span>
        <a [class.selected]="selected == 2" (click)="selected = 2">TECHNIK</a>
        <span> | </span>
        <a [class.selected]="selected == 3" (click)="selected = 3">GOOD TO KNOW</a>
    </div>
    
    <div class="margin-top-16 info-container" *ngIf="location">
        <div *ngIf="selected == 0">
            <h3>Sitzplätze</h3>
            <app-room-info-fields [fields]="PLACE_FIELDS" [dataJSON]="location['rooms'][selectedRoom]" filterField="SITZPLÄTZE">
            </app-room-info-fields>
    
            <h3 class="margin-top-16">Grundriss</h3>
            <app-room-info-fields [fields]="PLACE_FIELDS" [dataJSON]="location['rooms'][selectedRoom]" filterField="GRUNDRISS">
            </app-room-info-fields>
    
    
            <h3 class="margin-top-16">Garderoben</h3>
            <app-room-info-fields [fields]="LOCATION_FIELDS" [dataJSON]="location" filterField="GARDEROBEN">
            </app-room-info-fields>
    
    
            <h3 class="margin-top-16">Personal</h3>
            <app-room-info-fields [fields]="LOCATION_FIELDS" [dataJSON]="location" filterField="PERSONAL">
            </app-room-info-fields>
        </div>
    
    
    
        <div *ngIf="selected == 1">
            <h3>Maße Hauptbühne</h3>
            <app-room-info-fields [fields]="PLACE_FIELDS" [dataJSON]="location['rooms'][selectedRoom]" filterField="MAßE HAUPTBÜHNE">
            </app-room-info-fields>
    
    
            <h3 class="margin-top-16">Bühnenraum</h3>
            <app-room-info-fields [fields]="PLACE_FIELDS" [dataJSON]="location['rooms'][selectedRoom]" filterField="BÜHNENRAUM">
            </app-room-info-fields>
    
    
            <h3 class="margin-top-16">Ausstattung</h3>
            <app-room-info-fields [fields]="PLACE_FIELDS" [dataJSON]="location['rooms'][selectedRoom]" filterField="AUSSTATTUNG">
            </app-room-info-fields>
        </div>
    
    
    
        <div *ngIf="selected == 2">
            <h3>Technik</h3>
            <app-room-info-fields [fields]="PLACE_FIELDS" [dataJSON]="location['rooms'][selectedRoom]" filterField="TECHNIK">
            </app-room-info-fields>
    
    
            <h3 class="margin-top-16">Kontakt Bühnenmeister</h3>
            <app-room-info-fields [fields]="LOCATION_FIELDS" [dataJSON]="location" filterField="KONTAKT BÜHNENMEISTER">
            </app-room-info-fields>
    
        </div>
    
    
    
        <div *ngIf="selected == 3">
            <h3>Lage & Anfahrt</h3>
            <app-room-info-fields [fields]="LOCATION_FIELDS" [dataJSON]="location" filterField="LAGE & ANFAHRT">
            </app-room-info-fields>
    
    
            <h3 class="margin-top-16">Infos für Nightliner</h3>
            <app-room-info-fields [fields]="LOCATION_FIELDS" [dataJSON]="location" filterField="INFOS FÜR NIGHTLINER">
            </app-room-info-fields>
        </div>
    
    
    
        <div class="margin-top-32">
            <h3>Downloads</h3>
            <ul>
                <li *ngFor="let download of location['rooms'][selectedRoom]['downloads']" target="_blank"><a href="{{ download }}">{{ getFileName(download) }}</a></li>
            </ul>
        </div>
    </div>
</div>