import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-event-slot-selector',
  templateUrl: './event-slot-selector.component.html',
  styleUrls: ['./event-slot-selector.component.scss']
})
export class EventSlotSelectorComponent implements OnInit {


  @Input() slot = {};
  @Input() selected = false;
  @Input() deactivated = false;
  
  constructor() { }

  ngOnInit(): void {
  }

}
