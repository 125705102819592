import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { FormControl } from '@angular/forms';
import { MatOptionSelectionChange } from '@angular/material/core';
import { combineLatest, of } from 'rxjs';
import { debounceTime, first, throttle } from 'rxjs/operators';
import {
  capitalizeFirstLetter,
  minimizeFirstLetter,
} from 'src/app/utils/helpers';

@Component({
  selector: 'app-location-access',
  templateUrl: './location-access.component.html',
  styleUrls: ['./location-access.component.scss'],
})
export class LocationAccessComponent implements OnInit {
  loading = false;
  public location = { users: [] };
  filteredUsers = [];
  searchControl = new FormControl();
  constructor(private firestore: AngularFirestore) { }

  ngOnInit(): void {
    console.log('Location is', this.location);

    this.findUsersByMail();
    this.initSearch();
  }

  private initSearch() {
    this.searchControl.valueChanges
      .pipe(debounceTime(500))
      .subscribe((searchTerm) => {
        this.findUsersByMail(searchTerm);
      });
  }

  private findUsersByMail(searchTerm?: string) {
    searchTerm = searchTerm ? searchTerm : '';

    console.log('Searching user with', searchTerm);

    if (searchTerm.length < 3) {
      return;
    }

    try {
      combineLatest(
        this.searchField('email', searchTerm.toLowerCase()),
      ).subscribe((users) => {
        console.log('users are', users);
        this.filteredUsers = [...users[0]].filter((user) => {
          return !this.location.users.find(
            (u) => u['uid'] == user['customIdName']
          );
        });
      });
    } catch (e) {
    }
  }
  // private findUsers(searchTerm?: string) {
  //   searchTerm = searchTerm ? searchTerm : '';

  //   console.log('Searching user with', searchTerm);

  //   combineLatest(
  //     this.searchField('firstName', capitalizeFirstLetter(searchTerm)),
  //     this.searchField('lastName', capitalizeFirstLetter(searchTerm)),
  //     this.searchField('firstName', minimizeFirstLetter(searchTerm)),
  //     this.searchField('lastName', minimizeFirstLetter(searchTerm))
  //   ).subscribe((users) => {
  //     console.log('users are', users);
  //     // if ('uid' in users[0]) {
  //     this.filteredUsers = [...users[0], ...users[1], ...users[2], ...users[3]].filter((user) => {
  //       return !this.location.users.find(
  //         (u) => u['uid'] == user['customIdName']
  //       );
  //     });
  //     // } else {
  //     // console.error('error no uid in user!');
  //     // }
  //   });
  // }



  selectUser(info: MatOptionSelectionChange) {
    let userInfo = info.source.value;
    console.log('userInfo', userInfo);
    this.location.users.push({
      uid: userInfo.customIdName,
      displayName: userInfo.firstName + ' ' + userInfo.lastName,
      role: 'Mitglied',
    });
    this.searchControl.setValue('');
    this.saveLocation();
  }

  deleteUser(i) {
    this.location.users.splice(i, 1);
    this.saveLocation();
  }

  private saveLocation() {
    this.loading = true;
    this.firestore
      .collection('locations')
      .doc(this.location['customIdName'])
      .update(this.location)
      .then((result) => {
        console.log('Location has been updated', result);
        this.loading = false;
      })
      .catch((error) => {
        console.log('Error updating location', error);
        this.loading = false;
      });
  }

  private searchField(field, searchTerm) {
    try {
      searchTerm = searchTerm.trim();
      return this.firestore
        .collection('users', (ref) =>
          ref
            .where(field, '>=', searchTerm)
            .where(field, '<=', searchTerm)
            .limit(10)
        )
        .valueChanges({ idField: 'customIdName' })
        .pipe(first());
    } catch (e) {
      return of([]);
    }
  }
}
