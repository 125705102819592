import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { first } from 'rxjs/operators';
import { UserService } from './user.service';

const BASE_URL = 'https://developerakademie.com/';
const IGNORE_EMAIL = false;

@Injectable({
  providedIn: 'root',
})
export class EmailService {
  constructor(private http: HttpClient, private userUservice: UserService) { }

  public async sendMail(uid, message, subject) {
    if (IGNORE_EMAIL) {
      return;
    }

    let user = await this.userUservice.getUser(uid).pipe(first()).toPromise();

    let msgText = `Hallo ${user['firstName']} ${user['lastName']},

${message}

Viele Grüße
Dein SADEY-TEAM
`;

    let url = BASE_URL + 'sendMail.php';
    let payload = {
      email: user['email'],
      subject: subject,
      message: msgText,
    };

    this.sendHTMLEmail(url, user['email'], subject, msgText);

    // const result = await this.http.post(url, payload).toPromise();

    // console.log('Send mail', result);

    return payload;
  }

  sendHTMLEmail(url, email, subject, message) {
    // TODO Senden mit GET Paramatern
    var iframe = document.createElement('iframe');

    const html = `
    <form id="form" action="${url}" method="POST">
      <input name="email" value="${email}">
      <input name="subject" value="${subject}">
      <input name="message" value="${message}">
    </form>


    <script>
      document.getElementById('form').submit();
    </script>
    `;

    iframe.src = 'data:text/html;charset=utf-8,' + encodeURI(html);
    iframe.setAttribute('style', 'display: none');

    document.body.appendChild(iframe);

    setTimeout(() => {
      document.body.removeChild(iframe);
    }, 15000);
  }
}
