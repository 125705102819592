import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-aot-new',
  templateUrl: './aot-new.component.html',
  styleUrls: ['./aot-new.component.scss']
})
export class AotNewComponent implements OnInit {
  arrowHidden = true;
  constructor() { }

  ngOnInit(): void {

    setTimeout(() => {
      this.arrowHidden = false;
    },1000);
  }

  scroll(id: string) {
    document.getElementById(id).scrollIntoView({behavior: "smooth"});
  }

}
