import { AfterViewInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { guideLines } from 'src/app/types/guideLines.type';

@Component({
  selector: 'app-guideline',
  templateUrl: './guideline.component.html',
  styleUrls: ['./guideline.component.scss']
})
export class GuidelineComponent implements OnInit, OnChanges {

  @Input() route: string;
  guideLine: string = '';

  constructor() { }
 

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    const change = changes['route'].currentValue;
    this.guideLine = guideLines[change];
  }
}