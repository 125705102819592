<div class="page-card">
    <!-- <app-location-picker></app-location-picker> -->

    <div *ngIf="locationService.currentSelection" class=calendar-container>
        <div>

            <div class="space-between flex-center-v">
                <mat-button-toggle-group>
                    <mat-button-toggle mwlCalendarPreviousView [view]="view" [(viewDate)]="viewDate" (viewDateChange)="closeOpenMonthViewDay()">Vorheriger</mat-button-toggle>
                    <mat-button-toggle mwlCalendarToday [(viewDate)]="viewDate" (viewDateChange)="closeOpenMonthViewDay()">Heute
                    </mat-button-toggle>
                    <mat-button-toggle mwlCalendarNextView [view]="view" [(viewDate)]="viewDate" (viewDateChange)="closeOpenMonthViewDay()">Nächster</mat-button-toggle>
                </mat-button-toggle-group>

                <app-room-picker (selectedRoomEvent)="loadEventSlots($event)"></app-room-picker>

            </div>


            <h2>{{ viewDate | calendarDate:(view + 'ViewTitle'):'de' }}</h2>

            <mat-button-toggle-group>
                <mat-button-toggle (click)="setView(CalendarView.Month)" [class.active]="view === CalendarView.Month">
                    Monat
                </mat-button-toggle>
                <mat-button-toggle (click)="setView(CalendarView.Week)" [class.active]="view === CalendarView.Week">
                    Woche
                </mat-button-toggle>
                <mat-button-toggle (click)="setView(CalendarView.Day)" [class.active]="view === CalendarView.Day">
                    Tag
                </mat-button-toggle>
            </mat-button-toggle-group>

        </div>

        <ng-template #loading>
            <p class="text-center">
                Lade Kalender...
            </p>
        </ng-template>


        <!--  *ngIf="events$ | async; else loading; let events"  -->

        <div *ngIf="!roomNotFinished">
            <div [ngSwitch]="view">
                <mwl-calendar-month-view weekStartsOn="1" *ngSwitchCase="CalendarView.Month" [viewDate]="viewDate" [events]="events" [refresh]="refresh" [activeDayIsOpen]="activeDayIsOpen" (dayClicked)="dayClicked($event.day)" (eventClicked)="handleEvent('Clicked', $event.event)" (beforeViewRender)="beforeMonthViewRender($event)"
                    (dblclick)="handleCreate()">
                </mwl-calendar-month-view>
                <mwl-calendar-week-view weekStartsOn="1" *ngSwitchCase="CalendarView.Week" [viewDate]="viewDate" [events]="events" [refresh]="refresh" (eventClicked)="handleEvent('Clicked', $event.event)" (hourSegmentClicked)="hourSegmentClicked($event.date)" (beforeViewRender)="beforeWeekOrDayViewRender($event)"
                    (dblclick)="handleCreate()">
                </mwl-calendar-week-view>
                <mwl-calendar-day-view *ngSwitchCase="CalendarView.Day" [viewDate]="viewDate" [events]="events" [refresh]="refresh" (eventClicked)="handleEvent('Clicked', $event.event)" (hourSegmentClicked)="hourSegmentClicked($event.date)" (beforeViewRender)="beforeWeekOrDayViewRender($event)"
                    (dblclick)="handleCreate()">
                </mwl-calendar-day-view>
            </div>

            <div class="flex-right">
                <button mat-fab color="primary" matTooltip="Füge ausgewählten Tag im Kalender hinzu" aria-label="open selected date in dialog" (click)="handleCreate()">
          <mat-icon>add</mat-icon>
        </button>
            </div>
        </div>

        <div *ngIf="roomNotFinished" class="card-round margin-top-32">
            Bitte fülle erst einmal alle Informationen zu diesem Raum in dem Menüpunkt "Meine Venues" aus.
        </div>


    </div>
</div>